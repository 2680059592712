<template>
  <div class="container-fluid mt-3 page-checkin">
    <div>
      <video style = "display:none" controls="" id = "audio">
        <source id ="audio-source" src="../../assets/video/notification.mp3" type="audio/mpeg">
      </video>
    </div>
    <div class="row mt--3 row-sm">
      <div class="item-grid col-lg-2 col-md-3 col-sm-6 col-xs-12 mt-3" v-for="user in users">
        <div class="item card mb-0">
          <a class="image">
            <img :src="user.detected_image_url"/>
          </a>
          <div class="info">
            <label>{{$t('label.nameEmployee')}}</label>: <span class="name w-100 clearfix">{{user.personName}}</span>
            <label>{{$t('label.timeDay')}}</label>: <span class="manv w-100 clearfix">{{user.date}}</span>
            <label>{{$t('label.camera')}}</label>: <span class="manv w-100 clearfix">{{user.deviceName}}</span>
          </div>
        </div>
      </div>
    </div>
    <b-modal modal-class="modal-notification" v-model="popupTurnOn" size="sm" :title="$t('label.notification')">
        {{$t('label.turnAudioOn')}}
        <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="outline-secondary"
            class="float-right"
            @click="turnAudioOff()"
          >
            {{$t('label.cancel')}}
          </b-button>
          <b-button
            variant="primary"
            class="float-right mr-3"
            @click="turnAudioOn()"
          >
            {{$t('label.agree')}}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  data () {
    return {
      users: [],
      popupTurnOn: false,
      audioOn: false,
      srcMp3: ''
    }
  },
  sockets: {
    connect: function () {
      console.debug('socket connected')
    },
    receiveMessage: function (user) {
      for (let i = 0; i < this.$store.getters.placeID.length; i++) {
        if (parseInt(this.$store.getters.placeID[i]) === parseInt(user.placeID)) {
          if ((this.audioOn) && (user.audio === true)) {
            const audioSrc = 'data:audio/mp3;base64,' + user.linkaudio
            document.getElementById('audio-source').src = audioSrc
            document.getElementById('audio').load()
            document.getElementById('audio').play()
          }
          this.users.unshift(user)
        }
      }
    }
  },
  created () {
    this.popupTurnOn = true
  },
  methods: {
    turnAudioOff () {
      this.audioOn = false
      this.popupTurnOn = false
    },
    turnAudioOn () {
      this.audioOn = true
      this.popupTurnOn = false
      document.getElementById('audio').play()
    }
  }
}
</script>
<style scoped lang="scss">
  .row-sm{
    margin-left: -5px;
    margin-right: -5px;
  }
  .item-grid{
    padding-left: 5px;
    padding-right: 5px;
  }
  .item{
    height: 100%;
    img{
      width: 100%;
      height: 180px;
    }
    .info{
      padding: 10px;
      font-size: 14px;
      label{
        font-weight: bold;
        margin-bottom: 0.2rem;
      }
    }
  }
</style>
