const ROLES: { [key: string]: string[]; } = {
  admin: [
    "/admin",
    "/employee"
  ],
  employee: [
    "/employee"
  ]
};

export default class Role {
  public static haveRole(menu: string, role: any) : boolean {
    if (!role || role == '') return true;
    if (ROLES[role]) {
      let hasRole = ROLES[role].find(function (element) {
        return menu.startsWith(element);
      });
      if (hasRole) {
        return true;
      }
    }
    return false;
  }

  public static checkRole(link: string, role: any) {
    if (!role || role == '') return false;
    localStorage.setItem('role', role);
    if (ROLES[role]) {
      let hasRole = ROLES[role].find(function (element) {
        return link.startsWith(element);
      });
      if (hasRole) {
        return true;
      }
    }
    return false;
  }
}
