export default {
  menu:{
    dashboard: 'Bảng điều khiển',
    inOutHistory: 'Lịch sử ra vào',
    employeeManager: 'Quản lý nhân viên',
    attendanceManagement: 'Bảng chấm công',
    checkIn: 'Quản lý chấm công',
    showListShift: 'Quản lý ca làm việc',
    arrangeShifts: 'Xếp ca',
    managementLeave: 'Quản lý nghỉ phép',
    dataManagement: 'Quản lý dữ liệu',
    branchManagement: 'Quản lý chi nhánh',
    departmentManager: 'Quản lý phòng ban',
    reportTimeKeep: 'Báo cáo chấm công',
    dayOff: 'Ngày nghỉ',
    systemSettings:'Thiết lập hệ thống',
    welcome:'Trang chào',
    companyManager:'Quản lý công ty',
    leaveTypeManagement: "Quản lý loại đơn báo",
    positionManagement: "Quản lý chức vụ",
    approveLeave: "Duyệt đơn báo",
    groupUserManagement: "Quản lý nhóm nhân sự"
  },
  title:{
    login: 'Đăng nhập :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt',
    logout: 'Đăng xuất :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt',
    admin: 'Bảng điều khiển :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt',
    InOutHistory: 'Lịch sử ra vào :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt',
    attendanceManagement: 'Bảng chấm công :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt',
    showListShift: 'Quản lý ca làm việc :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt',
    staffInShift: 'Nhân viên trong ca :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt',
    arrangeWorkShift: 'Xếp ca :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt',
    branchManagement: 'Quản lý chi nhánh :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt',
    departmentManager: 'Quản lý phòng ban :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt',
    employeeManager: 'Quản lý nhân viên :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt',
    leaveApplication: 'Quản lý nghỉ phép :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt',
    leaveType: 'Quản lý loại nghỉ phép :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt',
    CheckIn: 'Quản lý chấm công :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt',
    user: 'Thành viên :: Viettel AI Camera | Giải pháp chấm công nhận diện khuôn mặt',
    managementLeave:'Danh sách nhân viên nghỉ phép',
    leaves: 'Danh sách nghỉ phép',
    setHoliday: 'Thiết lập ngày lễ',
    compensationWorkDay: 'Duyệt bù công',
    reportSetup: 'Thiết lập báo cáo',
    homePage:'Viettel Construction :: Giải pháp Camera thông minh, chấm công nhận diện khuôn mặt',
    claimTimeSheetList:'Danh sách đơn duyệt bù công',
    welcome: 'Viettel Construction :: Smart reception Robot, AI Camera | Giải pháp chấm công nhận diện khuôn mặt',
    download:'Tải xuống',
    companyManager:'Quản lý công ty',
    leaveTypeManagement: 'Quản lý loại đơn báo',
    groupUserManagement: "Quản lý nhóm nhân sự"
  },

  validate: {
    message: {
      accountRequired: 'Vui lòng nhập tài khoản',
      existPhone: 'Số điện thoại đã có trong hệ thống',
      placeIdRequired: 'Mã chi nhánh không được để trống',
      placeAddressRequired: 'Địa chỉ chi nhánh không được để trống',
      placeNameRequired: 'Tên chi nhánh không được để trống',
      fullNameRequired: 'Tên người dùng không được để trống',
      phoneRequired: 'Số điện thoại không được để trống',
      emailRequired: 'Email không được để trống',
      startWorkRequired: 'Ngày bắt đầu làm việc không được để trống',
      avatarRequired: 'Ảnh đại diện không được để trống',
      placeRequired: 'Chi nhánh không được để trống',
      departmentRequired: 'Phòng ban không được để trống',
      aliasIdRequired: 'Mã nhân viên không được để trống',
      passwordRequired: 'Vui lòng nhập mật khẩu',
      confirmPassRequired: 'Xác nhận mật khẩu không được để trống',
      oldPasswordRequired: 'Mật khẩu cũ không được để trống',
      staffRequired: 'Nhân viên không được để trống',
      leaveRequired: 'Ngày nghỉ không được để trống',
      leaveReasonRequired: 'Lý do nghỉ không được để trống',
      leaveNameRequired: 'Tên ngày nghỉ không được để trống',
      startLeaveRequired: 'Ngày bắt đầu nghỉ không được để trống',
      endLeaveRequired: 'Ngày kết thúc nghỉ không được để trống',
      shiftNameRequired: 'Tên ca làm việc không được để trống',
      workDayRequired: 'Số ngày công làm việc không được để trống',
      startWorkTimeRequired: 'Thời gian bắt đầu ca làm việc không được để trống',
      endWorkTimeRequired: 'Thời gian kết thúc ca làm việc không được để trống',
      leaveHalfRequired: 'Vui lòng chọn nghỉ nửa ca',
      userRequired: "Người dùng không được để trống",
      compensationDateRequired: "Ngày bù công không được để trống",
      compensationReasonRequired: "Lý do bù công không được để trống",
      userNameRequired:"Tên đăng nhập không được để trống",
      companyNameRequired: 'Tên công ty không được để trống',
      companyAddressRequired: 'Địa chỉ công ty không được để trống',
      taxcodeRequired: 'Mã số thuế không được để trống',
      companyRequired: 'Công ty không được để trống',
      positionRequired: 'Chức vụ không được để trống',
      confirmPasswordRequired: 'Vui lòng xác nhận mật khẩu',
      approverRequired: 'Người duyệt không được để trống',
      tokenHanetRequired: 'Hanet token không được để trống',
      noUserSelected: "Bạn chưa chọn nhân viên",
      noDepartmentSelected: "Bạn chưa chọn phòng ban",
      branchIdRequired: "Chọn chi nhánh",
      codeRequired: "Nhập mã",
      nameRequired: "Nhập tên",
      leaveTypeRequired: "Chọn loại đơn báo",
      groupNameRequired: "Nhập tên nhóm"
    }
  },

  error:{
    message:{
      accountOrPassIncorrect: 'Tài khoản hoặc mật khẩu không đúng',
      dbError: 'Lỗi truy vấn cơ sở dữ liệu',
      general: 'Lỗi',
      uploadPicture: 'Lỗi tải ảnh',
      userNotExist: 'Nhân viên không tồn tại',
      passwordNotMatch: 'Mật khẩu không khớp',
      shiftNotExist: 'Ca làm việc không tồn tại',
      leaveNotExist: 'Không tồn tại nghỉ phép này',
      noUserFound: 'Không có nhân viên nào',
      dateRangeLessThan31: 'Khoảng ngày không lớn hơn 31 ngày',
      shiftNameExisted:'Tên ca làm việc đã có trong hệ thống',
      contactNotExist: 'Liên hệ không tồn tại',
      oldPasswordIncorrect: 'Mật khẩu cũ không đúng',
      aliasIDAlreadyExisted: 'Mã nhân viên đã tồn tại',
      userNameExisted: 'Tên đăng nhập đã tồn tại'
    }
  },

  notify:{
    message:{
      success: 'Thành công',
      addUserToShiftSuccess: 'Thêm nhân viên vào ca thành công',
      removeUserShiftSuccess: 'Xoá nhân viên trong ca thành công',
      createdAccountSuccess:'Tạo tài khoản thành công',
      updatedSuccess: 'Cập nhật thành công',
      changePassSuccess: 'Thay đổi mật khẩu thành công',
      deleteShiftSuccess: 'Xoá ca làm việc thành công',
      addLeaveSuccess: 'Thêm ngày nghỉ thành công',
      updateLeaveSuccess: 'Duyệt ngày nghỉ thành công',
      deleteLeaveSuccess: 'Xoá ngày nghỉ thành công',
      updateShiftSuccess:'Sửa ca làm việc thành công',
      addShiftSuccess:'Thêm mới ca làm việc thành công',
      addBranchSuccess:'Thêm mới chi nhánh thành công',
      updateBranchSuccess:'Sửa chi nhánh thành công',
      deleteBranchSuccess:'Xóa chi nhánh thành công',
      addContactSuccess: 'Thêm liên hệ thành công',
      deleteContactSuccess: 'Xoá liên hệ thành công',
      addCompensationSuccess: "Thêm bù công thành công",
      approveCompensationSuccess: "Duyệt bù công thành công",
      addCompanySuccess:'Thêm công ty thành công',
      updateCompanySuccess: 'Chỉnh sửa thông tin công ty thành công',
      importUserSuccess: 'Import nhân viên thành công',
      addDepartmentSuccess: 'Thêm phòng ban thành công',
      editDepartmentSuccess: 'Sửa phòng ban thành công',
      deleteDepartmentSuccess: 'Xoá phòng ban thành công',
      moveDepartmentSuccess: 'Chuyển phòng ban thành công',
      changeUserDepartment: 'Chuyển phòng ban nhân viên thành công',
      addLeaveTypeSuccess: "Thêm loại đơn báo thành công",
      editLeaveTypeSuccess: "Sửa loại đơn báo thành công",
      deleteLeaveTypeSuccess: "Xoá loại đơn báo thành công",
      addPositionSuccess: 'Thêm chức vụ thành công',
      editPositionSuccess: 'Sửa chức vụ thành công',
      deletePositionSuccess: 'Xoá chức vụ thành công',
      movePositionSuccess: 'Chuyển chức vụ thành công',
      changeUserPosition: 'Chuyển chức vụ nhân viên thành công',
      nodata: 'Không có dữ liệu',
      importUserToShiftSuccess: "Import nhân viên vào ca thành công",
      addGroupUserSuccess: "Thêm nhóm nhân sự thành công",
      editGroupUserSuccess: "Sửa nhóm nhân sự thành công",
      deleteGroupUserSuccess: "Xóa nhóm nhân sự thành công"
    }
  },
  home:{
    menu:{
      home: 'Trang chủ',
      userCares: 'Ứng dụng',
      services: 'Dịch vụ',
      benefis: 'Lợi ích',
      contact: 'Liên hệ',
      resources: 'Tài nguyên',
    },
    banner:{
			title:'Giải pháp Camera Thông minh',
      desc:'Kiểm soát ra vào và chấm công bằng nhận diện khuôn mặt, QR code.',
      slogan:'Checkin theo cách của bạn!',
		},
    ourServices:{
      ourServices:'Dịch vụ của chúng tôi',
      desc:'Chúng tôi luôn cung cấp dịch vụ với chất lượng tốt nhất',
      accessControl:'Kiểm soát ra vào',
			accessControlDesc:'Thiết lập chính sách ra vào địa điểm, thông tin cho nhân viên và khách.',
      timeAtendance:'Chấm công',
			timeAtendanceDesc:'Cài đặt thời gian làm việc theo ca, Ghi lại thời gian làm việc của từng nhân viên',
      vmsSystem:'Hệ thống VMS',
			vmsSystemDesc:'Tạo sự kiện, Tạo biểu mẫu tự đăng ký, Nhắc nhớ sự kiện qua Email, Tạo mã QR để tự đăng ký',
      analytics:'Phân tích',
			analyticsDesc:'Phân tích nhận dạng khuôn mặt, Phân tích kiểm soát truy cập, Cảnh bảo hết hạn, Tạo báo cáo trong tệp Excel',
      integration:'Tích hợp',
			integrationDesc:'Tích hợp với Nền tảng bên thứ ba.',
    },
    keyBenefits:{
			title:'Lợi ích chính',
			desc:"Các lợi ích mà bạn sẽ nhận được",
      affordable:'Chi phí hợp lý',
			affordableDesc:'Giải pháp có sẵn phù hợp với tất cả doanh nghiệp.',
      mutiTenant:'Multi-tenant',
			mutiTenantDesc:'Tùy chỉnh quyền truy cập cho mỗi đơn vị để quản lý địa điểm, nhân viên, sự kiện.',
      apisDriver:'APIs Driven',
			apisDriverDesc:'Tích hợp với nền tảng bên thứ ba.',
      analytics:'Phân tích',
      analyticsDesc:'Xem tổng thể hệ thống hàng ngày, hàng tuần, hàng tháng theo dòng thời gian, bảng và đồ thị.',
      cloudService:'Dịch vụ điện toán đám mây',
			cloudServiceDesc:'Quản lý dịch vụ trên đám mây, có thể truy cập Bảng điều khiển từ bất kỳ từ đâu.',
		},
    contactUs:{
      title:'Liên hệ với chúng tôi',
      desc:'Đội ngũ chuyên gia của chúng tôi sẽ tư vấn giải pháp phù hợp với doanh nghiệp của bạn',
      slogan:'Viettel Construction | Dựng xây cuộc sống mới',
      address: 'Số 06 Phạm Văn Bạch, Phường Yên Hòa, Quận Cầu Giấy, Hà Nội.',
    },
    termscondition:'Điều khoản & Điều kiện',
    privacyPolicy:'Chính sách bảo mật',
  },

  label:{
    idEmployee: 'Mã NV',
    nameEmployee: 'Họ tên',
    employee: 'Nhân viên',
    camera: 'Camera',
    image: 'Hình ảnh',
    time: 'Thời điểm',
    all: 'Tất cả',
    stranger: 'Người lạ',
    approver: 'Người duyệt',
    guest: 'Khách hàng',
    start: 'Bắt đầu',
    end: 'Kết thúc',
    filter: 'Lọc',
    control: 'Thao tác',
    removeFromShift:'Xoá khỏi ca làm việc',
    addToShift: 'Thêm nhân viên vào ca',
    totalRecord: 'Tổng số bản ghi: ',
    descriptionemployeeManager: 'QUẢN LÝ NHÂN VIÊN TRỰC THUỘC TẤT CẢ PHÒNG BAN, CHI NHÁNH',
    descriptionAttendanceManagement: 'CHẤM CÔNG CHO NHÂN VIÊN',
    descriptionShowListShift: 'QUẢN LÝ CÁC CA LÀM VIỆC TRONG NGÀY',
    descriptionArrangeShifts: 'QUẢN LÝ XẾP CA',
    descriptionBranchManagement: 'QUẢN LÝ CÁC CHI NHÁNH TRỰC THUỘC DOANH NGHIỆP CỦA BẠN',
    descriptionDepartmentManager: 'QUẢN LÝ CÁC PHÒNG BAN TRỰC THUỘC DOANH NGHIỆP',
    addNew: 'Thêm mới',
    export: 'Xuất ',
    import: 'Nhập',
    employeeCode: 'Mã nhân viên',
    totalNumberOfEmployees: 'Tổng số nhân viên:',
    fullName: 'Họ và tên',
    branch: 'Chi nhánh',
    department: 'Phòng ban',
    adminCompany: 'Quản trị công ty',
    adminBranch: 'Quản trị chi nhánh',
    admin: 'Quản trị viên',
    role: 'Quyền',
    jobPosition: 'Chức vụ',
    dateOfEntry: 'Ngày làm việc',
    phoneNumber: 'Số điện thoại',
    totalWorkShifts: 'Tổng số ca',
    statisticsToday: 'Thống kê hôm nay',
    working: 'Đang làm việc',
    late: 'Đi muộn',
    absent: 'Vắng mặt',
    takeLeave: 'Nghỉ phép',
    birthday: 'Sinh nhật',
    toWork: 'Vào ca',
    sessionWork: 'Ca làm',
    endWork: 'Tan ca',
    minuteLate: 'Đi muộn (phút)',
    minuteEarly: 'Về sớm (phút)',
    name: 'Tên',
    workingTime: 'Thời gian làm việc',
    breakTimeBetweenShifts: 'Thời gian nghỉ giữa ca',
    timeToAllowTimeAttendance: 'Thời gian cho phép chấm vào',
    timeToAllowTimekeeping: 'Thời gian cho phép chấm ra',
    loadShiftsInSeries: ' Xếp ca hàng loạt',
    extraWorkShifts: ' Gán thêm ca',
    defaultShift: 'Ca mặc định',
    delete: 'Xóa',
    address: 'Địa chỉ',
    area: 'Khu vực',
    total: 'Tổng số',
    dateTime: 'Ngày giờ',
    addEdit: 'Thêm/sửa',
    save: 'Lưu',
    areYouSure: 'Bạn có chắc chắn',
    sureDeleteTheBranch: 'Bạn có chắc chắn muốn xoá chi nhánh không?',
    iAgree:'Tôi đồng ý',
    type: 'Kiểu',
    mainWorkShift: 'Ca chính',
    overtimeShift: 'Ca làm thêm',
    numberOfWorkdays: 'Đơn vị công/ngày',
    timeToStartShift: 'Giờ bắt đầu ca',
    endTimeOfShift: 'Giờ kết thúc ca',
    thereIsABreakBetweenShift: 'Có nghỉ giữa ca',
    timeToStartABreakInTheMiddleOfTheShift: 'Giờ bắt đầu nghỉ giữa ca',
    timeToApply: 'Thời gian áp dụng',
    timeCheckinLate: 'Cho phép checkin muộn (phút)',
    timeCheckoutEarly: 'Cho phép checkout sớm (phút)',
    endHourBreakBetweenShift: 'Giờ kết thúc nghỉ giữa ca',
    repeatShifts: 'Lặp ca',
    staffInShift: 'Nhân viên trong ca',
    check:'Kiểm tra',
    operationTimeOfShift: 'Ngày làm việc của ca',
    sureDeleteShift: 'Bạn có chắc chắn muốn xoá ca làm việc này không?',
    monday:'Thứ 2',
    tuesday:'Thứ 3',
    wednesday:'Thứ 4',
    thursday:'Thứ 5',
    friday:'Thứ 6',
    saturday:'Thứ 7',
    sunday:'Chủ nhật',
    date: 'Ngày giờ',
    turnAudioOn: 'Thông báo bằng tiếng:',
    notification: 'Thông báo',
    notificationError: 'Thông báo lỗi',
    agree: 'Đồng ý',
    cancel: 'Huỷ',
    object: 'Đối tượng',
    selectCamera: 'Chọn camera',
    titleLogin: 'Giải pháp chấm công nhận diện khuôn mặt',
    user: "Tài khoản",
    password: 'Mật khẩu',
    changePassword: 'Đổi mật khẩu',
    confirmPassword: 'Xác nhận mật khẩu',
    login: 'Đăng nhập',
    forGotPassword: 'Quên mật khẩu',
    descCompany: 'Copyright © 2021 Viettel Construction',
    search: 'Tìm kiếm',
    timeDay: 'Thời gian',
    deleteSuccess: 'Xóa thành công',
    close: 'Đóng',
    errorMessage: 'Có lỗi sảy ra!',
    addBranchSuccess: 'Thêm mới chi nhánh thành công',
    editBranchSuccess:'Chỉnh sửa chi nhánh thành công',
    office:'Vị trí công việc',
    dateStartWork: 'Ngày vào làm',
    selectTime: 'Sắp xếp theo thời gian',
    asc: 'Tăng dần',
    desc: 'Giảm dần',
    company: 'Công ty',
    staffInformation: 'Thông tin nhân viên',
    mainInformation: 'Thông tin chính',
    male:'Nam',
    female: 'Nữ',
    gender:'Giới tính',
    dateOfBirth: 'Ngày sinh',
    idCard: 'Chứng minh thư',
    asACollaborator: 'Là cộng tác viên',
    yes:'Có',
    no:'Không',
    bankAccountNumber: 'Số tài khoản ngân hàng',
    personalImage: 'Hình ảnh cá nhân',
    avatar: 'Ảnh đại diện',
    update: 'Cập nhật',
    upload:'Tải lên',
    addStaff: 'Thêm nhân viên',
    checkInCode: 'Mã checkin',
    edit: 'Chỉnh sửa',
    sureDeleteMember: 'Bạn có chắc chắn muốn xoá thành viên này không?',
    staffsName: 'Tên nhân viên',
    browser: 'Người duyệt',
    reason:'Lý do',
    status: 'Trạng thái',
    reportType: 'Loại báo cáo',
    filterBy: 'Lọc theo',
    report: 'Báo cáo',
    chooseDate: 'Chọn ngày',
    exportReport: 'Xuất báo cáo',
    moreHolidays:'Thêm ngày lễ',
    holidayList: 'Danh sách ngày lễ',
    holiday: 'Ngày lễ',
    nameHoliday: 'Tên ngày lễ',
    enterHolidayName: 'Nhập tên ngày lễ',
    pending: 'Chờ duyệt',
    approved: 'Đã duyệt',
    approval: 'Duyệt',
    sureLeave: 'Bạn có chắc chắn muốn xoá đơn nghỉ phép này không?',
    sureLeaveType: 'Bạn có chắc chắn muốn xoá loại đơn báo này không?',
    sureApproveLeave: 'Bạn có chắc chắn muốn duyệt đơn nghỉ phép này không?',
    totalLeave: 'Tổng số đơn nghỉ phép:',
    approvalSuccess: 'Duyệt thành công',
    addLeaveApplication: 'Thêm đơn xin nghỉ',
    addClaimTime: 'Thêm đơn duyệt bù công',
    reasonForLeave: 'Lý do nghỉ',
    selectStaff: 'Chọn nhân viên',
    checkedin: 'Đã checkin',
    notCheckin: 'Chưa checkin',
    on: 'Bật',
    off: 'Tắt',
    workDayDetail: 'Chấm công',
    checkinByDay: 'Chấm công theo ngày',
    checkinDetail: 'Chi tiết checkin',
    checkinMoreDay: 'Chấm công nhiều ngày',
    workDetail: 'Chi tiết chấm công',
    mealsInMonth: 'Số suất cơm ca theo tháng',
    detailCheckin: 'Chấm công chi tiết',
    generalReport: 'Báo cáo tổng hợp',
    selectDateRange: 'Chọn khoảng ngày',
    allBranches: 'Tất cả các chi nhánh',
    totalDailyShifts: 'Tổng số ca ngày',
    applyPublicity: 'Áp dụng tính công',
    comeBackBefore: 'Về sớm',
    takeABreak: 'Xin nghỉ',
    repeatWeekly: 'Lặp lại hàng tuần',
    setAsDefault: 'Đặt làm mặc định',
    applyOvertime: 'Có tính tăng ca',
    itAHoliday: 'Là ngày nghỉ lễ',
    pleaseEnterEmployeeName: 'Vui lòng nhập tên nhân viên...',
    to:'đến',
    sender: 'Người gửi',
    weekly: 'Hàng tuần',
    default: 'Mặc định',
    learnMore: 'Xem thêm',
    moreInformation:'Thông tin bổ sung',
    submitHome:'Đăng ký dùng thử',
    turnInOut:'Lượt ra vào',
    leaveHalfShift: 'Nghỉ nửa ca',
    chooseLeaveHalf: 'Chọn nửa ca nghỉ',
    leaveFirstHalf: 'Nghỉ nửa ca trước',
    leaveSecondHalf: 'Nghỉ nửa ca sau',
    refuse:'Từ chối',
    feedbackForm: 'Đơn phản hồi',
    browseApplications:'Duyệt đơn',
    chooseTheAmountOfWork: 'Chọn số công',
    halfWork: 'Nửa công',
    oneWork: 'Một công',
    sureClaimTime: 'Bạn có chắc chắn muốn xoá đơn bù công này không? ',
    totalClaimTime: 'Tổng số đơn bù công',
    userName: 'Tên đăng nhập',
    titleListCheckin:'Danh sách Checkin',
    taxCode : 'Mã số thuế',
    logout: 'Đăng xuất',
    description: 'Mô tả',
    addCompany : 'Thêm công ty',
    totalCompany: 'Tổng số công ty',
    monthlyStatistics: 'Thông kê tháng',
    leaveRemain: 'Số phép còn lại',
    workNumber: 'Số ngày làm việc',
    workspaceTitle: 'Workspace của bạn',
    personal: 'Cá nhân',
    personalInformation: 'Thông tin cá nhân',
    timekeeping: 'Chấm công',
    leaveDetails: 'Chi tiết nghỉ phép',
    inoutDetails: 'Chi tiết ra vào',
    oldPassword: 'Mật khẩu cũ',
    downloadFileTemplate: 'Tải xuống file mẫu',
    importUserFromFile: 'Nhập nhân viên từ file',
    searchUser: 'Tên hoặc mã nhân viên',
    leave: 'Nghỉ phép',
    reasonDenied: 'Lý do từ chối',
    personalPage: 'Trang cá nhân',
    moreDepartments: 'Thêm phòng ban',
    nameBranch: 'Tên chi nhánh',
    chooseDepartment: "Chọn phòng ban",
    noNodeChildren: "Không có cấp con",
    changeDepartment: "Chuyển phòng ban",
    leaveType: "Loại đơn báo",
    addLeaveType: "Thêm loại đơn báo",
    totalLeaveType: "Tổng số đơn báo",
    leaveTypeName: "Tên loại đơn báo",
    leaveTypeCode: "Tên viết tắt",
    chooseMonth: "Chọn tháng",
    chooseYear: "Chọn năm",
    positionName: "Tên chức vụ",
    addPosition: "Thêm chức vụ",
    choosePosition: "Chọn chức vụ",
    preview: "Xem trước",
    exportPdf: "Xuất ra pdf",
    exportExcel: "Xuất ra excel",
    groupUser: "Nhóm nhân sự",
    totalGroup: "Tổng số nhóm",
    addGroupUser: "Thêm nhóm nhân sự",
    groupName: "Tên nhóm",
    note: "Ghi chú",
    sureDeleteGroup: "Bạn có chắc muốn xóa nhóm nhân sự này?",
    print: "In",
    annualLeave: "Số ngày nghỉ phép"
  }
}
