<template>
  <div id="main-default" class="container-fluid ">
    <div class="card col-12">
      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-md-6 col-xs-12">
              <h1 class="page-title mt-3">{{ $t('menu.arrangeShifts') }}</h1>
              <p>{{$t('label.staffInShift')}}: {{totalRecords}}</p>
            </div>
            <div class="col-md-6 col-xs-12 text-right">
              <div  class="mt-3">
                <button class="btn btn-danger bg-gradient-danger" @click="removeSelectedUserFromShift()"><font-awesome-icon icon="trash" /> {{ $t("label.removeFromShift") }}</button>
                <button @click="addStaffToShift()" class="btn  btn-success bg-gradient-success"><font-awesome-icon icon="fas fa-plus-square" /> {{ $t("label.addToShift") }}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xs-12 col-md-4">
              <input type="text" :placeholder="$t('label.searchUser')" v-model="username" class="form-control  form-control-alternative">
            </div>
              <div class="col-xs-12 col-md-4" v-if="isSupperAdmin || isComAdmin">
                <b-form-select
                  id="ddlBranch"
                  v-model="branch"
                  :options="branchs"
                  class="form-control form-control-alternative"
                  name="branch"
                ></b-form-select>
              </div>
              <div class="col-xs-12 col-md-4">
                  <button class="btn btn-info  bg-gradient-info w-100" @click="getUserShift()">{{$t('label.filter')}}</button>
              </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row mt-3">
            <div class="col-xl-12 text-right pr-2">
                <button class="btn btn-primary mr-2"
                        @click="downloadFileTemplate()">
                  <font-awesome-icon icon="download" />
                  {{ $t('label.downloadFileTemplate') }}
                </button>
                <b-form-file style="width: auto; text-align: left"
                  :placeholder="$t('label.importUserFromFile')"
                  drop-placeholder="Drop file here..."
                  accept=".xlsx,.xls,.xlt"
                  v-on:change="importUserToShift($event.target.files)"
                ></b-form-file>
            </div>
            <div class="col-xl-12">
              <b-table
                striped
                bordered
                class=" default-table mt-2"
                hover
                :items="dataTable"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                responsive
                id="table-inouthistory"
              >
                <template v-slot:head(select)="row">
                  <b-form-checkbox @click.native.stop @change="selectAllUser(data)" v-model="selectedAll">
                  </b-form-checkbox>
                </template>

                <template v-slot:cell(select)="data">
                  <b-form-checkbox @click.native.stop :value="data.item.id" v-model="selected">
                  </b-form-checkbox>
                </template>
                <template #cell(aliasID)="data"> {{data.item.aliasID}}</template>
                <template #cell(name)="data"> {{data.item.fullName}}</template>
                <template #cell(email)="data"> {{data.item.email}} </template>
                <template #cell(branch)="data"> {{data.item.branch_name}} </template>
                <template #cell(button)="data">
                  <b-button
                    class="btn btn-warning btn-sm"
                    depressed
                    elevation="2"
                    title="Xoá khỏi ca"
                    @click="removeFromShift(data.item.id)"
                  ><font-awesome-icon icon="trash"
                  /></b-button>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="dataTable.length"
                :per-page="perPage"
                align="right"
                size="sm"
                class="m-4"
                aria-controls="table-inouthistory"
              ></b-pagination>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <b-modal
            v-model="modalAddStaffToShift"
            id="modal-add-shift"
            ref="modal-add-shift"
            title="Thêm nhân viên vào ca"
            header-bg-variant="gradient-primary"
            header-text-variant="light"
            body-bg-variant="secondary"
            hide-footer
            centered
            size="xl"
          >
            <div class="row">
              <div class="card-body m-0 p-0 table-responsive">
                <div class="row col-12 mb-lg-2">
                  <div class="col-md-6 col-xs-12 mb-2">
                    <treeselect v-model="dpmSearch" :multiple="true" :options="dpoptions" :flat="true"
                          :default-expand-level="1" :placeholder="$t('label.chooseDepartment')" />
                  </div>
                  <div class="col-md-6 col-xs-12 mb-2">
                    <treeselect v-model="psSearch" :multiple="true" :options="positionTree" :flat="true"
                          :default-expand-level="1" :placeholder="$t('label.choosePosition')" />
                  </div>
                  <div class="col-md-6 col-xs-12 mb-2">
                    <b-form-select
                      id="ddlGroupUser"
                      v-model="guSearch"
                      :options="groupUsers"
                      class="form-control form-control-alternative"
                      name="branch"
                    ></b-form-select>
                  </div>
                  <div class="col-md-6 col-xs-12">
                    <input type="text" v-model="addusername" :placeholder="$t('label.searchUser')" class="form-control">
                  </div>
                  <div class="col-md-6 col-xs-12">
                    <b-button variant="btn btn-info  btn-outline-primary" @click="searchUserOutShift()">{{$t('label.search')}}
                    </b-button>
                  </div>
                </div>
                <b-table
                  striped
                  bordered
                  class="table table-small table-hover table-striped"
                  head-variant="light"
                  hover
                  :items="userDataTable"
                  :fields="userFields"
                  :current-page="userCurrentPage"
                  :per-page="userPerPage"
                  responsive
                  id="table-inouthistory"
                >
                <template #head(checkbox)="data">
                      <b-form-checkbox @click.native.stop @change="selectAll(data)" v-model="allSelected">
                      </b-form-checkbox>
                    </template>
                  <template #cell(avatar)="data">
                    <a href="javascript:void(0)">
                      <img width="75px" height="75px" style="border-radius:8px;max-width:75px;" :src="data.item.avatar" :alt="data.item.fullName"/>
                    </a>
                  </template>
                  <template #cell(aliasID)="data"> {{data.item.aliasID}}</template>
                  <template #cell(name)="data"> {{data.item.fullName}}</template>
                  <template #cell(email)="data"> {{data.item.email}} </template>
                  <template #cell(branch)="data"> {{data.item.branch_name}} </template>
                  <template #cell(checkbox)="data">
                      <b-form-checkbox @click.native.stop :value="data.item.id" v-model="userSelected">
                      </b-form-checkbox>
                  </template>
                </b-table>
                <b-pagination
                  v-model="userCurrentPage"
                  :total-rows="userDataTable.length"
                  :per-page="userPerPage"
                  align="right"
                  size="sm"
                  class="m-4"
                  aria-controls="table-inouthistory"
                ></b-pagination>
                <div class="mt-3 text-center">
                  <b-button variant="primary" @click="saveUserToShifts()">{{ $t("label.save") }}</b-button>
                  <b-button variant="secondary" @click="$bvModal.hide('modal-add-shift')">{{
                    $t("label.cancel")
                    }}</b-button>
                </div>
              </div>
            </div>
          </b-modal>
          <b-modal
            v-model="modalConfirmDelete"
            id="modal-confirm-delete"
            hide-header
            hide-footer
            header-bg-variant="secondary"
            modal-class="modal-delete text-center"
            centered
            size="md">
            <div class="modal-delete--warning">
              <div class="modal-delete--warning__content">!</div>
            </div>
            <div>
              <h2 class="text-warning">{{ $t('label.areYouSure') }}?</h2>
            </div>
            <div>{{ $t('label.sureDeleteMember') }}</div>
            <div class="mt-3">
              <b-button variant="primary" @click="confirmDelete()">{{
                $t('label.iAgree')
                }}
              </b-button>
              <b-button variant="secondary" @click="$bvModal.hide('modal-confirm-delete')">{{
                $t('label.cancel')
                }}
              </b-button>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import apiServer from '@/axiosServer'
  import Treeselect from '@riophae/vue-treeselect'

  export default {
    components: {
      Treeselect
    },
    data () {
      return {
        isSupperAdmin: false,
        isComAdmin: false,
        dataTable: [{ name: 'Ca làm việc' }],
        fields: [
          {
            label: 'select',
            key: 'select',
            class: 'align-middle',
            sortable: false
          },
          {
            label: this.$t('label.employeeCode'),
            key: 'aliasID',
            class: 'align-middle',
            sortable: false
          },
          {
            label: this.$t('label.fullName'),
            key: 'fullName',
            class: 'align-middle',
            sortable: false
          },
          {
            label: 'Email',
            key: 'email',
            class: 'align-middle',
            sortable: false
          },
          {
            label: this.$t('label.branch'),
            key: 'branch',
            class: 'align-middle',
            sortable: true
          },
          {
            label: this.$t('label.control'),
            key: 'button',
            tdClass: 'text-center',
            thClass: 'text-center'
          }
        ],
        currentPage: 1,
        perPage: 25,
        shiftId: undefined,
        branchs: [],
        totalRecords: 0,
        branch: null,
        username: '',
        addusername: '',
        allSelected: false,
        selectedAll: false,
        selected: [],
        modalAddStaffToShift: false,
        userDataTable: [],
        userFields: [
          {
            label: this.$t('label.image'),
            key: 'avatar',
            class: 'align-middle',
            sortable: false
          },
          {
            label: this.$t('label.employeeCode'),
            key: 'aliasID',
            class: 'align-middle',
            sortable: false
          },
          {
            label: this.$t('label.fullName'),
            key: 'fullName',
            class: 'align-middle',
            sortable: false
          },
          {
            label: 'Email',
            key: 'email',
            class: 'align-middle',
            sortable: false
          },
          {
            label: this.$t('label.branch'),
            key: 'branch',
            class: 'align-middle',
            sortable: true
          },
          {
            label: '',
            key: 'checkbox',
            class: 'align-middle',
            sortable: false
          }
        ],
        userCurrentPage: 1,
        userPerPage: 15,
        userSelected: [],
        modalConfirmDelete: false,
        userId: 0,
        loading: false,
        dpmSearch: null,
        dpoptions: [],
        positionTree: [],
        psSearch: null,
        groupUsers: [],
        guSearch: null
      }
    },
    created() {
        this.shiftId = this.$route.params.id;
      if (this.$store.getters.superAdmin == true) {
        this.isSupperAdmin = true;
      }
      if (this.$store.getters.comAdmin == true) {
        this.isComAdmin = true;
      }
      if (this.isSupperAdmin || this.isComAdmin){
        this.getListBranch();
      }
        this.getUserShift();
    },
    methods: {
      async getUserShift(){
        let res
        try {
          res = await apiServer.post('api/v1/user/get-user-by-shift', {
            id: this.shiftId,
            branch: this.branch,
            username: this.username,
            inshift: true
          })
        } catch (error) {
          console.log(error);
        }
        if (res && res.data) {
          this.dataTable = res.data
          this.totalRecords = res.data.length
        }
      },
      async getListBranch(){
        let res;
        try{
          res = await apiServer.post('/api/v1/company/get-all-branch',{});
          if (res && res.data) {
            this.branchs.push({value:null, text: this.$t('label.allBranches')});
            for (let br of res.data) {
              this.branchs.push({value: br.placeID, text:br.branch_name});
            }
          }
        }
        catch (e) {
          console.log(e);
        }
      },
      async getDepartmentTree(){
      let res
      try {
        res = await apiServer.get('api/v1/department/tree', {})
      } catch (error) {
      }
      if (res && res.data) {
        this.dpoptions = [res.data];
      }
    },
    async getPositionTree(){
      let res
      try {
        res = await apiServer.get('api/v1/position/tree', {})
      } catch (error) {
      }
      if (res && res.data) {
        this.positionTree = [res.data];
      }
    },
    async getGroupUser () {
      let res
      try {
        res = await apiServer.post('api/v1/group-user/list', {
          placeId: this.branch
        })
      } catch (error) {}
      if (res && res.data) {
        this.groupUsers = [];
        this.groupUsers.push({value: null, text: this.$t("label.all")});
        var data = res.data;
        if (data.length > 0) {
          for(var dt of data){
            this.groupUsers.push({value: dt.id, text: dt.group_name});
          }
        }
      }
    },
      async addStaffToShift(){
        this.modalAddStaffToShift = true;
        this.allSelected = false;
        await this.searchUserOutShift();
        await this.getDepartmentTree();
        await this.getPositionTree();
        await this.getGroupUser();
      },
      async searchUserOutShift(){
        if (this.dpmSearch && this.dpmSearch.length == 0){
          this.dpmSearch = null;
        }
        let res;
        try {
          res = await apiServer.post('api/v1/user/get-user-by-shift', {
            id: this.shiftId,
            branch: this.branch,
            username: this.addusername,
            inShift: false,
            departments: this.dpmSearch,
            positions: this.psSearch,
            groupUser: this.guSearch
          })
        } catch (error) {
          console.log(error);
        }
        if (res && res.data) {
            this.userDataTable = res.data
        }
        this.userSelected = [];
      },
      async saveUserToShifts(){
        if(this.userSelected == null || this.userSelected.length == 0){
          alert('Bạn chưa chọn nhân viên nào');
          return false;
        }
        let res
        try {
          res = await apiServer.post('/api/v1/shifts/add-user-to-shift', {
            users: this.userSelected,
            shiftId: this.shiftId
          })
        } catch (error) {
          if (error.response.status === 400) {
            console.log(error.response.data);
          } else if (error.response.status === 401) {
            alert(error.response.data)
          }
        }
        if (res && res.data) {
          this.modalAddStaffToShift = false;
          this.$notify({
            group: 'bottomnotification',
            title: 'Thông báo',
            text: 'Thêm nhân viên vào ca thành công'
          });
          this.getUserShift();
        }
      },
      selectAllUser () {
        if (this.selectedAll == true){
          this.selected = this.dataTable.map(x => x.id);
        }
        else {
          this.selected = [];
        }
      },
      selectAll () {
        if (this.allSelected == true){
          this.userSelected = this.userDataTable.map(x => x.id);
        }
        else {
          this.userSelected = [];
        }
      },
      showConfirmDelete(id){
        this.userId = id;
        this.modalConfirmDelete = true;
      },
      confirmDelete(){
        if(this.userId > 0){
          this.removeUsersFromShift([this.userId], this.shiftId, 'Xoá nhân viên khỏi ca thành công')
        }
        else{
          this.removeUsersFromShift(this.selected, this.shiftId, 'Xoá nhân viên khỏi ca thành công');
        }
        this.modalConfirmDelete = false;
      },
      removeFromShift(user_id){
        this.showConfirmDelete(user_id);
      },
      removeSelectedUserFromShift(){
        if(this.selected.length == 0){
          alert('Bạn chưa chọn nhân viên nào để xoá khỏi ca');
          return false;
        }
        this.showConfirmDelete(0);
      },
      async removeUsersFromShift(users,shiftId, message){
        let res
        try {
          res = await apiServer.post('/api/v1/shifts/remove-user-from-shift', {
            users: users.toString(),
            shiftId: shiftId
          })
        } catch (error) {
          if (error.response.status === 400) {
            console.log(error.response.data);
          } else if (error.response.status === 401) {
             alert(this.$t(error.response.data))
          }
        }
        if (res && res.data) {
          this.$notify({
            group: 'bottomnotification',
            title: 'Thông báo',
            text: message
          });
          this.getUserShift();
        }
      },
      async downloadFileTemplate() {
      let res;
      try {
        res = await apiServer.get("/api/v1/user/download-user-shift-template", {
          responseType: 'blob'
        }).then((response) => {
          const url = URL.createObjectURL(new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', "import_user_to_shift")
          document.body.appendChild(link)
          link.click()
        });
      } catch (error) {

      }
    },
    async importUserToShift(files) {
      let formData = new FormData();
      formData.append("file", files[0]);
      formData.append("shiftid", this.shiftId);
      const config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      };
      let res;
      this.msg = [];
      try {
        this.loading = true;
        res = await apiServer.post(
          'api/v1/user/import-user-to-shift',
          formData,
          config
        )
      } catch (error) {
        if (error.response.status === 400) {
          this.loading = false;
          alert(error.response.data.message);
        }
      }
      finally {
        this.loading = false;
      }
      if (res && res.data) {
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notification'),
            text: this.$t(res.data.message),
            position:'bottom-center'
          });
        await this.getUserShift();
      }
    }
    }
  }
</script>

<style scoped>
  table tr .custom-checkbox{
    text-align: center !important;
  }
</style>
