<template>
    <div class="row">
        <div class="card-body m-0 p-0">
        <table id="tbl-preview" class="table b-table table-striped table-hover table-bordered">
            <tr v-for="(row, i) in previewData" :key="i">
            <td v-if="i == 0" :colspan="row.length">{{row[1]}}</td>
            <template v-else>
                <td v-for="(cell, j) in row" v-if="j > 0" :key="j">{{ cell }}</td>
            </template>
            </tr>
        </table>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        previewData: {
            type: [Array,Object]
        }
    }
}
</script>
<style scoped>
  #tbl-preview tr:first-child td {
    text-align: center;
    font-weight: bold;
  }
</style>