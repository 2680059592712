<template>
  <div id="main-default" class="container-fluid">
    <div class="card col-xl-12">
      <div class="row">
        <div class="col-xl-12">
          <div class="row">
            <div class="col-md-6 col-xs-12">
              <h1 class="page-title mt-2">
                {{ $t("title.leaveTypeManagement") }}
              </h1>
              <p class="text-muted">
                {{ $t("label.totalLeaveType") }}  {{dataTable.length}}
              </p>
            </div>
            <div class="col-md-6 col-xs-12 text-right">
              <div class="mt-4">
                <a href="javascript:void(0)" @click="showModalAddLeaveType()" class="btn btn-danger bg-gradient-danger">
                  <font-awesome-icon icon="plus" />
                  {{ $t("label.addNew") }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xs-12 col-md-3" v-if="isSupperAdmin || isComAdmin">
              <b-form-group>
                <select v-model="searchBranch" class="form-control">
                  <option v-for="branch in branches"
                      v-bind:value="branch.value">
                    {{ branch.text }}
                  </option>
                </select>
              </b-form-group>
            </div>
            <div class="col-xs-12 col-md-3">
              <input
                  type="text"
                  :placeholder="$t('label.leaveTypeCode')"
                  class="form-control"
                  v-model="searchCode"
              />
            </div>
            <div class="col-xs-12 col-md-3">
              <input
                  type="text"
                  :placeholder="$t('label.leaveTypeName')"
                  class="form-control"
                  v-model="searchName"
              />
            </div>
            <div class="col-md-3 col-xs-12">
              <b-button
                  variant="btn btn-info  btn-outline-primary w-100"
                  @click="getListLeaveType()"
              >{{$t('label.search')}}
              </b-button>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row mt-3">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body m-0 p-0 table-responsive">
                  <b-table
                      bordered="bordered"
                      class="default-table"
                      hover
                      :items="dataTable"
                      :fields="fields"
                      responsive
                      :current-page="currentPage"
                      :per-page="perPage"
                      id="table-leave-type"
                  >
                    <template #cell(function)="data">
                      <button
                          class="btn btn-primary btn-sm"
                          @click="showEdit(data.item)"
                          v-b-tooltip.hover
                          :title="$t('label.edit')"
                      >
                        <font-awesome-icon icon="edit"/>
                      </button>
                      <button
                          class="btn btn-danger btn-sm"
                          @click="showDelete(data.item.hash_id)"
                          v-b-tooltip.hover
                          :title="$t('label.delete')">
                        <font-awesome-icon icon="trash" />
                      </button>
                    </template>
                  </b-table>
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="dataTable.length"
                      :per-page="perPage"
                      align="right"
                      size="sm"
                      class="m-4"
                      aria-controls="table-leave-type"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
        v-model="modalAddLeave"
        id="modal-add-leave"
        :title="$t('label.addLeaveType')"
        hide-footer
        header-bg-variant="secondary"
        centered
        size="md"
    >
      <b-form ref="form">
        <b-form-group v-if="isSupperAdmin || isComAdmin"
            id="input-group-2"
            :label="$t('label.branch')">
          <select v-model="branchId" class="form-control">
            <option v-for="branch in branches"
                    v-bind:value="branch.value">
              {{ branch.text }}
            </option>
          </select>
          <div class="error-message w-100 clear text-left">
            {{ msg['branchId'] ? $t(msg['branchId']) : ''  }}
          </div>
        </b-form-group>
        <b-form-group
            id="input-group-3"
            :label="$t('label.leaveTypeCode')">
          <b-form-input
              v-model="leaveTypeCode"
              type="text"
              class="form-control-sm"
              required></b-form-input>
          <div class="error-message w-100 clear text-left">
            {{ msg['code'] ? $t(msg['code']) : ''  }}
          </div>
        </b-form-group>
        <b-form-group
            id="input-group-4"
            :label="$t('label.leaveTypeName')">
          <b-form-input
              v-model="leaveTypeName"
              type="text"
              class="form-control-sm"
              required></b-form-input>
          <div class="error-message w-100 clear text-left">
            {{ msg['name'] ? $t(msg['name']) : ''  }}
          </div>
        </b-form-group>
      </b-form>
      <div class="d-block text-center">
        <div class="text-center">
          <b-button class="btn btn-danger"  @click="saveLeaveType">{{
              $t('label.save')
            }}
          </b-button>
          <b-button class="btn btn-default" @click="$bvModal.hide('modal-add-leave')">{{
              $t('label.cancel')
            }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
        v-model="modalDelete"
        id="modal-delete"
        hide-header
        hide-footer
        header-bg-variant="secondary"
        modal-class="modal-delete text-center"
        centered
        size="md"
    >
      <div>
        <div class="modal-delete--warning">
          <div class="modal-delete--warning__content">!</div>
        </div>
        <div>
          <h2 class="text-warning">{{ $t("label.areYouSure") }}?</h2>
        </div>
        <div>{{ $t("label.sureLeaveType") }}</div>
        <div class="mt-3">
          <b-button variant="primary" @click="deleteLeaveType()"
          >{{ $t("label.iAgree") }}
          </b-button>
          <b-button variant="secondary" @click="$bvModal.hide('modal-delete')"
          >{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div> </b-modal
    >
  </div>
</template>

<script>
import apiServer from "@/axiosServer";

export default {
  data() {
    return {
      isSupperAdmin: false,
      isComAdmin: false,
      modalDelete: false,
      modalAddLeave: false,
      dataTable: [],
      showDeleteSuccess: false,
      currentPage: 1,
      perPage: 25,
      totalTakeLeave: 5,
      fields: [
        {
          label: this.$t("label.leaveTypeCode"),
          key: "code",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.leaveTypeName"),
          key: "name",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("label.branch"),
          key: "branch_name",
          class: "align-middle",
          sortable: false,
        },
        {
          label: "",
          key: "function",
          class: "align-middle text-center",
          sortable: true,
        },
      ],
      searchBranch: null,
      searchCode: null,
      searchName: null,
      branchId: null,
      branches: [],
      leaveTypeCode: '',
      leaveTypeName: '',
      id: null,
      idDelete: null,
      msg: []
    };
  },
  created() {
    if (this.$store.getters.superAdmin == true) {
      this.isSupperAdmin = true;
    }
    if (this.$store.getters.comAdmin == true) {
      this.isComAdmin = true;
    }
    if (this.isSupperAdmin || this.isComAdmin) {
      this.getListBranch();
    }
    this.getListLeaveType();
  },
  methods: {
    async getListLeaveType(){
      let res;
      try{
        res = await apiServer.post('/api/v1/leave-type/get-list',{
          name: this.searchName,
          code: this.searchCode,
          placeId: this.searchBranch
        });
        if (res && res.data) {
          this.dataTable = res.data
        }
      }
      catch (error) {
        if (error.response.status === 400) {
          console.log(error.response.message);
        }
      }
    },
    async saveLeaveType(){
      let res;
      try{
        res = await apiServer.post('/api/v1/leave-type/save',{
          id: this.id,
          name: this.leaveTypeName,
          code: this.leaveTypeCode,
          branch_id: this.branchId
        });
        if (res && res.data) {
            this.modalAddLeave = false;
            this.$notify({
              group: 'bottomnotification',
              title: this.$t('label.notification'),
              text: this.$t(res.data.message),
              position: 'bottom-center'
            });
            this.getListLeaveType();
        }
      }
      catch (error) {
        if (error.response.status === 400) {
          this.msg = (error.response.data)
        } else if (error.response.status === 401) {
          alert(error.response.data)
        }
      }
    },
    async getListBranch(){
      let res;
      try{
        res = await apiServer.post('/api/v1/company/get-all-branch',{});
        if (res && res.data) {
          this.branches.push({value:null, text: this.$t('label.allBranches')});
          for (let br of res.data) {
            this.branches.push({value: br.placeID, text:br.branch_name});
          }
        }
      }
      catch (e) {
        console.log(e);
      }
    },
    showModalAddLeaveType(){
      this.branchId = null;
      this.leaveTypeCode = null;
      this.leaveTypeName = null;
      this.modalAddLeave = true;
    },
    showDelete(idDelete) {
      this.idDelete = idDelete;
      this.modalDelete = true;
    },
    showEdit(item){
      this.modalAddLeave = true;
      this.id = item.hash_id;
      this.branchId = item.branch_id;
      this.leaveTypeCode = item.code;
      this.leaveTypeName = item.name;
    },
    async deleteLeaveType(){
      let res;
      try{
        res = await apiServer.post('/api/v1/leave-type/delete',{
          id: this.idDelete,
        });
        if (res && res.data) {
          this.modalDelete = false;
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notification'),
            text: this.$t(res.data.message),
            position:'bottom-center'
          });
          this.getListLeaveType();
        }
      }
      catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
