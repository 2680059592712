<template>
  <!-- Page content -->
  <div class="container-fluid mt-3">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header border-0">
            <h1 class="page-title mt-2 mb-4">
              {{ $t("menu.inOutHistory") }}
            </h1>
            <div class="row align-items-center">
              <div class="col-md-3 col-xs-12 mt-4">
                <label>{{ $t('label.start') }}</label>
                <flat-pickr
                  v-model="startDateString"
                  :config="configDateTimePicker"
                  :placeholder='$t("label.chooseDate")'
                  class="form-control"></flat-pickr>
              </div>
              <div class="col-md-3 col-xs-12 mt-4">
                <label>{{ $t('label.end') }}</label>
                <flat-pickr v-model="endDateString" :config="configDateTimePicker"
                            name="birthdate"
                            :placeholder='$t("label.chooseDate")'
                            class="form-control"></flat-pickr>
              </div>
              <div class="col-md-3 col-xs-12 mt-4">
                <label>{{$t('label.selectTime')}}</label>
                <select class="form-control" v-model="modelEsc">
                  <option
                    v-for="optionsEscItem in optionsEsc"
                    v-bind:value="optionsEscItem.value"
                  >
                    {{ optionsEscItem.text }}
                  </option>
                </select>
              </div>
              <div class="col-md-3 col-xs-12 mt-4">
                <b-button variant="btn btn-info  btn-outline-primary w-100 " style="margin-top: 29px;"  @click="search('search')">{{$t('label.search')}}
                </b-button>
              </div>
            </div>
          </div>
          <div class="number-records"><span>{{$t('label.totalRecord')}}{{dataTable.length}}</span></div>
          <div class="col-12">
            <b-table
            striped bordered
            class="default-table table"
            hover
            :items="dataTable"
            :fields="fields"
            responsive
            :current-page="currentPage"
            :per-page="perPage"
            id="table-inouthistory"
            :busy="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(image)="data">
              <a href="javascript:void(0)" @click="showImage(data.item.avatar)">
                <img width="95px" height="95px" :src="data.item.avatar" :alt="data.item.personName"/>
              </a>
            </template>
            <template #cell(checkinTime)="data">
              <div>{{ convertTimestampToDate(data.item.checkinTime) }}</div>
              <span>{{ convertTimestampToTime(data.item.checkinTime) }}</span>
            </template>
          </b-table>
          </div>
          
          <b-pagination
            v-model="currentPage"
            :total-rows="dataTable.length"
            :per-page="perPage"
            align="right"
            size="sm"
            class="m-4"
            aria-controls="table-inouthistory"
          ></b-pagination>
        </div>
      </div>
    </div>
    <b-modal v-model="modalImage" hide-footer centered hide-header modal-class="modal-image inouthistory" size="lg" >
      <div class="btn-close"><span @click="hideImage()">x</span></div>
      <div class="d-block text-center">
        <img :src="urlImage" style="width:530px;padding:15px"/>
      </div>
    </b-modal>
  </div>
</template>
<script>
import apiClient from "@/axios";
import apiServer from "@/axiosServer";
import flatPickr from 'vue-flatpickr-component'
import FlatpickrLanguages from "flatpickr/dist/l10n"
import moment from 'moment'

export default {
  components: {
    flatPickr
  },
  data () {
    return {
      urlImage: '',
      modalImage: false,
      isBusy: true,
      startDateString: '',
      endDateString: '',
      nowDate: '',
      modelEsc: 'desc',
      configDateTimePicker: {
        enableTime: true,
        dateFormat: 'd/m/Y H:i:ss',
        time_24hr: true,
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true
      },
      optionsEsc: [
        {
          value: 'asc',
          text: this.$t('label.asc')
        },
        {
          value: 'desc',
          text: this.$t('label.desc')
        }
      ],
      dataTable: [],
      currentPage: 1,
      perPage: 25,
      placeId: 0,
      fields: [
        {
          label: this.$t('label.camera'),
          key: 'deviceName',
          class: "align-middle",
          sortable: false
        },
        {
          label: this.$t('label.image'),
          key: 'image',
          class: "align-middle text-center",
          sortable: false
        },
        {
          label: this.$t('label.time'),
          key: 'checkinTime',
          class: "align-middle",
          sortable: true
        }
      ],
    }
  },
  mounted () {
    this.getCheckinByPlaceIdInTimestamp()
    if(this.$store.getters.locale == 'vi'){
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"]
    };
  },
  methods: {
      
    hideImage () {
      this.modalImage = false
    },
    showImage (urlImage) {
      this.urlImage = urlImage
      this.modalImage = true
    },
    async getCheckinByPlaceIdInTimestamp () {
      let res
      try {
        res = await apiServer.post('api/v1/user/in-out-history', {
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.dataTable = res.data
        this.isBusy = false
      }
    },


    getNowDate () {
      let today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0')
      const yyyy = today.getFullYear()
      today = dd + '/' + mm + '/' + yyyy
      return today
    },
    convertTimestampToDate (timestamp) {
      return moment(timestamp).format('DD/MM/yyyy');
    },
    convertTimestampToTime (timestamp) {
      return moment(timestamp).format('HH:mm:ss');
    },
    
    convertTimestampSendDate (date) {
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....) (..):(..)/, '$3-$2-$1 $4:$5');
      var date = new Date(changedDate);
      return moment(date.getTime()).format('yyyy-MM-DD HH:mm:ss')
    },
    
    convertDateToTimestamp (date) {
      if (date == null) return ''
      const newDate = new Date(date)
      return newDate.getTime()
    }
  }
}
</script>
<style lang="scss">
.number-records{
  padding-left: 1.25rem;
  padding-bottom: 0.25rem
}
.modal-image{
  img{
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 767px){
  .card .table td, .card .table th {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    font-size: 12px !important;
}
}
</style>
