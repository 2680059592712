<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid " style="padding-top:0px !important">
    <div class="row">
      <div class="col-12">

        <div>
          {{$t('label.turnAudioOn')}}
          <toggle-button
            :value=audioOn
            color="#FB6340"
            :labels=labelsAudio
            @change="clickAudio"
          />
        </div>
        <div>
          <video style="display:none" controls="" id="audio">
            <source id ="audio-source" src="../../assets/video/notification.mp3" type="audio/mpeg">
          </video>
        </div>
      </div>
      <div class="clear-fix"></div>
      </div>
      <div class="row mt-2">
        <div class="col-12 col-md-3">
          <div class="card border-0">
            <a
              href="javascript:void(0)"
               @click="redirectEmployeeManager"
              class="link-from-dashboard"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-8">
                    <span class="h2 font-weight-bold mb-0">{{this.dataDashBoard.accountNumber}}</span>
                    <p class="card-title text-muted mb-0">
                      {{ $t("label.employee") }}
                    </p>
                  </div>
                  <div class="col-4 icon-dashdoard">
                    <font-awesome-icon icon="users" style="color:#FB6340;" />
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="card border-0">
            <a href="#" class="link-from-dashboard">
              <div class="card-body">
                <div class="row">
                  <div class="col-8">
                    <span class="h2 font-weight-bold mb-0">{{this.dataDashBoard.checkedin}}</span>
                    <p class="card-title text-muted mb-0">
                      {{ $t("label.checkedin") }}
                    </p>
                  </div>
                  <div class="col-4 icon-dashdoard">
                    <font-awesome-icon icon="calendar-check" style="color:#FB6340;" />
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="card border-0">
            <a href="#" class="link-from-dashboard">
              <div class="card-body">
                <div class="row">
                  <div class="col-8">
                    <span class="h2 font-weight-bold mb-0">{{this.dataDashBoard.lateNumber}}</span>
                    <p class="card-title text-muted mb-0">
                      {{ $t("label.late") }}
                    </p>
                  </div>
                  <div class="col-4 icon-dashdoard">
                    <font-awesome-icon icon="clock" style="color:#FB6340;" />
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="card border-0">
            <a href="#" class="link-from-dashboard">
              <div class="card-body">
                <div class="row">
                  <div class="col-8">
                    <span class="h2 font-weight-bold mb-0">{{this.dataDashBoard.notCheckin}}</span>
                    <p class="card-title text-muted mb-0">
                      {{ $t("label.notCheckin") }}
                    </p>
                  </div>
                  <div class="col-4 icon-dashdoard">
                    <font-awesome-icon icon="calendar-times" style="color:#FB6340;" />
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="page-checkin">
      <div>
        <video style = "display:none" controls="" id = "audio">
          <source id ="audio-source" src="../../assets/video/notification.mp3" type="audio/mpeg">
        </video>
      </div>
      <div class="row mt--3 row-sm">
        <div class="item-grid col-lg-2 col-md-3 col-sm-6 col-xs-12 mt-3" v-for="user in users">
          <div class="item card mb-0">
            <a class="image">
              <img :src="user.detected_image_url"/>
            </a>
            <div class="info">
              <label>{{$t('label.nameEmployee')}}</label>: <span class="name w-100 clearfix">{{user.personName}}</span>
              <label>{{$t('label.timeDay')}}</label>: <span class="manv w-100 clearfix">{{user.date}}</span>
              <label>{{$t('label.camera')}}</label>: <span class="manv w-100 clearfix">{{user.deviceName}}</span>
            </div>
          </div>
        </div>
      </div>
      <b-modal modal-class="modal-notification" v-model="popupTurnOn" size="sm" :title="$t('label.notification')">
          {{$t('label.turnAudioOn')}}
          <template #modal-footer>
          <div class="w-100">
            <b-button
              variant="outline-secondary"
              class="float-right"
              @click="turnAudioOff()"
            >
              {{$t('label.cancel')}}
            </b-button>
            <b-button
              variant="primary"
              class="float-right mr-3"
              @click="turnAudioOn()"
            >
              {{$t('label.agree')}}
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>

  </div>
</template>
<script>
import apiClient from '@/axios'
import apiServer from '@/axiosServer'

export default {
  data() {
    return {
      dataDashBoard: [],
      users: [],
      popupTurnOn: false,
      audioOn: true,
      srcMp3: '',
      labelsAudio:{checked: this.$t('label.on'), unchecked: this.$t('label.off')}
    };
  },
  created() {
    this.audioOn = this.$store.getters.audio;
  },
  mounted() {
      this.getSummary();
      // document.getElementById('audio').play();
      document.getElementById('audio').click();
  },
  sockets: {
    connect: function () {
      console.debug('socket connected')
    },
    receiveMessage: function (user) {
      for (let i = 0; i < this.$store.getters.placeID.length; i++) {
        if (parseInt(this.$store.getters.placeID[i]) === parseInt(user.placeID)) {
          if ((this.audioOn) && (user.audio === true)) {
            const audioSrc = 'data:audio/mp3;base64,' + user.linkaudio
            document.getElementById('audio-source').src = audioSrc
            document.getElementById('audio').load()
            document.getElementById('audio').play()
          }
          this.users.unshift(user)
        }
      }
    }
  },
  methods:{
    async getSummary() {
      let res
      try {
        res = await apiServer.post('api/v1/dashboard/summary', {
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.dataDashBoard =
          {
              accountNumber: res.data.accountNumber,
              checkedin: res.data.checkedin,
              lateNumber: res.data.lateNumber,
              notCheckin: res.data.notCheckin,
          }
      }
    },
    clickAudio() {
      this.audioOn = !this.audioOn;
      this.$store.commit("updateAudio", this.audioOn);
    },

    redirectEmployeeManager(){
      this.$router.replace("/admin/employee-manager")
    }
  }
};
</script>
<style scoped lang="scss">
  .icon-dashdoard{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 40px;
  }
  .row-sm{
    margin-left: -5px;
    margin-right: -5px;
  }
  .item-grid{
    padding-left: 5px;
    padding-right: 5px;
  }
  .item{
    height: 100%;
    img{
      width: 100%;
      height: 250px;
    }
    .info{
      padding: 10px;
      font-size: 14px;
      label{
        font-weight: bold;
        margin-bottom: 0.2rem;
      }
    }
  }
.card-body{
  padding: .5rem;
}
</style>
