import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './locales'
import store from '@/store';

Vue.use(VueI18n);

export default new VueI18n({
    locale: store.getters.locale, // set locale
    fallbackLocale: 'vi', // set fallback locale
    messages // set locale messages
});
