<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid">
    <div class="card col-xl-12">
      <div class="row">
        <div class="col-xl-12">
          <h1 class="page-title mt-2 mb-4">
            {{ $t("title.setHoliday") }}
          </h1>
        </div>
        <div class="col-xl-12">
          <div class="row">
            <div class="col-5 col-md-2 pt-3">
              {{ $t("label.holidayList") }}
            </div>
            <div class="col-7 col-md-2">
              <b-form-group>
                <select class="form-control" v-model="yearChoose" @change="changeYear">
                  <option
                    v-for="holidayList in holidayLists"
                    v-bind:value="holidayList.value"
                  >
                    {{ holidayList.text }}
                  </option>
                </select>
              </b-form-group>
            </div>
            <div class="col-12 col-md-2 offset-md-6 text-right">
                <a
                  href="javascript:void(0)"
                  class="btn btn-danger bg-gradient-danger"
                  @click="showAddHoliday()"
                >
                  <font-awesome-icon icon="plus" />  {{ $t("label.addNew") }}</a
                >
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row mt-3">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body m-0 p-0 table-responsive">
                  <b-table
                    bordered="bordered"
                    class="default-table"
                    hover
                    :items="dataTable"
                    :fields="fields"
                    responsive
                    :current-page="currentPage"
                    :per-page="perPage"
                    id="table-inouthistory"
                  >
                    <template #cell(holiday_name)="data">
                      <span>{{ data.item.holiday_name | truncate(40, '...') }}</span>
                    </template>
                    <template #cell(date)="data">
                      <template v-if="data.item.from_date != data.item.to_date">
                          {{ convertDate(data.item.from_date) + ' - ' + convertDate(data.item.to_date) }}
                        </template>
                        <template v-else>
                          {{ convertDate(data.item.to_date) }}
                        </template>
                    </template>
                    <template #cell(function)="data">
                      <button
                        class="btn btn-warning btn-sm"
                        @click="showEditHoliday(data.item.id)"
                        v-b-tooltip.hover
                        :title="$t('label.edit')"
                      >
                        <font-awesome-icon icon="edit"/>
                      </button>
                      <button
                        class="btn btn-danger btn-sm"
                        @click="showDelete(data.item.id)"
                        v-b-tooltip.hover
                        :title="$t('label.delete')"
                      >
                        <font-awesome-icon icon="trash" />
                      </button>
                    </template>
                  </b-table>
                  <b-pagination
                  v-model="currentPage"
                  :total-rows="dataTable.length"
                  :per-page="perPage"
                  align="right"
                  size="sm"
                  class="m-4"
                  aria-controls="table-inouthistory"
                ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="modalHoliday"
      ref="modal-add-holiday"
      :title="$t('label.addEdit') + ' ' + $t('label.holiday')"
      header-bg-variant="secondary"
      hide-footer
      centered
      size="lg"
    >
      <div class="form-group" v-if="isSupperAdmin">
          <div class="mb-1">{{$t('label.company')}}</div>
          <select v-model="company" class="form-control" @change="changeCompany" required>
            <option v-for="cp in companies" v-bind:value="cp.value">
              {{ cp.text }}
            </option>
          </select>
          <div class="error-message w-100 clear text-left">
            {{ msg['company'] ? msg['company'] : ''  }}
          </div>
      </div>
      <div class="form-group" v-if="isSupperAdmin || isComAdmin">
        <div class="mb-1">{{$t('label.branch')}}</div>
          <select v-model="placeId" class="form-control">
            <option
              v-for="branch in branchs"
              v-bind:value="branch.value"
            >
              {{ branch.text }}
            </option>
          </select>
        <div class="error-message w-100 clear text-left">
          {{ msg['placeId'] ? msg['placeId'] : ''  }}
        </div>
      </div>
      <div class="form-group">
        <div class="mb-1">{{$t('label.enterHolidayName')}}</div>
        <input type="text" v-model="nameHoliday" class="form-control" :placeholder="$t('label.nameHoliday')">
        <div class="error-message w-100 clear text-left">
          {{ msg['holidayName'] ? $t(msg['holidayName']) : ''  }}
        </div>
      </div>
      <div class="form-group">
        <div class="mb-1">{{$t('label.timeDay')}}</div>
        <flat-pickr
          v-model="dateSelect"
          :config="configDateTimePicker"
          :placeholder='$t("label.chooseDate")'
          name="reportDate"
          class="form-control"></flat-pickr>
          <div class="error-message w-100 clear text-left">
            {{ msg['fromDate'] && msg['fromDate'][0] ? $t(msg['fromDate']) : ''  }}
          </div>
      </div>
      <div>
        <b-form-checkbox
        v-model="publicity"
        value="1"
        unchecked-value="0"
      >
        {{$t('label.applyPublicity')}}
      </b-form-checkbox>
      </div>
      <div class="d-block text-center">
        <div class="text-center">
          <b-button class="btn btn-danger" @click="addHoliday">{{
            $t('label.save')
            }}
          </b-button>
          <b-button class="btn btn-default" @click="hideModalAddHoliday">{{
            $t('label.cancel')
            }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- modal add  -->
    <b-modal
      v-model="modalEditHoliday"
      ref="modal-edit-holiday"
      :title="$t('label.addEdit') + ' ' + $t('label.holiday')"
      header-bg-variant="secondary"
      hide-footer
      centered
      size="lg"
    >
      <div class="form-group" v-if="isSupperAdmin">
        <div class="mb-1">{{$t('label.company')}}</div>
        <select v-model="company" class="form-control" required>
          <option v-for="cp in companies" v-bind:value="cp.value">
            {{ cp.text }}
          </option>
        </select>
        <div class="error-message w-100 clear text-left">
          {{ msg['company'] ? msg['company'][0] : ''  }}
        </div>
      </div>
      <div class="form-group">
        <div class="mb-1">{{$t('label.enterHolidayName')}}</div>
        <input type="text" v-model="editHolidayInfo.holidayName" class="form-control" :placeholder="$t('label.nameHoliday')">
        <div class="error-message w-100 clear text-left">
          {{ msg['holidayName'] && msg['holidayName'][0] ? $t(msg['holidayName']) : ''  }}
        </div>
      </div>
      <div class="form-group">
        <div class="mb-1">{{$t('label.timeDay')}}</div>
        <flat-pickr
          v-model="editHolidayInfo.date"
          :config="configDateTimePicker"
          :placeholder='$t("label.chooseDate")'
          name="reportDate"
          class="form-control"></flat-pickr>
          <div class="error-message w-100 clear text-left">
            {{ msg['fromDate'] && msg['fromDate'][0] ? $t(msg['fromDate']) : ''  }}
          </div>
      </div>
      <div>
        <b-form-checkbox
        v-model="editHolidayInfo.applyWorkDay"
        value="1"
        unchecked-value="0"
      >
        {{$t('label.applyPublicity')}}
      </b-form-checkbox>
      </div>
      <div class="d-block text-center">
        <div class="text-center">
          <b-button class="btn btn-danger" @click="editHoliday">{{
            $t('label.save')
            }}
          </b-button>
          <b-button class="btn btn-default" @click="hideModalEditHoliday">{{
            $t('label.cancel')
            }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- modal edit  -->

    <b-modal
      v-model="modalDelete"
      ref="modal-delete"
      hide-header
      hide-footer
      header-bg-variant="secondary"
      modal-class="modal-delete text-center"
      centered
      size="md"
    >
      <div >
        <div class="modal-delete--warning">
          <div class="modal-delete--warning__content">!</div>
        </div>
        <div>
          <h2 class="text-warning">{{ $t("label.areYouSure") }}?</h2>
        </div>
        <div>{{ $t("label.sureLeave") }}</div>
        <div class="mt-3">
          <b-button variant="primary" @click="deleteDayOff"
            >{{ $t("label.iAgree") }}
          </b-button>
          <b-button variant="secondary" @click="hideModalDelete"
            >{{ $t("label.cancel") }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- modal delete  -->

  </div>
</template>
<script>
import apiClient from "@/axios";
import apiServer from "@/axiosServer";
import flatPickr from 'vue-flatpickr-component'
import FlatpickrLanguages from "flatpickr/dist/l10n"
import moment from 'moment'
export default {
    components: {
    flatPickr
  },
  data() {
    return {
      isSupperAdmin: false,
      isComAdmin: false,
      dataTable: [],
      fields: [
        {
          label: this.$t("label.nameHoliday"),
          key: "holiday_name",
          class: "align-middle",
          sortable: false,
        },
        {
          label: this.$t("menu.dayOff"),
          key: "date",
          class: "align-middle",
          sortable: false,
        },
        {
          label: "",
          key: "function",
          class: "align-middle text-center",
          sortable: true,
        },
      ],
      holidayLists: [],
      modalHoliday: false,
      modalEditHoliday: false,
      idEdit: null,
      nameHoliday:'',
      dateSelect:'',
      configDateTimePicker: {
        dateFormat: 'd/m/Y',
        mode: "range",
        minDate: "today",
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true
      },
      modalDelete: false,
      currentPage: 1,
      perPage: 20,
      numbersPlusYears: 4,
      yearChoose:null,
      publicity:0,
      editHolidayInfo:[],
      idDelete:'',
      msg: [],
      companies: [],
      company: null,
      branchs: [],
      placeId: null
    };
  },
  mounted() {
    if (this.$store.getters.superAdmin == true) {
      this.isSupperAdmin = true;
      this.getCompany();
    }
    if (this.$store.getters.comAdmin == true) {
      this.isComAdmin = true;
    }
    if (this.isSupperAdmin || this.isComAdmin){
      this.getListBranch();
    }
    this.getListDayOff();
    this.getListYear();
    if(this.$store.getters.locale == 'vi'){
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"]
    };
  },
  filters: {
    truncate: function (text, length, suffix) {
        if (text.length > length) {
            text = text.substring(0, length);
            text = text.substring(0, text.lastIndexOf(' '));
            return text + suffix;
        } else {
            return text;
        }
    },
  },

  methods: {

    getListYear(){
      let nowYear = new Date().getFullYear();
      this.holidayLists.push({value:null,text:this.$t('label.all')});
      this.holidayLists.push({value:nowYear,text:nowYear});
      for (let i = 1; i <= this.numbersPlusYears; i++) {
        this.holidayLists.push(
          {value:nowYear + i,text:nowYear + i}
        )
      }

    },

    async showAddHoliday () {
      this.modalHoliday = true;
      this.resetForm();
    },

    async showEditHoliday (id) {
      this.resetForm();
      let objEdit = this.dataTable.find((x) => x.id == id);
      let fd = moment(objEdit.from_date).format('yyyy-MM-DD');
      let td = moment(objEdit.to_date).format('yyyy-MM-DD');
      let newDate = '';
      if(fd == td){
        newDate = fd
      }else{
        newDate = fd + ' ' + this.$t('label.to')+ ' ' +  td
      }

      this.editHolidayInfo = {
          id: id,
          holidayName: objEdit.holiday_name,
          date:newDate,
          applyWorkDay: objEdit.apply_working_day,
        };
      this.company = objEdit.companyId;
      this.modalEditHoliday = true;
    },


    async hideModalEditHoliday(){
      this.$refs['modal-edit-holiday'].hide();
      this.resetForm();
    },

    async addHoliday(){
      let res;
      let df = null, dt = null;
      if (this.dateSelect !== '') {
        let dateArr = this.dateSelect.split(' ');
        df = this.convertTimestampSendDate(dateArr[0]);
        if(dateArr.length == 1){
          dt = df;
        }
        else {
          dt =  this.convertTimestampSendDate(dateArr[2]);
        }
      }

      try {
        res = await apiServer.post('/api/v1/holiday/save', {
          holidayName: this.nameHoliday,
          fromDate:df,
          toDate: dt,
          applyWorkDay: this.publicity,
          company: this.company,
          placeId: this.placeId
        })
      } catch (error) {
        if (error.response.status === 400) {
          this.msg = (error.response.data)
        } else if (error.response.status === 401) {
          alert(this.$t(error.response.data))
        }
      }
      if (res && res.data) {
        if(res.status === 200) {
          this.modalHoliday = false;
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notification'),
            text: this.$t(res.data.message),
            position:'bottom-center'
          });
          this.getListDayOff();
          this.resetForm();
        }
      }

    },
    async getCompany(){
      let res
      try {
        res = await apiServer.post('/api/v1/company/get-list', {})
      } catch (error) {}
      if (res && res.data) {
        this.companies = [];
        if (res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            this.companies.push({
              text: res.data[i].name,
              value: res.data[i].id
            })
          }
        }
      }
    },

    async getListBranch(){
      let res;
      this.branchs = [];
      try{
        res = await apiServer.post('/api/v1/company/get-all-branch',{
          company: this.company
        });
        if (res && res.data) {
          for (let br of res.data) {
            this.branchs.push({value: br.placeID, text:br.branch_name});
          }
          this.date = this.dateRange
        }
      }
      catch (e) {
        console.log(e);
      }
    },

    changeCompany() {
      this.placeId = null;
      this.getListBranch();
    },

    async editHoliday(){
      let res;
      let df = null, dt = null;
      if (this.editHolidayInfo.date !== '') {
        let dateArr = this.editHolidayInfo.date.split(' ');
        df = this.convertTimestampSendDate(dateArr[0]);
        if(dateArr.length == 1){
          dt = df;
        }
        else {
          dt = this.convertTimestampSendDate(dateArr[2]);
        }
      }

      try {
        res = await apiServer.post('/api/v1/holiday/save', {
          id: this.editHolidayInfo.id,
          holidayName: this.editHolidayInfo.holidayName,
          fromDate:df,
          toDate: dt,
          applyWorkDay: this.editHolidayInfo.applyWorkDay,
          company: this.company
        })
      } catch (error) {
        if (error.response.status === 400) {
          this.msg = (error.response.data)
        } else if (error.response.status === 401) {
           alert(this.$t(error.response.data))
        }
      }

      if (res && res.data) {
        if(res.status === 200) {
          this.modalEditHoliday = false;
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notification'),
            text: this.$t(res.data.message),
            position:'bottom-center'
          });
          this.getListDayOff();
          this.resetForm();
        }
      }
    },


    async getListDayOff(){
      let res;
      this.optionsIdPerson = [];
      try {
        res = await apiServer.post('/api/v1/holiday/get-list', {
          placeID: this.$store.getters.placeID,
          year: this.yearChoose
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.dataTable = res.data
      }
    },

    changeYear(){
      this.getListDayOff();
    },

    async deleteDayOff(){
      let res;
      try {
      res = await apiServer.post('/api/v1/holiday/delete', {
          id: this.idDelete,
        })
      } catch (error) {
      }

      if (res && res.data) {
        if(res.status === 200) {
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notification'),
            text: this.$t(res.data.message),
            position:'bottom-center'
          });
          this.getListDayOff();
          this.modalDelete = false;
        }
      }

    },

    hideModalAddHoliday () {
      this.resetForm();
      this.$refs['modal-add-holiday'].hide();
    },

    showDelete (id){
      this.idDelete = id,
      this.modalDelete = true,
      this.showDeleteSuccess = false;

      console.debug(this.idDelete);
    },

    hideModalDelete () {
      this.resetForm();
      this.$refs['modal-delete'].hide();
    },

    resetForm(){
      this.idEdit= null;
      this.publicity=0;
      this.nameHoliday='';
      this.dateSelect='';
      this.editHolidayInfo=[];
      this.idDelete='';
      this.company = null;
      this.placeId = null;
      this.msg = [];
    },

    convertDate(inputDate) {
      if (inputDate == undefined || inputDate == null) return '';
      return  moment(inputDate).format("DD/MM/yyyy");
    },
    convertTimestampSendDate (date) {
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, '$3-$2-$1');
      var date = new Date(changedDate);
      return moment(date).format('yyyy-MM-DD');
    },

  },
};
</script>

<style  lang="scss">
</style>
