<template>
  <div id="main-default" class="container-fluid ">
    <div v-show="loadSubmit" class="load-submit"><font-awesome-icon icon="spinner" /></div>
    <div class="card col-12">
      <h3 class="mt-2">{{$t('label.staffInformation')}}</h3>
      <div>
          <div class="information-user-collapse">
            <div v-b-toggle:my-collapse class="information-user-collapse__title">
                <span class="when-open"><font-awesome-icon icon="caret-down" /></span><span class="when-closed"><font-awesome-icon icon="caret-right" /></span> {{$t('label.mainInformation')}}
            </div>
            <b-collapse id="my-collapse" visible  class="information-user-collapse__body">
                <div class="row">
                  <div class="col-6 col-md-3">
                    <b-form-group
                      class="required-mess"
                      :label="$t('label.employeeCode')"
                    >
                      <b-form-input
                        v-model="aliasID"
                        required
                        @keydown="nameKeydown($event)"
                      ></b-form-input>
                      <div class="error-message w-100 clear text-left">
                        {{ msg['aliasID'] ? $t(msg['aliasID']) : ''  }}
                      </div>
                    </b-form-group>
                  </div>
                    <div class="col-6 col-md-3">
                        <b-form-group
                        class="required-mess"
                        :label="$t('label.fullName')"
                        >
                          <b-form-input
                              v-model="nameUser"
                              required
                          ></b-form-input>
                          <div class="error-message w-100 clear text-left">
                            {{ msg['fullName'] ? $t(msg['fullName']) : ''  }}
                          </div>
                        </b-form-group>
                    </div>
                    <div class="col-6 col-md-3">
                        <b-form-group
                        class="required-mess"
                        :label="$t('label.userName')"
                        >
                          <b-form-input
                              v-model="userName"
                              required
                              @keydown="nameKeydown($event)"
                          ></b-form-input>
                          <div class="error-message w-100 clear text-left">
                            {{ msg['userName'] ? $t(msg['userName']) : ''  }}
                          </div>
                        </b-form-group>
                    </div>
                    <div class="col-6 col-md-3">
                        <b-form-group
                        class="required-mess"
                        label="Email"
                        >
                          <b-form-input
                            type="email"
                              v-model="emailUser"
                          ></b-form-input>
                            <div class="error-message w-100 clear text-left">
                              {{ msg['email'] ? $t(msg['email']) : ''  }}
                            </div>
                        </b-form-group>
                    </div>
                    <div class="col-6 col-md-3">
                        <b-form-group
                          class="required-mess"
                          :label="$t('label.phoneNumber')"
                        >
                            <b-form-input
                                type="number"
                                v-model="phoneNumberUser"
                                class="required-mess"
                                required
                            ></b-form-input>
                            <div class="error-message w-100 clear text-left">
                              {{ msg['phone'] ? $t(msg['phone']) : ''  }}
                            </div>
                        </b-form-group>
                    </div>
                    <div class="col-md-3 col-12">
                        <b-form-group
                        :label="$t('label.address')"
                        >
                            <b-form-input
                                v-model="addressUser"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-6 col-md-3">
                        <b-form-group
                        :label="$t('label.dateOfBirth')"
                        >
                            <flat-pickr v-model="dateOfBirthUser" :config="configDateTimePicker" :placeholder="$t('label.chooseDate')" name="birthDate" class="form-control"></flat-pickr>
                        </b-form-group>
                    </div>
                    <div class="col-6 col-md-3">
                        <b-form-group
                        :label="$t('label.idCard')"
                        >
                            <b-form-input
                                type="number"
                                v-model="idCardUser"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-6 col-md-3">
                        <b-form-group
                        :label="$t('label.gender')"
                        >
                        <select v-model="genderUser" class="form-control">
                            <option v-for="gender in genders" v-bind:value="gender.value">
                                {{ gender.text }}
                            </option>
                            </select>
                        </b-form-group>
                    </div>

                    <div class="col-6 col-md-3" v-if="isSupperAdmin">
                        <b-form-group
                        class="required-mess"
                        :label="$t('label.company')"
                        >
                            <select v-model="companyUser" class="form-control" @change="changeCompany" required>
                                <option v-for="cp in companies" v-bind:value="cp.value">
                                    {{ cp.text }}
                                </option>
                            </select>
                            <div class="error-message w-100 clear text-left">
                              {{ msg['company'] ? $t(msg['company']) : ''  }}
                            </div>
                        </b-form-group>
                    </div>
                    <div class="col-6 col-md-3" v-if="isSupperAdmin || isComAdmin">
                        <b-form-group
                        class="required-mess"
                        :label="$t('label.branch')"
                        >
                        <select v-model="branchUser" class="form-control"  @change="changeBranch" required>
                            <option v-for="branchByUser in branchByUsers" v-bind:value="branchByUser.value">
                                {{ branchByUser.text }}
                            </option>
                        </select>
                        <div class="error-message w-100 clear text-left">
                          {{ msg['branch'] ? $t(msg['branch']) : ''  }}
                        </div>
                        </b-form-group>
                    </div>
                  <div class="col-6 col-md-3">
                    <b-form-group
                      class="required-mess"
                      :label="$t('label.department')"
                    >
                      <treeselect v-model="department" :multiple="false" :options="departmentOption" :default-expand-level="Infinity"
                                  :noChildrenText="$t('label.noNodeChildren')" :placeholder="$t('label.chooseDepartment')" />
                      <div class="error-message w-100 clear text-left">
                        {{ msg['department'] ? $t(msg['department']) : ''  }}
                      </div>
                    </b-form-group>
                  </div>
                  <div class="col-6 col-md-3" v-if="isSupperAdmin || isComAdmin">
                        <b-form-group
                        :label="$t('label.groupUser')"
                        >
                        <select v-model="groupId" class="form-control">
                            <option v-for="gu in groupUsers" v-bind:value="gu.id">
                                {{ gu.group_name }}
                            </option>
                        </select>
                        </b-form-group>
                    </div>
                  <div class="col-6 col-md-3" v-if="isSupperAdmin || isComAdmin">
                    <b-form-group
                      :label="$t('label.role')"
                      class="required-mess"
                    >
                      <select v-model="userRole" class="form-control" required>
                        <option v-for="r in roles" v-bind:value="r.value">
                          {{ r.text }}
                        </option>
                      </select>
                      <div class="error-message w-100 clear text-left">
                        {{ msg['role'] ? $t(msg['role']) : ''  }}
                      </div>
                    </b-form-group>
                  </div>
                    <div class="col-6 col-md-3">
                        <b-form-group
                        :label="$t('label.jobPosition')"
                        >
                          <treeselect v-model="jobPositionUser" :multiple="false" :options="positionOption" :default-expand-level="Infinity"
                                      :noChildrenText="$t('label.noNodeChildren')" :placeholder="$t('label.choosePosition')" />
                          <div class="error-message w-100 clear text-left">
                            {{ msg['position'] ? $t(msg['position']) : ''  }}
                          </div>
                        </b-form-group>
                    </div>
                    <div class="col-6 col-md-3">
                        <b-form-group
                        :label="$t('label.dateStartWork')"
                        class="required-mess"
                        >
                        <flat-pickr required v-model="dateStartWorkUser" :config="configDateTimePicker" :placeholder="$t('label.chooseDate')" name="dateStartWorkUser" class="form-control"></flat-pickr>
                        <div class="error-message w-100 clear text-left">
                          {{ msg['dateStartWork'] ? $t(msg['dateStartWork']) : ''  }}
                        </div>
                        </b-form-group>
                    </div>
                    <div class="col-6 col-md-3">
                        <b-form-group
                        :label="$t('label.annualLeave')"
                        >
                            <b-form-input
                                v-model="annualLeave"
                                type="number"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-6 col-md-3">
                        <b-form-group
                        :label="$t('label.bankAccountNumber')"
                        >
                            <b-form-input
                                v-model="bankAccountNumberUser"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-6 col-md-3">
                        <b-form-group
                        :label="$t('label.asACollaborator')"
                        >
                        <select v-model="asACollaboratorUser" class="form-control">
                            <option v-for="asACollaborator in asACollaborators" v-bind:value="asACollaborator.value">
                                {{ asACollaborator.text }}
                            </option>
                        </select>
                        </b-form-group>
                    </div>
                </div>
            </b-collapse>
          </div>
          <div class="information-user-collapse">
            <div v-b-toggle:my-collapse2 class="information-user-collapse__title">
                <span class="when-open"><font-awesome-icon icon="caret-down" /></span><span class="when-closed"><font-awesome-icon icon="caret-right" /></span> {{$t('label.personalImage')}}
            </div>
            <b-collapse id="my-collapse2" visible class="information-user-collapse__body">
                <div class="row">
                  <div id="preview">
                    <img style="height: 100px;width: 100px;margin: 15px;border-radius: 5px;" v-if="url" :src="url" />
                  </div>
                  <div class="upload-images">
                    <label for="insurance_policy"  class="upload-images__hidden">
                      <input type="file" style="display: block;" name="insurance_policy" multiple="multiple" required
                            class="upload-file" id="insurance_policy"
                            accept=".png, .jpg, .jpeg, .pdf"
                            v-on:change="addAvatarUser($event.target.files)"
                      />
                    </label>

                    <div class="upload-images--show">
                      <div class="text-center">
                        <font-awesome-icon icon="plus" />
                        <p>{{$t('label.upload')}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="error-message w-100 clear text-left">
                    {{ msg['avatar'] ? $t(msg['avatar']) : ''  }}
                  </div>
                </div>
            </b-collapse>
          </div>
          <div class="submit-form m-4 text-center">
            <button type="submit" style="background: #FB6340;color: #fff;" @click="addNewUser()" class="btn">{{$t('label.addStaff')}}</button>
            <button type="button" class="btn btn-default" @click="$router.push('/admin/employee-manager')">{{$t("label.cancel")}}</button>
          </div>
          <div class="error-message w-100 clear text-left">
              {{notiSuccess}}
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiServer from '@/axiosServer'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import FlatpickrLanguages from "flatpickr/dist/l10n"
import Treeselect from '@riophae/vue-treeselect'
import moment from 'moment'
export default {
  components: {
    flatPickr,
    Treeselect
  },
  data () {
    return {
      isSupperAdmin: false,
      isComAdmin: false,
      idUser: this.$route.params.userId,
      aliasID: null,
      nameUser: "",
      userName:"",
      avatarUser: "",
      loadSubmit:false,
      emailUser: "",
      phoneNumberUser: "",
      companies: [],
      companyUser: null,
      userRole: 2,
      branchByUsers: [],
      branchUser: null,
      groupUsers: [],
      groupId: null,
      genderUser: '0',
      genders: [
        { text: this.$t('label.male'), value: '1' },
        { text: this.$t('label.female'), value: '0' }
      ],
      asACollaboratorUser: '0',
      asACollaborators: [
        { text: this.$t('label.yes'), value: '1' },
        { text: this.$t('label.no'), value: '0' }
      ],
      addressUser: '',
      dateOfBirthUser: '',
      configDateTimePicker: {
        dateFormat: 'd/m/Y',
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true
      },
      idCardUser: null,
      roles: [],
      jobPositionUser: null,
      dateStartWorkUser: '',
      bankAccountNumberUser: '',
      urlImage: null,
      url:null,
      msg: [],
      notiSuccess:'',
      department: null,
      departmentOption: null,
      positionOption: null,
      annualLeave: null
    };
  },
  mounted () {
    if (this.$store.getters.superAdmin == true) {
      this.isSupperAdmin = true;
      this.getCompany();
      this.roles.push({ text: this.$t('label.adminCompany'), value: 9 });
      this.roles.push({ text: this.$t('label.adminBranch'), value: 1 });
    }
    if (this.$store.getters.comAdmin == true) {
      this.isComAdmin = true;
      this.roles.push({ text: this.$t('label.adminBranch'), value: 1 });
    }
    this.roles.push({ text: this.$t('label.employee'), value: 2 });
    if (this.isComAdmin){
      this.getBranch();
    }
    else if (!this.isSupperAdmin) {
      this.getDepartment();
      this.getPosition();
    }
    if(this.$store.getters.locale == 'vi'){
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"]
    };
  },
   watch: {
    userName(val) {
      this.userName = val.replace(/\W/g, "");
    },
  },
  methods: {
    async getDepartment() {
      let res
      try {
        res = await apiServer.post('api/v1/department/list', {
          companyId: this.companyUser,
          placeId: this.branchUser
        })
      } catch (error) {}
      if (res && res.data) {
        this.departmentOption = res.data;
      }
    },
    async getBranch () {
      let res
      try {
        res = await apiServer.post('api/v1/company/get-all-branch', {
          company: this.companyUser
        })
      } catch (error) {}
      if (res && res.data) {
        this.branchByUsers = [];
        if (res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            this.branchByUsers.push({
              text: res.data[i].branch_name,
              value: res.data[i].placeID
            })
          }
        }
      }
    },
    async getPosition () {
      let res
      try {
        res = await apiServer.post('api/v1/position/list', {
          companyId: this.companyUser,
          placeId: this.branchUser
        })
      } catch (error) {}
      if (res && res.data) {
        this.positionOption = [];
        if (res.data.length > 0) {
          this.positionOption = res.data;
        }
      }
    },
    async getGroupUser () {
      let res
      try {
        res = await apiServer.post('api/v1/group-user/list', {
          placeId: this.branchUser
        })
      } catch (error) {}
      if (res && res.data) {
        this.groupUsers = [];
        if (res.data.length > 0) {
          this.groupUsers = res.data;
        }
      }
    },
    async getCompany(){
      let res
      try {
        res = await apiServer.post('/api/v1/company/get-list', {})
      } catch (error) {}
      if (res && res.data) {
        if (res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            this.companies.push({
              text: res.data[i].name,
              value: res.data[i].id
            })
          }
        }
      }
    },
    changeCompany() {
      if (this.companyUser != null){
        this.getBranch();
        this.branchByUsers = null;
        this.department = null;
      }
    },
    changeBranch() {
      if (this.branchUser != null){
        this.getDepartment();
        this.department = null;
        this.getPosition();
        this.jobPositionUser = null;
        this.getGroupUser();
        this.groupId = null;
      }
    },
    addAvatarUser(file) {
      this.avatarUser = file[0];
      this.url = URL.createObjectURL(file[0]);
    },
    async addNewUser(file) {
      let res;
      let dateOfBirthUser = null;
      if(this.dateOfBirthUser != undefined && this.dateOfBirthUser != ''){
        dateOfBirthUser = this.convertTimestampSendDate(this.dateOfBirthUser);
      }
      let dateStartWork = (this.dateStartWorkUser != undefined && this.dateStartWorkUser != '') ?
        this.convertTimestampSendDate(this.dateStartWorkUser) : null;
      this.loadSubmit=true,
      this.msg = []
      const config = {
        header: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: 0,
      };
      const formData = new FormData();
      formData.append("file", this.avatarUser);
      formData.append("fullName", this.nameUser);
      formData.append("aliasID", this.aliasID);
      formData.append("userName", this.userName);
      formData.append("password", "");
      formData.append("branchID", this.branchUser);
      formData.append("dateBirthDay", dateOfBirthUser);
      formData.append("gender", this.genderUser);
      formData.append("cardID", this.idCardUser);
      formData.append("address", this.addressUser);
      formData.append("phone", this.phoneNumberUser);
      formData.append("email", this.emailUser);
      formData.append("company", this.companyUser);
      formData.append("role", this.userRole);
      formData.append("department", this.department);
      formData.append("office", this.jobPositionUser);
      formData.append("isCollaborator", this.asACollaboratorUser);
      formData.append("dateStartWork", dateStartWork);
      formData.append("bankNumber", this.bankAccountNumberUser);
      formData.append("groupId", this.groupId);
      formData.append("annualLeave", this.annualLeave);
      try {
        res = await apiServer.post(
          'api/v1/user/insert-user',
          formData,
          config
        )
      } catch (error) {
        this.loadSubmit=false
        if (error.response.status === 400) {
          this.msg = (error.response.data)
          if (this.msg['status'] != undefined) {
            this.$notify({
              group: 'bottomnotification',
              title: this.$t('label.notificationError'),
              text: this.$t(this.msg['status']),
              type: 'error'
            });
          }
        } else if (error.response.status === 401) {
           alert(this.$t(error.response.data))
        }
      }
      if (res && res.data) {
        this.loadSubmit=false,
        this.notiSuccess = (res.data.status),
        this.$notify({
          group: 'bottomnotification',
          title: this.$t('label.notification'),
          text: this.$t(res.data.message),
          position:'bottom-center'
        });
        this.redirectSuccess()
      }
    },
    redirectSuccess(){
        this.$router.replace("/admin/employee-manager")
    },
    convertTimestampSendDate (date) {
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, '$3-$2-$1');
      var date = new Date(changedDate);
      return moment(date).format('DD-MM-yyyy')
    },
    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },

  }
}
</script>
<style scoped lang="scss">
.info-user {
  .info-user__image {
    span {
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
      padding: 10px;
      display: inline-block;
      margin: 20px 0;
      border-radius: 5px;
    }
  }
  .info-user-desc {
    display: flex;
    align-items: center;
    .info-user-desc--more-info {
      ul {
        padding: 0;
        margin: 0;
        width: 100%;
      }
    }
  }
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.information-user-collapse {
  border-bottom: 1px solid #ddd;
  .information-user-collapse__title {
    outline: none;
    cursor: pointer;
    padding: 10px;
    svg {
      margin-right: 10px;
    }
  }
  .information-user-collapse__body {
    padding: 10px;
    .col-form-label {
      font-weight: bold;
    }
    .image-avatar-user {
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
      padding: 10px;
      display: inline-block;
      margin: 20px 0;
      border-radius: 5px;
    }
  }
}
.upload-images {
  position: relative;
  display: inline-block;
  margin: 15px 0 10px;
  cursor: pointer;
  .upload-images__hidden {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    .custom-file {
      height: 100%;
      .custom-file-label {
        height: 100%;
      }
    }
  }
  .upload-images--show {
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    background: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    p {
      margin: 0;
    }
  }
  &:hover {
    .upload-images--show {
      border: 1px dashed #fb6340;
    }
  }
}
</style>
<style lang="scss">
  .required-mess{
    legend{
      &::after{
        content: '*';
        color: red;
      }
    }
  }
  .load-submit{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #ccccccbf;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: #FB6340;
    svg{
      animation: spin-around 1s linear 0s infinite;
      -webkit-animation: spin-around 1s linear 0s infinite;
      -moz-animation: spin-around 1s linear 0s infinite;
      -o-animation: spin-around 1s linear 0s infinite;
    }
  }
  @-webkit-keyframes spin-around{
    from{
        -webkit-transform:rotate(0deg);
        -moz-transform:rotate(0deg);
        -o-transform:rotate(0deg);
    }
    to{
        -webkit-transform:rotate(360deg);
        -moz-transform:rotate(360deg);
        -o-transform:rotate(360deg);
    }
}
/* Standard syntax */
@keyframes spin-around {
    from{
        -webkit-transform:rotate(0deg);
        -moz-transform:rotate(0deg);
        -o-transform:rotate(0deg);
    }
    to{
        -webkit-transform:rotate(360deg);
        -moz-transform:rotate(360deg);
        -o-transform:rotate(360deg);
    }
}
</style>
