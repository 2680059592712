<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid">
    <div class="card col-xl-12 ">
      <div class="row">
        <div class="col-xl-12 mt-2">
          <h1 class="page-title">
            {{ $t('menu.branchManagement') }}
          </h1>
          <p>{{ $t('label.total') }} {{ $t('label.branch') }}: {{dataTable.length}}</p>
        </div>
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xs-12 col-md-3">
              <input
                type="text"
                v-model="branchName"
                :placeholder="$t('label.search')"
                class="form-control"
              />
            </div>
            <div class="col-md-2 col-xs-12">
              <b-button
                variant="btn btn-info btn-outline-primary w-100"
                @click="getBranch()"
                >{{$t('label.search')}}
              </b-button>
            </div>
            <div class="col-xs-12 col-md-7 text-right">
              <a href="javascript:void(0)" class="btn btn-danger bg-gradient-danger" @click="showAddBranch()"
              >
                <font-awesome-icon icon="plus"/>
                {{ $t('label.addNew') }}</a
              >
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row mt-3">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body m-0 p-0 table-responsive">
                  <b-table
                    striped
                    bordered
                    class="default-table"
                    hover
                    :items="dataTable"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    responsive
                    id="table-inouthistory"
                  >
                    <template #cell(id)="data">
                      <button
                        class="btn btn-warning btn-sm"
                        @click="showEdit(data.value)"
                        v-b-tooltip.hover
                        :title="$t('label.edit')"
                      >
                        <font-awesome-icon icon="edit"/>
                      </button>
                      <button
                        class="btn btn-danger btn-sm"
                        @click="showDelete(data.value)"
                        v-b-tooltip.hover
                        :title="$t('label.delete')"
                      >
                        <font-awesome-icon icon="trash"/>
                      </button>
                    </template>
                  </b-table>
                  <!-- table  -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="dataTable.length"
                    :per-page="perPage"
                    align="right"
                    size="sm"
                    class="m-4"
                    aria-controls="table-inouthistory"
                  ></b-pagination>
                  <b-modal
                    v-model="modalAddBranch"
                    ref="modal-edit"
                    :title="$t('label.addEdit') + ' ' + $t('label.branch')"
                    hide-footer
                    header-bg-variant="secondary"
                    centered
                    size="md"
                  >
                    <b-form ref="form">
                      <b-form-group
                        id="input-group-1"
                        :label="$t('label.name')"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          :placeholder="$t('label.name')"
                          required
                          v-model="nameBranch"
                        ></b-form-input>
                        <div class="error-message w-100 clear text-left">
                          {{ msg['branchName'] && msg['branchName'][0] ? $t(msg['branchName'][0]) : ''  }}
                        </div>
                      </b-form-group>
                      <b-form-group
                        class="required-mess"
                        :label="$t('label.company')"
                        v-if="isSupperAdmin"
                      >
                        <select v-model="companyId" class="form-control" @change="changeCompany" required>
                          <option v-for="cp in companies" v-bind:value="cp.value">
                            {{ cp.text }}
                          </option>
                        </select>
                        <div class="error-message w-100 clear text-left">
                          {{ msg['company'] ? msg['company'][0] : ''  }}
                        </div>
                      </b-form-group>
                      <b-form-group
                        id="input-group-2"
                        :label="$t('label.address')"
                        label-for="input-2"
                      >
                        <b-form-input
                          id="input-2"
                          :placeholder="$t('label.address')"
                          required
                          v-model="addressBranch"
                        ></b-form-input>
                        <div class="error-message w-100 clear text-left">
                           {{ msg['branchAddress'] && msg['branchAddress'][0] ? $t(msg['branchAddress'][0]) : ''  }}
                        </div>
                      </b-form-group>
                      <b-form-group
                        id="input-group-2"
                        label="Place ID"
                        label-for="input-2"
                      >
                        <select v-model="placeIdBranch" class="form-control" required>
                          <option v-for="p in places" v-bind:value="p.value">
                            {{ p.text }}
                          </option>
                        </select>
                        <div class="error-message w-100 clear text-left">
                          {{ msg['placeId'] && msg['placeId'][0] ? $t(msg['placeId'][0]) : ''  }}
                        </div>
                      </b-form-group>

                    </b-form>
                    <div class="d-block text-center">
                      <div class="text-center">
                        <b-button class="btn btn-danger"  @click="addNewBranch">{{
                          $t('label.save')
                          }}
                        </b-button>
                        <b-button class="btn btn-default" @click="hideModalEdit">{{
                          $t('label.cancel')
                          }}
                        </b-button>
                      </div>
                    </div>
                  </b-modal>
                  <!-- modal addBranch  -->
                  <b-modal
                    v-model="modalEdit"
                    ref="modal-edit"
                    :title="$t('label.addEdit') + ' ' + $t('label.branch')"
                    hide-footer
                    header-bg-variant="secondary"
                    centered
                    size="md"
                  >
                    <b-form ref="form">
                      <b-form-group
                        class="required-mess"
                        :label="$t('label.company')"
                        v-if="isSupperAdmin"
                      >
                        <select v-model="companyId" class="form-control" @change="changeCompany" required>
                          <option v-for="cp in companies" v-bind:value="cp.value">
                            {{ cp.text }}
                          </option>
                        </select>
                        <div class="error-message w-100 clear text-left">
                          {{ msg['company'] ? msg['company'][0] : ''  }}
                        </div>
                      </b-form-group>
                      <b-form-group
                        id="input-group-1"
                        :label="$t('label.name')"
                        label-for="input-1"
                      >
                        <b-form-input
                          id="input-1"
                          :placeholder="$t('label.name')"
                          :value="editBranchName"
                          v-model="editBranchName"
                          required
                        ></b-form-input>
                        <div class="error-message w-100 clear text-left">
                          {{ msg['branchName'] && msg['branchName'][0] ? $t(msg['branchName'][0]) : ''  }}
                        </div>
                      </b-form-group>
                      <b-form-group
                        id="input-group-2"
                        :label="$t('label.address')"
                        label-for="input-2"
                      >
                        <b-form-input
                          id="input-2"
                          :placeholder="$t('label.address')"
                          :value="editBranchAddress"
                          v-model="editBranchAddress"
                          required
                        ></b-form-input>
                        <div class="error-message w-100 clear text-left">
                          {{ msg['branchAddress'] && msg['branchAddress'][0] ? $t(msg['branchAddress'][0]) : ''  }}
                        </div>
                      </b-form-group>
                      <b-form-group
                        id="input-group-2"
                        label="Place ID"
                        label-for="input-2"
                      >
                        <select v-model="editBranchPlaceID" class="form-control" required>
                          <option v-for="p in places" v-bind:value="p.value">
                            {{ p.text }}
                          </option>
                        </select>
                        <div class="error-message w-100 clear text-left">
                          {{ msg['placeId'] && msg['placeId'][0] ? $t(msg['placeId'][0]) : ''  }}
                        </div>
                      </b-form-group>
                    </b-form>
                    <div class="d-block text-center">
                      <div class="text-center">
                        <b-button class="btn btn-danger" @click="editBranchById(idEdit)">{{
                          $t('label.save')
                          }}
                        </b-button>
                        <b-button class="btn btn-default" @click="hideModalEdit">{{
                          $t('label.cancel')
                          }}
                        </b-button>
                      </div>
                    </div>
                  </b-modal>
                  <!-- modal edit  -->
                  <b-modal
                    v-model="modalDelete"
                    ref="modal-delete"
                    hide-header
                    hide-footer
                    header-bg-variant="secondary"
                    modal-class="modal-delete text-center"
                    centered
                    size="md"
                  >
                  <div >
                    <div class="modal-delete--warning">
                      <div class="modal-delete--warning__content">!</div>
                    </div>
                    <div>
                      <h2 class="text-warning">{{ $t('label.areYouSure') }}?</h2>
                    </div>
                    <div>{{ $t('label.sureDeleteTheBranch') }}</div>
                    <div class="mt-3">
                      <b-button variant="primary" @click="definitelyDelete(idDelete)">{{
                        $t('label.iAgree')
                        }}
                      </b-button>
                      <b-button variant="secondary" @click="hideModalDelete">{{
                        $t('label.cancel')
                        }}
                      </b-button>
                    </div>
                  </div>
                  </b-modal><!-- modal delete  -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiServer from '@/axiosServer'
export default {
  data () {
    return {
      isSupperAdmin: false,
      dataTable: [],
      modalEdit: false,
      modalDelete: false,
      modalAddBranch: false,
      companies: [],
      companyId: null,
      idEdit: '',
      idDelete: '',
      currentPage: 1,
      perPage: 20,
      showDeleteSuccess:false,
      nameBranch:'',
      addressBranch:'',
      placeIdBranch:'',
      editBranchAddress:'',
      editBranchName:'',
      editBranchPlaceID:'',
      places: [],
      fields: [
        {
          label: this.$t('label.name'),
          key: 'branch_name',
          sortable: false
        },
        {
          label: this.$t('label.address'),
          key: 'branch_address',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: false
        },
        {
          label: '',
          key: 'id',
          tdClass: 'text-center w-5',
          thClass: 'text-center',
          sortable: true
        }
      ],
      branchName:'',
      msg:'',
    }
  },
  mounted () {
    if (this.$store.getters.superAdmin == true) {
      this.isSupperAdmin = true;
      this.getCompany();
    }
    this.getPlace();
    this.getBranch();
  },
  methods: {
    showAddBranch () {
      this.modalAddBranch = true;
      this.resetForm();
    },
    showEdit (idEdit) {
      this.idEdit = idEdit;
      this.modalEdit = true;
      this.getBranchById(idEdit);
      this.resetForm();
    },
    showDelete (idDelete) {
      this.idDelete = idDelete
      this.showDeleteSuccess = false
      this.modalDelete = true
    },
    hideModalEdit () {
      this.$refs['modal-edit'].hide()
    },
    hideModalDelete () {
      this.$refs['modal-delete'].hide()
    },
    async definitelyDelete(id){
      let respone
      try {
        respone = await apiServer.post('api/v1/company/delete-branch', {
           id: id
        })
      } catch (error) {
      }
      if (respone && respone.status ) {
        if (respone.status === 200) {
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notification'),
            text: this.$t('notify.message.deleteBranchSuccess'),
            position:'bottom-center'
          });
          this.modalDelete  = false;
          this.getBranch ()
        }
      }
    },
    async getCompany(){
      let res
      try {
        res = await apiServer.post('/api/v1/company/get-list', {})
      } catch (error) {}
      if (res && res.data) {
        if (res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            this.companies.push({
              text: res.data[i].name,
              value: res.data[i].id
            })
          }
        }
      }
    },
    changeCompany() {
      this.placeIdBranch = null;
      this.editBranchPlaceID = null;
      this.getPlace();
    },
    async getBranch () {
      let res
      try {
        res = await apiServer.post('api/v1/company/get-all-branch', {
          branchName: this.branchName
        })
      } catch (error) {
      }
      if (res && res.data) {
        if (res.data.length > 0) {
          this.dataTable = res.data
        }
      }
    },
    async getPlace() {
      this.places = [];
      let res;
      try {
        res = await apiServer.post('api/v1/place/get-list', {
          company: this.companyId
        });
      } catch (error) {
        console.log(error);
      }
      if (res && res.data) {
        if (res.data.length > 0) {
          this.places = res.data;
        }
      }
    },
    async getBranchById (id) {
      let res
      try {
        res = await apiServer.post('api/v1/company/get-branch-by-id', {
          id: id
        })
      } catch (error) {
      }
      if (res && res.status) {
        if(res.status === 200) {
            this.editBranchName = res.data[0].branch_name;
            this.editBranchPlaceID = res.data[0].placeID;
            this.editBranchAddress = res.data[0].branch_address;
            this.companyId = res.data[0].companyId;
            this.getPlace();
        }
      }
    },
    async editBranchById (id) {
      let res
      try {
        res = await apiServer.post('api/v1/company/update-branch', {
          id: id,
          placeID: this.editBranchPlaceID,
          branch_name: this.editBranchName,
          branch_address: this.editBranchAddress,
          company: this.companyId
        })
      } catch (error) {
        if (error.response.status === 400) {
          this.msg = (error.response.data)
        } else if (error.response.status === 401) {
          alert(error.response.data)
        }
      }
      if (res && res.data) {
        if(res.data == "TRUE"){
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notification'),
            text: this.$t('notify.message.updateBranchSuccess'),
            position:'bottom-center'
          });
          this.getBranch ();
          this.modalEdit = false
        }
      }
    },
    async addNewBranch () {
      let res
      try {
        res = await apiServer.post('api/v1/company/add-branch', {
            "placeID": this.placeIdBranch,
            "branch_name":this.nameBranch,
            "branch_address": this.addressBranch,
            "company": this.companyId
        })
      } catch (error) {
        if (error.response.status === 400) {
          this.msg = (error.response.data)
        } else if (error.response.status === 401) {
           alert(this.$t(error.response.data))
        }
      }
      if (res && res.data) {
        if(res.data == "TRUE"){
          this.getBranch ();
          this.$notify({
            group: 'bottomnotification',
            title: this.$t('label.notification'),
            text: this.$t('label.addBranchSuccess'),
            position:'bottom-center'
          });
          this.modalAddBranch = false
          this.resetForm();
        }
      }
    },

    resetForm(){
      this.placeIdBranch = "";
      this.nameBranch = "";
      this.addressBranch = "";
      this.msg = [];
      ;
    }
  }
}
</script>
<style lang="scss">
  .modal-delete {
    .modal-delete--warning {
      position: relative;
      box-sizing: content-box;
      justify-content: center;
      width: 1.3em;
      height: 1.3em;
      margin: 0.25em auto 0.375em;
      border: 0.06em solid transparent;
      border-radius: 50%;
      font-family: inherit;
      line-height: 1.3em;
      cursor: default;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border-color: #28a745;
      color: #f8bb86;
      text-align: center;
      font-size: 3.6em;
    }
    .modal-delete--success {
      position: relative;
      box-sizing: content-box;
      justify-content: center;
      width: 1.3em;
      height: 1.3em;
      margin: 0.25em auto 0.375em;
      border: 0.06em solid transparent;
      border-radius: 50%;
      font-family: inherit;
      line-height: 1.3em;
      cursor: default;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border-color: #28a745;
      color: #28a745;
      text-align: center;
      font-size: 3.6em;
    }

  }
</style>
