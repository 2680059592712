<template>
  <div id="app-body">
      <div class="close-welcome" @click="redirectLink('/admin/dashboard')"><font-awesome-icon icon="power-off"/></div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-7 last-inout">
                    <h1 class="logo"><img src="../assets/img/welcome/logo.png" alt=""></h1>
                    <div class="video">
                        <video src="../assets/img/welcome/bg.mp4" loop muted autoplay></video>
                        <div class="overlay"></div>
                    </div>
                    <div class="last-inout--item">
                        <div  v-for="user in usersLast">
                            <div>
                                <div class="last-inout__image"><img :src="user.detected_image_url"></div>
                                <div v-show="user.personID" class="check-right"><img src="../assets/img/welcome/icon-tick.png"></div>
                                <div class="last-inout--content">
                                    <h4>{{user.personName}}</h4>
                                    <p>{{user.placeName}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="col-5">
                    <div class="date-time row">
                        <div class="col-6 date-time--time">{{currentTime}}</div>
                        <div class="col-6 date-time--date"> <p>{{currentDate}}</p><div>{{nameDate}}</div></div>
                    </div>
                    <div class="row list-noti">

                        <div class="col-6">
                            <div class="card border-0">
                                <div>
                                    <span class="h2 font-weight-bold mb-0">{{this.dataDashBoard.checkedin}}</span>
                                    <p class="card-title text-muted mb-0">
                                    {{ $t("label.checkedin") }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="card border-0">
                                <div>
                                    <span class="h2 font-weight-bold mb-0">{{this.dataDashBoard.lateNumber}}</span>
                                    <p class="card-title text-muted mb-0">
                                    {{ $t("label.late") }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="list-name mt-3 row">
                        <div class="list-name--item col-6" v-for="user in infoList">
                            <div>
                                <h4>{{user.personName}}</h4>
                                <p>{{updateTime(user.date)}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="list-checkout">
                <div>
                    <div class="list-checkout--item" v-for="user in users">
                        <p>{{user.placeName}}</p>
                        <div class="list-checkout--item__image">
                            <img :src="user.detected_image_url"/>
                        </div>
                        <h4>{{user.personName}}</h4>
                    </div>
                </div>
            </div>
            <div class="col-12" style="display:none">
                <div>
                    {{$t('label.turnAudioOn')}}
                    <toggle-button
                        :value=audioOn
                        color="#FB6340"
                        :labels=labelsAudio
                        @change="clickAudio"
                    />
                </div>
                <div>
                <video style="display:none" controls="" id="audio">
                    <source id ="audio-source" src="../assets/video/notification.mp3" type="audio/mpeg">
                </video>
                </div>
            </div>
        </div>
  </div>
</template>


<script>
import apiClient from '@/axios'
import apiServer from '@/axiosServer'
import moment from 'moment'

export default {
  data() {
    return {
      dataDashBoard: [],
      users: [],
      usersLast: [],
      popupTurnOn: false,
      audioOn: true,
      srcMp3: '',
      labelsAudio:{checked: this.$t('label.on'), unchecked: this.$t('label.off')},
      currentTime:'',
      currentDate:'',
      nameDate:'',
      infoList:[],
      fontSize:'',
    };
  },
  created() {
    this.audioOn = this.$store.getters.audio;
    setInterval(this.getNow, 1000);
  },
  mounted() {
      this.getSummary();
      document.getElementById('audio').click();
      this.checkWidth();
  },
  sockets: {
    connect: function () {
      console.debug('socket connected')
    },
    receiveMessage: function (user) {
    this.usersLast = [];
      for (let i = 0; i < this.$store.getters.placeID.length; i++) {
        if (parseInt(this.$store.getters.placeID[i]) === parseInt(user.placeID)) {
          if ((this.audioOn) && (user.audio === true)) {
            const audioSrc = 'data:audio/mp3;base64,' + user.linkaudio
            document.getElementById('audio-source').src = audioSrc
            document.getElementById('audio').load()
            document.getElementById('audio').play()
          }
          this.users.unshift(user)
          this.usersLast.unshift(user)
          if( this.infoList.length > 9){
              this.infoList.pop();
              this.infoList.unshift(user);
          }else{
              this.infoList.unshift(user)
          }
        }
      }
    }
  },
  methods:{
      async getSummary() {
      let res
      try {
        res = await apiServer.post('api/v1/dashboard/summary', {
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.dataDashBoard =
          {
              accountNumber: res.data.accountNumber,
              checkedin: res.data.checkedin,
              lateNumber: res.data.lateNumber,
              notCheckin: res.data.notCheckin,
          }
      }
    },
    clickAudio() {
      this.audioOn = !this.audioOn;
      this.$store.commit("updateAudio", this.audioOn);
    },

    redirectEmployeeManager(){
      this.$router.replace("/admin/employee-manager")
    },

    getNow() {
        const today = new Date();
        var dt = moment(today, "YYYY-MM-DD HH:mm:ss");
        const date = moment(today).format("DD/MM/yyyy");
        const time = moment(today).format("HH:mm");
        this.currentTime = time;
        this.currentDate = date;
        this.nameDate = dt.format('dddd');
    },

    updateTime(date){
        return moment(date).format("HH:mm");
    },
     checkWidth() {
        const htmlDom = document.getElementsByTagName('html')[0];
        let w = window.innerWidth;
        var font = w / 100;
        htmlDom.style.fontSize = font +'px';
    },
    redirectLink(link){
        this.$router.replace(link)
    },
  }
};
</script>
<style scoped lang="scss">
body,html {
    overflow-x: hidden;
}
#app-body{
    background: url(../assets/img/welcome/bg.jpg) center center no-repeat;
    background-size: cover;
    width: 100vw;
    min-height: 100vh;
    padding: 2.3rem;
}
.date-time{
    color: #fff;
}
.date-time--time{
    font-size: 6rem;
    line-height: .8;
}
.date-time--date{
    font-weight: bold;
    p{
        font-size: 2.2rem;
        line-height: .8;
        margin-bottom: .2rem;
    }
    div{
        font-size: 1.9rem;
    }
}
.list-name{
    color: #fff;
    .list-name--item{
        &>div{
            padding: .8rem;
            display: flex;
            justify-content: space-between;
            h4{
                color: #fff;
                width: 80%;
                margin-bottom: 0;
            }
            p{
                padding: 0 .8rem;
                width: 20%;
                margin-bottom: 0;
            }
        }
        &:nth-child(3)>div,&:nth-child(4)>div,&:nth-child(7)>div,&:nth-child(8)>div,&:nth-child(11)>div,&:nth-child(12)>div{
            background: #ffffff1a;
            border-radius:.2rem;
        }
    }
}
.last-inout{
    position: relative;
    .video{
        width: 100%;
        max-height: 26rem;
        overflow: hidden;
        position: relative;
        background: url(../assets/img/welcome/bg-video.png);
        video{
            width: 100%;
            height: 100%;
        }
        .overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            // background-image: linear-gradient(45deg, rgba(0,0,0,.3) 50%, rgba(0,0,0,.7) 50%);
            background-size: 3px 3px;
            z-index: 1;
        }
    }
    h1{
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%,-50%);
        z-index: 2;
        img{
            width: 11rem;
        }
    }
    
    &>div.last-inout--item{
        background-size: cover;
        height: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 2;
        &>div{
            display: flex;
            align-items: center;
            &>div{
                box-shadow: 0 0 1rem #011b40;
                border-radius: .8rem;
                padding: 0;
                .last-inout__image{
                    padding: 1.3rem;
                    background: #e71b30;
                    border-radius: .8rem .8rem 0 0;
                    text-align: center;
                    img{
                        width: 11rem;
                        height: 11rem;
                        background: url(../assets/img/welcome/bg-img.png) center center no-repeat;
                        background-size: contain;
                        border-radius: 50%;
                        padding: .8rem;
                    }
                }
                .last-inout--content{
                    background: #9d1129;
                    text-align: center;
                    color: #fff;
                    border-radius: 0 0 .8rem .8rem;
                    h4{
                        color: #fff;
                        padding: .8rem 1rem 0;
                        font-size: 1.2rem;
                    }
                    p{
                       padding-bottom: 1rem;
                       line-height: 1;
                       margin-bottom: 0;
                    }
                }
            }
        }
    }
}
.list-checkout{
    padding: 3rem 1rem 0;
    width: 100%;
    overflow-x:auto;
    overflow-y:hidden;
    &>div{
        width: auto;
        white-space:nowrap;
    }
}
.list-checkout--item{
    min-width: 10rem;
    text-align: center;
    margin-right: 1rem;
    display: inline-block;
    padding: 0 1rem;
    img{
        width: 9rem;
        height: 9rem;
        border-radius: 50%;
        box-shadow:0 0 .8rem #550110;
        margin: .5rem 0;
    }
    p{
        color: #fff;
        font-size: 1.1rem;
        line-height: 1.2;
        height: .8rem;
    }
    h4{
        color: #fff;
        font-size: 1.1rem;
        margin-top: .3rem;
    }
}
.list-noti{
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    .card{
        padding: .4rem .8rem;
        margin-top: 1.3rem;
        margin-bottom: 0;
        div{
            display: flex;
            justify-content: space-between;
        }
    }
}
.check-right{
    position: relative;
    color: #73CC43;
    background: #9d1129;
    background: linear-gradient(180deg, #e71b30 50%, #9d1129 0%);
    line-height: 0;
    text-align: center;
    img{
        width: 2.3rem;
        border-radius: 50%;
        box-shadow: 0 0 .5rem #2e251bad;
    }
}
.close-welcome{
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: .5rem .57rem;
    border: solid .1rem #fff;
    line-height: 0;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
}

/*ipad-pro*/
@media screen and (min-width: 968px) and (max-width: 1110px){

}
/* end-ipad-pro*/

/*ipad*/
@media screen and (min-width: 768px) and (max-width: 967px){


}
/*end-ipad*/
/*mobile*/
@media screen and (max-width: 767px){



}
@media screen and (min-width: 375px) and (max-width: 413px){

}
</style>
