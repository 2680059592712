<template>
  <!-- Sidenav -->
  <nav class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white" id="sidenav-main"
       @mouseover="mouseoverMenu()"
       @mouseleave="mouseleaveMenu()">
    <div class="scrollbar-inner">
      <!-- Brand -->
      <div class="sidenav-header d-flex align-items-center">
        <a class="navbar-brand" href="javascript:void(0)">
          <img src="../assets/img/brand/logo.png" class="navbar-brand-img" alt="...">
        </a>
        <div class=" ml-auto">
          <!-- Sidenav toggler -->
          <div class="sidenav-toggler d-none d-xl-block active" @click.stop=checkMenu()>
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="navbar-inner">
        <!-- Collapse -->
        <div class="collapse navbar-collapse" id="sidenav-collapse-main">
          <!-- Nav items -->
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/employee/dashboard" :class="includeRoute(['employee/dashboard' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                <font-awesome-icon icon="home" size="xs" />
                <span class="nav-link-text">{{ $t('menu.dashboard') }}</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/employee/personnel" :class="includeRoute(['employee/personnel' ], this.$route.path) ? 'nav-link active': 'nav-link'">
             <font-awesome-icon icon="user" size="xs" />
                <span class="nav-link-text">{{ $t('label.personalInformation') }}</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/employee/checkin" :class="includeRoute(['employee/checkin' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                <font-awesome-icon icon="calendar-alt" size="xs" />
                <span class="nav-link-text">{{ $t('menu.checkIn') }}</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/employee/in-out-history" :class="includeRoute(['employee/in-out-history' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                <i class="ni ni-planet text-orange"></i>
                <span class="nav-link-text">{{ $t('menu.inOutHistory') }}</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/employee/leave" :class="includeRoute(['employee/leave' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                <font-awesome-icon icon="calendar-day" size="xs" />
                <span class="nav-link-text">{{ $t('menu.managementLeave') }}</span>
              </router-link>
            </li>
            <li v-if="approval" class="nav-item">
              <router-link to="/employee/approve-leave" :class="includeRoute(['employee/approve-leave' ], this.$route.path) ? 'nav-link active': 'nav-link'">
                <font-awesome-icon icon="calendar-day" size="xs" />
                <span class="nav-link-text">{{ $t('menu.approveLeave') }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { CollapsePlugin } from 'bootstrap-vue'
export default {
  data () {
    return {
      isSuperAdmin: false,
      approval: false
    }
  },
  created () {
    if (this.$store.getters.superAdmin == true){
      this.isSuperAdmin = true
    }
    if (this.$store.getters.approval == true) {
      this.approval = true;
    }
  },
  name: 'MenuSideBar',
  methods: {
    checkMenu () {
      this.$emit('check-menu')
    },
    includeRoute (paths, route) {
      if (paths.includes(route)) {
        return true
      }
      for (const [index, path] of paths.entries()) {
        if (route.match(path)) {
          return true
        }
      }
      return false
    },
    mouseleaveMenu () {
      this.$emit('mouseleave-menu')
    },
    mouseoverMenu () {
      this.$emit('mouseover-menu')
    }
  }
}
</script>
<style scoped lang="scss">
.navbar-nav{
  li{
    a{
      cursor:pointer;
      color: #000 !important;
      font-size: 15px !important;
      svg{
        font-size: 15px;
        margin-right: 15px;
        color: #000;
      }
      i{
        color: #000 !important;
      }
      &.active{
        color: #FB6340 !important;
        background: #FFEFEC !important;
        margin: 0rem !important;
        border-radius: 0 !important;
        i{
          color: #FB6340 !important;
        }
        svg{
          color: #FB6340 !important;
        }
      }
      &:hover{
        color: #FB6340 !important;
        background: #FFEFEC !important;
        i{
          color: #FB6340 !important;
        }
        svg{
          color: #FB6340 !important;
        }
      }
    }
  }
}
.g-sidenav-hidden{
  a{
    &.router-link-active{
      display: none !important;
    }
  }
}
#sub-menu{
  li{
    position: relative;
    &::before{
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #FB6340;
      top: 50%;
      left: 35px;
      z-index: 99;
      transform: translateY(-50%);
    }
    &:first-child{
      a{
        position: relative !important;
      }
    }
  }
}
.g-sidenav-hidden:not(.g-sidenav-pinned) #sub-menu li::before{
    opacity: 0;
}
.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item > .nav-link.active{
    padding-left: 1.5rem;
    padding-right: 1.5rem;

}

.nav-item .nav-link.collapsed:after {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(0, 0, 0, 0.6);
  font-variant: normal;
  display: inline-block;
  margin-left: auto;
  content:"";
}
.nav-item .nav-link.not-collapsed:after {
  content:"";
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid rgba(0, 0, 0, 0.6);
  display: inline-block;
  margin-left: auto;
}

</style>
