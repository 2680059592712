<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid">
    <div class="card col-xl-12">
      <div class="row">
        <div class="col-xl-12">
          <h1 class="page-title mt-2">
            {{ $t("menu.showListShift") }}
          </h1>
          <p>{{ $t("label.totalWorkShifts") }} : {{totalRecords}}</p>
        </div>
        <div class="col-xl-12">
          <div class="row">
            <div class="col-xs-12 col-md-3">
              <input
                type="text"
                v-model="shiftsName"
                :placeholder="$t('label.search')"
                class="form-control"
              />
            </div>
            <div class="col-md-2 col-xs-12">
              <b-button
                variant="btn btn-info  btn-outline-primary w-100"
                @click="searchShifts()"
                >{{$t('label.search')}}
              </b-button>
            </div>
            <div class="col-xs-12 col-md-7 text-right">
              <a
                href="javascript:void(0)"
                class="btn btn-danger bg-gradient-danger"
                @click="showAddListShift()"
                ><font-awesome-icon icon="plus" /> {{ $t("label.addNew") }}</a
              >
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <div class="row mt-3">
            <div class="col-xl-12 mt-4">
              <div class="card">
                <div class="card-body m-0 p-0 table-responsive">
                  <b-table
                    striped
                    bordered
                    class="default-table"
                    hover
                    :items="dataTable"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    responsive
                    id="table-inouthistory"
                  >
                    <template #cell(name)="data"> {{data.item.shifts_name}} {{data.item.default_shifts ? '('+  $t('label.default') +')' : ''}}</template>
                    <template #cell(workingTime)="data">
                      <div v-html="getWorkingTime(data.item.time_start_work,data.item.time_finish_work,data.item.time_start_rest,data.item.time_finish_rest)"></div></template>
                    <template #cell(applyTime)="data">


                      <div v-if="data.item.is_weekly ">
                        {{$t('label.weekly')}}
                      </div>
                      <div v-else-if="data.item.apply_from != data.item.apply_to">
                        {{convertDate(data.item.apply_from) + ' - ' + convertDate(data.item.apply_to)}}
                      </div>
                      <div v-else>
                        {{convertDate(data.item.apply_from)}}
                      </div>

                    </template>
                    <template #cell(button)="data">
                      <b-button
                        @click="showAddListShift(data.item.id)"
                        :title="$t('label.edit')"
                        class="btn btn-primary btn-sm"
                        variant="primary"
                        depressed
                        elevation="2"
                        v-b-tooltip.hover
                        ><font-awesome-icon icon="edit"
                      /></b-button>
                      <a
                        :href="'/admin/staff-in-shift/'+data.item.id"
                        :title="$t('label.staffInShift')"
                        v-b-tooltip.hover
                        class="btn btn-danger btn-sm bg-gradient-danger"
                      ><font-awesome-icon icon="list" /></a
                      >
                      <b-button
                        class="btn btn-warning btn-sm"
                        :title="$t('label.delete')"
                        v-b-tooltip.hover
                        @click="deleteShift(data.item.id)"
                        depressed
                        elevation="2"
                        ><font-awesome-icon icon="trash"
                      /></b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="dataTable.length"
                    :per-page="perPage"
                    align="right"
                    size="sm"
                    class="m-4"
                    aria-controls="table-inouthistory"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-12">
          <b-modal
            v-model="modalAddShift"
            ref="modal-add-shift"
            :title="$t('label.addEdit') + ' ' + $t('label.sessionWork')"
            header-bg-variant="secondary"
            hide-footer
            centered
            size="lg"
          >
            <div class="row">
              <div class="col-6" v-if="isSupperAdmin">
                <b-form-group
                  class="required-mess"
                  :label="$t('label.company')">
                  <select v-model="company" class="form-control" @change="changeCompany" required>
                    <option v-for="cp in companies" v-bind:value="cp.value">
                      {{ cp.text }}
                    </option>
                  </select>
                  <div class="error-message w-100 clear text-left">
                    {{ msg['company'] ? $t(msg['company']) : ''  }}
                  </div>
                </b-form-group>
              </div>
              <div class="col-6" v-if="isSupperAdmin || isComAdmin">
                <b-form-group
                  class="required-mess"
                  :label="$t('label.branch')">
                  <select v-model="placeId" class="form-control">
                    <option
                      v-for="branch in branchs"
                      v-bind:value="branch.value"
                    >
                      {{ branch.text }}
                    </option>
                  </select>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <b-form-group
                  class="required-mess"
                  :label="$t('label.name')"
                  label-for="input-1"
                >
                  <b-form-input
                    v-model="shifts_name"
                    class="form-control-sm"
                    required
                  ></b-form-input>
                  <div class="error-message w-100 clear text-left font-weight-normal">
                    {{ msg['shiftsName'] ? $t(msg['shiftsName']) : ''  }}
                  </div>
                </b-form-group>
              </div>

              <div class="col-6">
                <b-form-group
                  :label="$t('label.numberOfWorkdays')"
                  label-for="input-3"
                >
                  <b-form-input
                    v-model="number_work_day"
                    type="number"
                    class="form-control-sm"
                    required
                    value="1"
                  ></b-form-input>
                  <div class="error-message w-100 clear text-left">
                    {{ msg['numberWorkDay'] ? $t(msg['numberWorkDay']) : ''  }}
                  </div>
                </b-form-group>
              </div>
            </div>
            <div class="card p-2 mb-2 border">
              <div class="row">
                <div class="col-6">
                  <b-form-group
                    id="input-group-4"
                    :label="$t('label.timeToStartShift')"
                    label-for="input-4"
                  >
                    <flat-pickr  v-model="work_start"  :config="configTimePicker"
                                name="time_start"
                                class="form-control time-picker" autocomplete="off" ></flat-pickr>
                    <div class="error-message w-100 clear text-left">
                      {{ msg['timeStartWork'] ? $t(msg['timeStartWork']) : ''  }}
                    </div>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group
                    id="input-group-5"
                    :label="$t('label.endTimeOfShift')"
                    label-for="input-4"
                  >
                    <flat-pickr  v-model="work_end"  :config="configTimePicker"
                                name="time_start"
                                class="form-control time-picker" autocomplete="off" ></flat-pickr>
                    <div class="error-message w-100 clear text-left">
                      {{ msg['timeFinishWork'] ? $t(msg['timeFinishWork']) : ''  }}
                    </div>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div>
                <b-form-checkbox v-model="showMidShiftBreak">{{ $t("label.thereIsABreakBetweenShift") }}</b-form-checkbox>
            </div>
            <div class="card p-2 mb-2 border" v-show="showMidShiftBreak">
              <div class="row">
                <div class="col-6">
                  <b-form-group
                    id="input-group-6"
                    :label="$t('label.timeToStartABreakInTheMiddleOfTheShift')"
                    label-for="input-4"
                  >
                    <flat-pickr  v-model="rest_start"  :config="configTimePicker"
                                name="time_start"
                                class="form-control time-picker" autocomplete="off" ></flat-pickr>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group
                    id="input-group-7"
                    :label="$t('label.endHourBreakBetweenShift')"
                    label-for="input-4"
                  >
                    <flat-pickr  v-model="rest_end"  :config="configTimePicker"
                                name="time_start"
                                class="form-control time-picker" autocomplete="off" ></flat-pickr>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="card p-2 mb-2 border">
              <b-form-group :label="$t('label.operationTimeOfShift')">
                  <b-form-checkbox class="custom-control-inline" v-model="work_monday">{{ $t("label.monday") }}</b-form-checkbox>
                  <b-form-checkbox class="custom-control-inline" v-model="work_tuesday">{{ $t("label.tuesday") }}</b-form-checkbox>
                  <b-form-checkbox class="custom-control-inline" v-model="work_wednesday">{{ $t("label.wednesday") }}</b-form-checkbox>
                  <b-form-checkbox class="custom-control-inline" v-model="work_thursday">{{ $t("label.thursday") }}</b-form-checkbox>
                  <b-form-checkbox class="custom-control-inline" v-model="work_friday">{{ $t("label.friday") }}</b-form-checkbox>
                  <b-form-checkbox class="custom-control-inline" v-model="work_saturday">{{ $t("label.saturday") }}</b-form-checkbox>
                  <b-form-checkbox class="custom-control-inline" v-model="work_sunday">{{ $t("label.sunday") }}</b-form-checkbox>
              </b-form-group>
            </div>
            <div class="card p-2 mb-2 border">
              <div class="row">
                <div class="col-6">
                  <b-form-group
                    id="input-group-9"
                    :label="$t('label.repeatShifts')"
                    label-for="input-4">
                    <b-form-checkbox v-model="is_weekly" @change="setWeekly($event)"> {{$t('label.repeatWeekly')}}</b-form-checkbox>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group
                    id="input-group-10"
                    :label="$t('label.timeToApply')"
                    label-for="input-4"
                  >
                    <flat-pickr  v-model="dateRange"  :config="configDateTimePicker"
                                 :disabled="is_weekly"
                                class="form-control"></flat-pickr>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="card p-2 mb-2 border">
              <div class="row">
                <div class="col-6">
                  <b-form-group
                    id="input-group-11"
                    :label="$t('label.timeCheckinLate')"
                    label-for="input-4"
                  >
                    <b-form-input
                      v-model="allow_checkin_late"
                      class="form-control-sm"
                      placeholder="Enter name"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group
                    id="input-group-12"
                    :label="$t('label.timeCheckoutEarly')"
                    label-for="input-4"
                  >
                    <b-form-input
                      v-model="allow_checkout_early"
                      class="form-control-sm"
                      placeholder="Enter name"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="card p-2 mb-2 border">
              <div class="row">
                <div class="col-4">
                    <b-form-checkbox v-model="default_shifts">{{$t('label.setAsDefault')}}</b-form-checkbox>
                </div>
                <div class="col-4">
                  <b-form-checkbox v-model="apply_overtime">{{$t('label.applyOvertime')}}</b-form-checkbox>
                </div>
                <div class="col-4">
                    <b-form-checkbox v-model="is_holiday">{{$t('label.itAHoliday')}}</b-form-checkbox>
                </div>
              </div>
            </div>

            <div class="mt-3 text-center">
              <b-button class="btn btn-danger" @click="saveShifts()">{{ $t("label.save") }}</b-button>
              <b-button class="btn btn-default" @click="hideModalAddShift">{{
                $t("label.cancel")
                }}</b-button>
            </div>
          </b-modal>
          <b-modal
            v-model="modalConfirmDelete"
            id="modal-confirm-delete"
            hide-header
            hide-footer
            header-bg-variant="secondary"
            modal-class="modal-delete text-center"
            centered
            size="md">
            <div class="modal-delete--warning">
              <div class="modal-delete--warning__content">!</div>
            </div>
            <div>
              <h2 class="text-warning">{{ $t('label.areYouSure') }}?</h2>
            </div>
            <div>{{ $t('label.sureDeleteShift') }}</div>
            <div class="mt-3">
              <b-button variant="primary" @click="confirmDelete()">{{
                $t('label.iAgree')
                }}
              </b-button>
              <b-button variant="secondary" @click="$bvModal.hide('modal-confirm-delete')">{{
                $t('label.cancel')
                }}
              </b-button>
            </div>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiServer from '@/axiosServer'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import FlatpickrLanguages from "flatpickr/dist/l10n"



import moment from 'moment'


export default {
  components: {
    flatPickr
  },
  data () {
    return {
      isSupperAdmin: false,
      isComAdmin: false,
      dataTable: [],
      fields: [
        {
          label: this.$t('label.name'),
          key: 'name',
          class: "align-middle",
          thClass: 'text-center',
          sortable: false
        },
        {
          label: this.$t('label.workingTime'),
          key: 'workingTime',
          tdClass: 'text-center',
          thClass: 'text-center',
          class: "align-middle",
          sortable: false
        },
        {
          label: this.$t('label.timeToApply'),
          key: 'applyTime',
          tdClass: 'text-center',
          thClass: 'text-center',
          class: "align-middle",
          sortable: false
        },
        {
          label: this.$t('label.control'),
          key: 'button',
          tdClass: 'text-center',
          class: "align-middle",
          thClass: 'text-center'
        }
      ],
      currentPage: 1,
      perPage: 25,
      modalAddShift: false,
      shiftsName: undefined,
      totalRecords: 0,
      shifts_name: '',
      work_monday: false,
      work_tuesday: false,
      work_wednesday: false,
      work_thursday: false,
      work_friday: false,
      work_saturday: false,
      work_sunday: false,
      default_shifts: false,
      is_holiday: false,
      number_work_day: 0,
      apply_overtime: false,
      is_weekly: false,
      work_start: '',
      work_end: '',
      rest_start: '',
      rest_end: '',
      allow_checkin_late: 0,
      allow_checkout_early: 0,
      msg: {},
      showMidShiftBreak: false,
      dateRange: '',
      configDateTimePicker: {
        dateFormat: 'd/m/Y',
        mode: 'range',
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true
      },
      configTimePicker:{
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        allowInput: true,
      },
      modalConfirmDelete: false,
      shiftId: null,
      langFlat:'',
      companies: [],
      company: null,
      branchs: [],
      placeId: null
    }
  },
  mounted(){

  },
  created() {
    this.getListShifts();
    if(this.$store.getters.locale == 'vi'){
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"]
    }
    if (this.$store.getters.superAdmin == true) {
      this.isSupperAdmin = true;
      this.getCompany();
    }
    if (this.$store.getters.comAdmin == true) {
      this.isComAdmin = true;
    }
    if (this.isSupperAdmin || this.isComAdmin){
      this.getListBranch();
    }
  },
  methods: {
    async showAddListShift (idEdit) {
      this.resetForm();
      if(idEdit){
        this.idEdit = idEdit
        let res
        try {
          res = await apiServer.post('api/v1/shifts/get-by-id', {
            id: this.idEdit
          })
        } catch (error) {
        }
        if (res && res.data) {
          this.shifts_name = res.data.shifts_name;
          this.company = res.data.companyId;
          this.placeId = res.data.placeId;
          this.default_shifts = res.data.default_shifts == 1 ? true : false;
          this.is_holiday = res.data.is_holiday == 1 ? true : false;
          this.number_work_day = res.data.number_work_day;
          this.is_weekly = res.data.is_weekly == 1 ? true : false;
          this.work_monday = res.data.work_monday == 1 ? true : false;
          this.work_tuesday = res.data.work_tuesday == 1 ? true : false;
          this.work_wednesday = res.data.work_wednesday == 1 ? true : false;
          this.work_thursday = res.data.work_thursday == 1 ? true : false;
          this.work_friday = res.data.work_friday == 1 ? true : false;
          this.work_saturday = res.data.work_saturday == 1 ? true : false;
          this.work_sunday = res.data.work_sunday == 1 ? true : false;
          this.allow_checkin_late = res.data.allow_checkin_late;
          this.allow_checkout_early = res.data.allow_checkout_early;
          this.apply_overtime = res.data.apply_overtime == 1 ? true : false;
          this.work_start = res.data.time_start_work.slice(0, -3);
          this.work_end = res.data.time_finish_work.slice(0, -3);
          if(res.data.time_start_rest !== null && res.data.time_start_rest !== ''){
            this.rest_start = res.data.time_start_rest.slice(0, -3);
            this.showMidShiftBreak = true;
          }
          if(res.data.time_finish_rest !== null && res.data.time_finish_rest !== ''){
            this.rest_end = res.data.time_finish_rest.slice(0, -3);
          }
          if(res.data.apply_from != null){
            this.dateRange = res.data.apply_from + ' ' + this.$t('label.to')+ ' ' + res.data.apply_to;
          }
          if (this.isSupperAdmin || this.isComAdmin){
            this.getListBranch();
          }
        }
      }

      this.modalAddShift = true
    },
    async getListBranch(){
      let res;
      this.branchs = [];
      try{
        res = await apiServer.post('/api/v1/company/get-all-branch',{
          company: this.company
        });
        if (res && res.data) {
          for (let br of res.data) {
            this.branchs.push({value: br.placeID, text:br.branch_name});
          }
          this.date = this.dateRange
        }
      }
      catch (e) {
        console.log(e);
      }
    },
    changeCompany() {
      this.placeId = null;
      this.getListBranch();
    },
    hideModalAddShift () {
      this.resetForm();
      this.$refs['modal-add-shift'].hide();
    },
    async saveShifts() {
      let res;
      let af = null, at = null;
      if (this.dateRange !== '' && this.dateRange !== null) {
        let dateArr = this.dateRange.split(' ');
        af = this.convertTimestampSendDate(dateArr[0]);
        if(dateArr.length == 1){
          at = af;
        }
        else {
          at = this.convertTimestampSendDate(dateArr[2]);
        }
      }


      if(this.showMidShiftBreak == false){
        this.rest_start = this.rest_end = null;
      }
      let formData = {
        id: this.idEdit,
        company: this.company,
        placeId: this.placeId,
        shifts_name: this.shifts_name,
        default_shifts: this.default_shifts,
        is_holiday: this.is_holiday,
        number_work_day: this.number_work_day,
        is_weekly: this.is_weekly,
        work_monday: this.work_monday,
        work_tuesday: this.work_tuesday,
        work_wednesday: this.work_wednesday,
        work_thursday: this.work_thursday,
        work_friday: this.work_friday,
        work_saturday: this.work_saturday,
        work_sunday: this.work_sunday,
        apply_from: af,
        apply_to: at,
        time_start_work: this.work_start,
        time_finish_work: this.work_end,
        time_start_rest: this.rest_start,
        time_finish_rest: this.rest_end,
        allow_checkin_late: this.allow_checkin_late,
        allow_checkout_early: this.allow_checkout_early,
        apply_overtime: this.apply_overtime
      };
      try {
        res = await apiServer.post(
          'api/v1/shifts/add',
          formData,
        )
      } catch (error) {
        if (error.response.status === 400) {
          this.msg = (error.response.data)
        } else if (error.response.status === 401) {
          alert(error.response.data)
        }
      }
      if (res && res.data) {
        this.hideModalAddShift();
        this.$notify({
          group: 'bottomnotification',
          title: this.$t('label.notification'),
          text: this.$t(res.data.message),
          position:'bottom-center'
        });
        this.getListShifts();
      }
    },
    async getListShifts(){
      this.dataTable = [];
      this.totalRecords = 0;
      let res
      try {
        res = await apiServer.post('api/v1/shifts/get', {
          shifts_name: this.shiftsName
        })
      } catch (error) {
      }
      if (res && res.data) {
        if (res.data.length > 0) {
          this.dataTable = res.data
          this.totalRecords = res.data.length
        }
      }
    },
    async getCompany(){
      let res
      try {
        res = await apiServer.post('/api/v1/company/get-list', {})
      } catch (error) {}
      if (res && res.data) {
        if (res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            this.companies.push({
              text: res.data[i].name,
              value: res.data[i].id
            })
          }
        }
      }
    },
    searchShifts(){
      this.getListShifts();
    },

    deleteShift(shift_id){
      this.modalConfirmDelete = true;
      this.shiftId = shift_id;
    },
    async confirmDelete(){
      let res
      try {
        res = await apiServer.post('/api/v1/shift/delete', {
          shiftid: this.shiftId
        })
      } catch (error) {
        if (error.response.status === 400) {
          console.log(error.response.data);
        } else if (error.response.status === 401) {
           alert(this.$t(error.response.data))
        }
      }
      if (res && res.data) {
        this.modalConfirmDelete = false;
        this.$notify({
          group: 'bottomnotification',
          title: this.$t('label.notification'),
          text: this.$t(res.data.message)
        });
        this.getListShifts();
      }
    },
    convertDate(inputDate) {
      if (inputDate == undefined || inputDate == null) return '';
      return  moment(inputDate).format("DD/MM/yyyy");
    },
    getWorkingTime(startwork,endwork,startrest,endrest){
      if(startrest !== null && startrest !== undefined){
        return startwork.slice(0,-3) + ' - ' + startrest.slice(0,-3) + '<br/> ' + endrest.slice(0,-3) + ' - ' + endwork.slice(0,-3);
      }
      else if(startwork !== null && startwork !== undefined) {
        return startwork.slice(0,-3) + ' - ' + endwork.slice(0,-3);
      }
      else{
        return "";
      }
    },
    setWeekly(evt){
      if(evt === true){
        this.dateRange = '';
      }
    },
    convertTimestampSendDate (date) {
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, '$3-$2-$1');
      var date = new Date(changedDate);
      return moment(date).format('yyyy-MM-DD');
    },

    resetForm() {
      this.dateRange = null;
      this.idEdit = null;
      this.shifts_name = '';
      this.default_shifts = false;
      this.is_holiday = false;
      this.number_work_day = 0;
      this.is_weekly = false;
      this.work_start = '';
      this.work_end = '';
      this.rest_start = '';
      this.rest_end = '';
      this.allow_checkin_late = 0;
      this.allow_checkout_early = 0;
      this.showMidShiftBreak = false;
      this.work_monday = false;
      this.work_tuesday = false;
      this.work_wednesday = false;
      this.work_thursday = false;
      this.work_friday = false;
      this.work_saturday = false;
      this.work_sunday = false;
      this.apply_overtime = false;
      this.msg = {}
    }
  }
}
</script>

<style lang="scss">
.form-control-sm {
  padding: 5px 10px !important;
}
input,
select {
  color: #333 !important;
}
</style>
<style lang="scss" scoped>
  .time-picker{
    width: auto;
  }
</style>
