import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    isLogin: false,
    jwt: '',
    role: '',
    locale: 'vi',
    fullName: '',
    avatar: '',
    placeID: '',
    audio: true,
    superAdmin: false,
    comAdmin: false,
    approval: false
  },
  getters: {
    isLogin: state => state.isLogin,
    jwt: state => state.jwt,
    role: state => state.role,
    locale: state => state.locale,
    fullName: state => state.fullName,
    avatar: state => state.avatar,
    placeID: state => state.placeID,
    audio: state => state.audio,
    superAdmin: state => state.superAdmin,
    comAdmin: state => state.comAdmin,
    approval: state => state.approval
  },
  mutations: {
    doLogin(state, payload) {
      state.isLogin = true;
      state.role = payload.role;
      state.fullName = payload.fullName;
      state.avatar = payload.avatar;
      state.placeID = payload.placeID;
      state.superAdmin = payload.supperAdmin;
      state.comAdmin = payload.comAdmin;
      state.approval = payload.approval;
    },
    saveJwt(state, token) {
      state.jwt = token
    },
    doLogout(state) {
      state.isLogin = false;
      state.jwt = '';
      state.role = '';
      state.fullName = '';
      state.avatar = '';
      state.placeID = '';
      state.superAdmin = false;
      state.comAdmin = false;
      state.approval = false;
    },
    updateFullName(state, fullName) {
      state.fullName = fullName
    },
    updateAvatar(state, avatar) {
      state.avatar = avatar
    },
    updateAudio(state, value) {
      state.audio = value
    },
    updateLocale(state, locale) {
      state.locale = locale
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
