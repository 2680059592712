
<template>
  <div :class="layout ? 'g-sidenav-show g-sidenav-pinned' : 'g-sidenav-show g-sidenav-hidden'" @click="clickBody()">
    <div class="layout">
      <menu-side-bar @check-menu="checkMenu"/>
      <!-- /top navigation -->
      <div class="main-content">
        <!-- top navigation -->
        <menu-top-header @check-menu="checkMenu"/>
        <router-view/>
      </div>
    </div>
  </div>
</template>
<script>
import MenuSideBar from '@/components/MenuSideBarEmployee.vue'
import MenuTopHeader from '@/components/MenuTopHeader.vue'

export default {
  components: { MenuTopHeader, MenuSideBar },
  data () {
    return {
      layout: true,
      hover: true,
      width: 0,
      height: 0,
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    if(window.innerWidth < 600){
      this.layout = false
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      return window.innerWidth
    },
    checkMenu () {
      this.layout = !this.layout
    },
    clickBody () {
      if (this.handleResize() < 1201) {
        this.layout = false
      }
    }
  }
}
</script>
<style lang="scss">
.btn-outline-primary{
    color: #FB6340 !important;
    border-color: #FB6340 !important;
}
.btn-outline-primary:hover{
  background-color: #FB6340 !important;
  color: #fff !important;
}
.page-item.active {
  .page-link {
      z-index: 3;
      color: #fff;
      background-color: #FB6340 !important;
      border-color: #FB6340 !important;
  }
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #FB6340 !important;
    background-color: #FB6340 !important;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    background-color: #FB6340 !important;
    border-color: #FB6340 !important;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #FB6340 !important;
}
.form-control:focus {
    border-color: #FB6340 !important;
}
</style>
