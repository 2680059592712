<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid">
    <div class="card col-xl-12">
      <div class="row">
      <div class="col-xl-12">
        <h1 class="page-title mt-2">
          {{ $t('menu.attendanceManagement') }}
        </h1>
        <p>{{$t('label.totalDailyShifts')}} {{ date }}: {{dataTable.length}}</p>
      </div>
      <div class="col-xl-12">
        <div class="row">
          <div class="col-xs-12 col-lg-3 col-md-4 mt-3">
            <flat-pickr  v-model="dateRange"  :config="configDateTimePicker" :placeholder='$t("label.selectDateRange")'
                         name="date_apply" class="form-control"></flat-pickr>
          </div>
          <div class="col-xs-12 col-lg-3 col-md-4 mt-3">
            <input type="text" v-model="usercode" :placeholder="$t('label.employeeCode')" class="form-control">
          </div>
          <div class="col-xs-12 col-lg-3 col-md-4 mt-3">
            <input type="text" v-model="username" :placeholder="$t('label.staffsName')" class="form-control">
          </div>
          <div class="col-xs-12 col-lg-3 col-md-4 mt-3" v-if="isSupperAdmin || isComAdmin">
            <select v-model="branch" class="form-control">
                  <option
                    v-for="branch in branchs"
                    v-bind:value="branch.value"
                  >
                    {{ branch.text }}
                  </option>
                </select>
          </div>
          <div class="col-xs-12 col-lg-3 col-md-4 mt-3">
            <treeselect v-model="departmentid" :multiple="true" :options="departmentTree" :flat="true"
                          :default-expand-level="1" :placeholder="$t('label.chooseDepartment')" />
          </div>
          <div class="col-xs-12 col-lg-3 col-md-4 mt-3">
            <treeselect v-model="positionid" :multiple="true" :options="positionTree" :flat="true"
                          :default-expand-level="1" :placeholder="$t('label.choosePosition')" />
          </div>
          <div class="col-xs-12 col-lg-3 col-md-4 mt-3">
            <b-form-checkbox v-model="lateEarly">{{$t('label.late') + '/' + $t('label.comeBackBefore')}}</b-form-checkbox>
          </div>
          <div class="col-xs-12 col-lg-3 col-md-4 mt-3">
            <b-button
              variant="btn btn-info  btn-outline-primary w-100 font-weight-bold"
              @click="search('search')"
              >{{$t('label.search')}}
            </b-button>
          </div>
        </div>
      </div>
      <div class="col-xl-12 mt-4">
        <div class="row mt-3">
          <div class=" col-xl-12">
            <div class="card">

              <div class="card-body m-0 p-0 table-responsive">
                <b-table
                  striped bordered
                  class="default-table"
                  id="table-inouthistory"
                  hover
                  :items="dataTable"
                  :fields="fields"
                  responsive
                  :current-page="currentPage"
                  :per-page="perPage"
                >
                  <template #cell(avatar)="data">
                    <a href="javascript:void(0)" @click="showImage(data.item.avatar)">
                      <img width="95px" height="95px" :src="data.item.avatar" :alt="data.item.personName"/>
                    </a>
                  </template>
                  <template #cell(dateOfEntry)="data">
                    {{   convertTimestampToDate(data.item.dateID)}}
                  </template>
                  <template #cell(name)="data">
                    <ul class="list-unstyled m-0">
                      <li><strong>{{ data.item.fullName }}</strong></li>
                      <li class="small">{{$t('label.idEmployee')}}: {{ data.item.aliasID }}</li>
                      <li class="small">{{$t('label.department')}}: {{ data.item.department }}</li>
                      <li class="small">{{$t('label.jobPosition')}}: {{ data.item.position }}</li>
                    </ul>
                  </template>
                  <template #cell(shift)="data">
                    <span class="bold" v-if="data.item.default_shift == true">{{$t('label.defaultShift')}}</span>
                    {{data.item.shifts_name}}
                  </template>
                  <template #cell(toWork)="data">
                    {{convertOnlyTime(data.item.checkin)}}
                  </template>
                  <template #cell(endWork)="data">
                    <template v-if="data.item.checkout != null">
                      {{convertOnlyTime(data.item.checkout)}}
                    </template>
                    <template v-else>
                      <span> </span>
                    </template>
                  </template>
                  <template #cell(minuteLate)="data">
                    {{data.item.minute_late}}
                  </template>
                  <template #cell(minuteEarly)="data">
                    {{data.item.minute_out_early}}
                  </template>
                </b-table>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="dataTable.length"
                  :per-page="perPage"
                  align="right"
                  size="sm"
                  class="m-4"
                  aria-controls="table-inouthistory"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="modalImage" hide-footer centered hide-header modal-class="modal-image" size="lg">
      <div class="d-block text-center">
        <img :src="urlImage"/>
      </div>
    </b-modal>
    </div>
    <pulse-loader :loading="loading"></pulse-loader>
  </div>
</template>
<script>
import apiClient from '@/axios'
import apiServer from '@/axiosServer'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import FlatpickrLanguages from "flatpickr/dist/l10n"
import Treeselect from '@riophae/vue-treeselect'
import PulseLoader from '@/components/PulseLoader.vue'
import moment from 'moment'
export default {
  components: {
    flatPickr,
    Treeselect,
    PulseLoader
  },
  data () {
    return {
      isSupperAdmin: false,
      isComAdmin: false,
      loading: false,
      date: this.getNowDate(),
      configDateTimePicker: {
        dateFormat: 'd/m/Y',
        mode: "range",
        maxDate: "today",
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true
      },
      urlImage: '',
      modalImage: false,
      accessToken: process.env.VUE_APP_TOKEN_HANET,
      placeId: '',
      branch: undefined,
      branchs: [],
      departmentTree: [],
      positionTree: [],
      username: '',
      dateRange: this.getNowDate(),
      from_date: null,
      to_date: null,
      usercode: null,
      departmentid: null,
      positionid: null,
      lateEarly: null,
      dataTable: [],
      currentPage: 1,
      perPage: 25,
      fields: [
        {
          label: this.$t('label.image'),
          key: 'avatar',
          tdClass: 'text-center',
          thClass: 'text-center',
          class: "align-middle",
          sortable: false
        },
        {
          label: this.$t('label.dateOfEntry'),
          key: 'dateOfEntry',
          class: "align-middle",
          sortable: false
        },
        {
          label: this.$t('label.fullName'),
          key: 'name',
          class: "align-middle",
          sortable: false
        },
        {
          label: this.$t('label.sessionWork'),
          key: 'shift',
          tdClass: 'text-center',
          class: "align-middle",
          thClass: 'text-center',
          sortable: true
        },
        {
          label: this.$t('label.toWork'),
          key: 'toWork',
          tdClass: 'text-center',
          class: "align-middle",
          thClass: 'text-center',
          sortable: true
        },
        {
          label: this.$t('label.endWork'),
          key: 'endWork',
          tdClass: 'text-center',
          class: "align-middle",
          thClass: 'text-center',
          sortable: true
        },
        {
          label: this.$t('label.minuteLate'),
          key: 'minuteLate',
          tdClass: 'text-center',
          class: "align-middle",
          thClass: 'text-center',
          sortable: true
        },
        {
          label: this.$t('label.minuteEarly'),
          key: 'minuteEarly',
          class: "align-middle",
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: true
        }
      ],
    }
  },
  mounted () {
    if (this.$store.getters.superAdmin == true) {
      this.isSupperAdmin = true;
    }
    if (this.$store.getters.comAdmin == true) {
      this.isComAdmin = true;
    }
    if (this.isSupperAdmin || this.isComAdmin){
      this.getListBranch();
    }
    this.getDepartmentTree();
    this.getPositionTree();
    this.getListPersonByPlace();
    if(this.$store.getters.locale == 'vi'){
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"]
    };
  },
  methods: {
    getNowDate () {
      let today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0')
      const yyyy = today.getFullYear()
      today = dd + '/' + mm + '/' + yyyy
      return today
    },
    search (search) {
      if (this.departmentid && this.departmentid.length == 0){
        this.departmentid = null;
      }
      if (this.positionid && this.positionid.length == 0){
        this.positionid = null;
      }
      this.getListPersonByPlace(search)
      this.date = this.dateRange
    },
    async getListBranch(){
      let res;
      try{
        res = await apiServer.post('/api/v1/company/get-all-branch',{});
        if (res && res.data) {
          this.branchs.push({value:undefined, text: this.$t('label.allBranches')});
          for (let br of res.data) {
            this.branchs.push({value: br.placeID, text:br.branch_name});
          }
          this.date = this.dateRange
        }
      }
      catch (e) {
        console.log(e);
      }
    },
    async getDepartmentTree(){
      let res
      try {
        res = await apiServer.get('api/v1/department/tree', {})
      } catch (error) {
      }
      if (res && res.data) {
        this.departmentTree = [res.data];
      }
    },
    async getPositionTree(){
      let res
      try {
        res = await apiServer.get('api/v1/position/tree', {})
      } catch (error) {
      }
      if (res && res.data) {
        this.positionTree = [res.data];
      }
    },
    convertDateTime (date) {
      return moment(date).format("DD/MM/yyyy HH:mm:ss");
    },
    convertOnlyTime (date) {
      return moment(date).format("HH:mm:ss");
    },
    convertDate(inputDate){
      if (inputDate == undefined || inputDate == null) return '';
      let date = new Date(inputDate)
      const dd = String(date.getDate()).padStart(2, '0')
      const mm = String(date.getMonth() + 1).padStart(2, '0')
      const yyyy = date.getFullYear()
      return  yyyy + '-' + mm + '-' + dd;
    },
    showImage (urlImage) {
      this.urlImage = urlImage
      this.modalImage = true
    },
    async getListPersonByPlace (search) {
      this.loading = true;
      this.from_date = null;
      this.to_date = null;
      if (this.dateRange !== '') {
        let dateArr = this.dateRange.split(' ');

        this.from_date = this.convertTimestampSendDate(dateArr[0]);
        if(dateArr.length == 1){
          this.to_date = this.from_date;
        }
        else {
          this.to_date = this.convertTimestampSendDate(dateArr[2]);
        }
      }
      let res
      // eslint-disable-next-line no-useless-catch
      try {
        res = await apiServer.post('/api/v1/camera/get-check-in', {
          fromdate: this.from_date,
          todate: this.to_date,
          usercode: this.usercode,
          username: this.username,
          placeid: this.branch,
          departmentid: this.departmentid,
          positionid: this.positionid,
          islate: this.lateEarly
        })
      } catch (error) {
        this.loading = false;
        throw error
      }
      if (res && res.data) {
        this.dataTable = res.data;
        this.loading = false;
      }
    },
    convertTimestampToDate (timestamp) {
      return moment(timestamp).format('DD/MM/yyyy')
    },
    convertTimestampSendDate (date) {
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, '$3-$2-$1');
      var date = new Date(changedDate);
      return moment(date).format('yyyy-MM-DD')
    },
  }
}
</script>
<style scoped lang="scss">
  .btn {
    font-weight: normal;
  }

  .filter-asc, .filter-desc {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }

  .filter-asc {
    margin-bottom: 1px;
    border-bottom: 5px solid #ccc;
  }

  .img-profile {
    width: 60px !important;
    border-radius: 10px;
    border: 1px solid #dedede;
  }

  .table-small td {
    font-size: 11px !important;
    padding: 5px !important;
    vertical-align: middle !important;
  }
</style>
