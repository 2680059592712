<template>
  <!-- Page content -->
  <div class="container-fluid mt-3">
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header border-0">
            <div class="row align-items-center">
              <div class="col-md-4 col-xs-12">
                <label>{{$t('label.nameEmployee')}}</label>
                <input
                  type="text"
                  v-model="namePerson"
                  class="form-control"
                />
              </div>
              <div class="col-md-4 col-xs-12">
                <label>{{$t('label.object')}}</label>
                <select class="form-control" v-model="modelObject">
                  <option
                    v-for="optionsObjectItem in optionsObject"
                    v-bind:value="optionsObjectItem.value"
                  >
                    {{ optionsObjectItem.text }}
                  </option>
                </select>
              </div>
              <div class="col-md-4 col-xs-12">
                <label>{{$t('label.selectCamera')}}</label>
                <select class="form-control" v-model="modelDevice">
                  <option
                    v-for="optionsDeviceItem in optionsDevice"
                    v-bind:value="optionsDeviceItem.value"
                  >
                    {{ optionsDeviceItem.text }}
                  </option>
                </select>
              </div>
              <div class="col-md-3 col-xs-12 mt-4">
                <label>{{ $t('label.start') }}</label>
                <flat-pickr v-model="startDateString" :config="configDateTimePicker"
                            name="birthdate"
                            class="form-control"></flat-pickr>
              </div>
              <div class="col-md-3 col-xs-12 mt-4">
                <label>{{ $t('label.end') }}</label>
                <flat-pickr v-model="endDateString" :config="configDateTimePicker"
                            name="birthdate"
                            class="form-control"></flat-pickr>
              </div>
              <div class="col-md-3 col-xs-12 mt-4">
                <label>{{$t('label.selectTime')}}</label>
                <select class="form-control" v-model="modelEsc">
                  <option
                    v-for="optionsEscItem in optionsEsc"
                    v-bind:value="optionsEscItem.value"
                  >
                    {{ optionsEscItem.text }}
                  </option>
                </select>
              </div>
              <div class="col-md-3 col-xs-12 mt-4">
                <b-button variant="btn btn-info  btn-outline-primary w-100 " style="margin-top: 29px;"  @click="search()">{{$t('label.search')}}
                </b-button>
              </div>
            </div>
          </div>
          <div class="number-records"><span>{{$t('label.totalRecord')}}{{dataTable.length}}</span>
            <button class="btn btn-sm btn-primary float-right mr-3 mb-2"
                    @click="exportInOutHistory()">
              <font-awesome-icon icon="download" />
              {{ $t('label.exportReport') }}
            </button>
          </div>
          <div class="col-12">
            <b-table
            striped bordered
            class="default-table"
            hover
            :items="dataTable"
            :fields="fields"
            responsive
            :current-page="currentPage"
            :per-page="perPage"
            id="table-inouthistory"
            :busy="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(personType)="data">
              <span v-if="data.item.personType == 0">{{$t('label.employee')}}</span>
              <span v-else>{{$t('label.stranger')}}</span>
            </template>
              <template #cell(personName)="data">
                <span v-if="data.item.personType == 0">{{data.item.personName}}</span>
                <span v-else></span>
              </template>
            <template #cell(image)="data">
              <a href="javascript:void(0)" @click="showImage(data.item.avatar)">
                <img width="95px" height="95px" :src="data.item.avatar" :alt="data.item.personName"/>
              </a>
            </template>
            <template #cell(checkinTime)="data">
              {{ convertTimestampToDate(data.item.checkinTime) }}
            </template>
          </b-table>
          </div>

          <b-pagination
            v-model="currentPage"
            :total-rows="dataTable.length"
            :per-page="perPage"
            align="right"
            size="sm"
            class="m-4"
            aria-controls="table-inouthistory"
          ></b-pagination>
        </div>
      </div>
    </div>
    <b-modal v-model="modalImage" hide-footer centered hide-header modal-class="modal-image inouthistory" size="lg" >
      <div class="btn-close"><span @click="hideImage()">x</span></div>
      <div class="d-block text-center">
        <img :src="urlImage" style="width:530px;padding:15px"/>
      </div>
    </b-modal>
    <pulse-loader :loading="loading"></pulse-loader>
  </div>
</template>
<script>
import apiClient from '@/axios'
import apiServer from '@/axiosServer'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'
import FlatpickrLanguages from "flatpickr/dist/l10n"
import PulseLoader from '@/components/PulseLoader.vue'

export default {
  components: {
    flatPickr,
    PulseLoader
  },
  data () {
    return {
      loading: false,
      urlImage: '',
      modalImage: false,
      isBusy: true,
      modelObject: '',
      startDateString: '',
      endDateString: '',
      deviceString: '',
      sort: '',
      nowDate: '',
      modelEsc: 'desc',
      configDateTimePicker: {
        enableTime: true,
        dateFormat: 'd/m/Y H:i:ss',
        time_24hr: true,
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true
      },
      optionsEsc: [
        {
          value: 'asc',
          text: this.$t('label.asc')
        },
        {
          value: 'desc',
          text: this.$t('label.desc')
        }
      ],
      optionsObject: [
        {
          value: '',

          text: this.$t('label.all')
        },
        {
          value: '0',
          text: this.$t('label.employee')
        },
        {
          value: '1',
          text: this.$t('label.guest')
        },
        {
          value: '4',
          text: this.$t('label.stranger')
        }
      ],
      modelDevice: '',
      optionsDevice: [
        {
          value: '',
          text: this.$t('label.all')
        }
      ],
      dataTable: [],
      currentPage: 1,
      perPage: 25,
      placeId: 0,
      fields: [
        {
          label: this.$t('label.idEmployee'),
          key: 'aliasID',
          class: "align-middle",
          sortable: false
        },
        {
          label: this.$t('label.fullName'),
          key: 'personName',
          class: "align-middle",
          sortable: false
        },
        {
          label: this.$t('label.object'),
          key: 'personType',
          class: "align-middle",
          sortable: false
        },
        {
          label: this.$t('label.camera'),
          key: 'deviceName',
          class: "align-middle",
          sortable: false
        },
        {
          label: this.$t('label.image'),
          key: 'image',
          class: "align-middle text-center",
          sortable: false
        },
        {
          label: this.$t('label.time'),
          key: 'checkinTime',
          class: "align-middle",
          sortable: true
        }
      ],
      accessToken: process.env.VUE_APP_TOKEN_HANET,
      namePerson: '',
    }
  },
  mounted () {
    this.getListDeviceByPlace()
    this.startDateString = this.getNowDate() + ' 00:00:00'
    this.endDateString = this.getNowDate() + ' 23:59:00'

    // console.log(this.startDateString)


    this.getCheckinByPlaceIdInTimestamp()
    if(this.$store.getters.locale == 'vi'){
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"]
    };
  },
  methods: {
    hideImage () {
      this.modalImage = false
    },
    showImage (urlImage) {
      this.urlImage = urlImage
      this.modalImage = true
    },
    async getCheckinByPlaceIdInTimestamp () {
      this.loading = true;
      let res
      try {
        res = await apiServer.post('api/v1/camera/get-in-out-history', {
          placeID: this.$store.getters.placeID,
          toDate:  this.convertTimestampSendDate(this.startDateString),
          fromDate: this.convertTimestampSendDate(this.endDateString),
          device: this.modelDevice,
          objectType: this.modelObject,
          sortTime: this.modelEsc,
          userName: this.namePerson
        })
      } catch (error) {
        this.loading = false;
      }
      if (res && res.data) {
        this.dataTable = res.data
        this.isBusy = false;
        this.loading = false;
      }
    },
    async getListDeviceByPlace () {
      let res
      try {
        res = await apiServer.get('api/v1/device/list',{})
      } catch (error) {
      }
      if (res && res.data) {
        const arrayData = res.data
        this.optionsDevice = [
          {
            value: '',
            text: this.$t('label.all')
          }
        ]
        for (let i = 0; i < arrayData.length; i++) {
          this.deviceString += arrayData[i].deviceID + ','
          this.optionsDevice.push({
            value: arrayData[i].deviceID,
            text: arrayData[i].deviceName
          })
        }
      } else {
        this.optionsDevice = [
          {
            value: '',
            text: this.$t('label.all')
          }
        ]
        this.isBusy = false
      }
    },
    async exportInOutHistory () {
      this.loading = true;
      let res
      try {
        res = await apiServer.post('api/v1/camera/export-in-out-history', {
          placeID: this.$store.getters.placeID,
          toDate:  this.convertTimestampSendDate(this.startDateString),
          fromDate: this.convertTimestampSendDate(this.endDateString),
          device: this.modelDevice,
          objectType: this.modelObject,
          sortTime: this.modelEsc,
          userName: this.namePerson
        },{
          responseType: 'blob'
        }).then((response) => {
          this.loading = false;
          const url = URL.createObjectURL(new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'in-out-history')
          document.body.appendChild(link)
          link.click();
        });
      } catch (error) {
        this.loading = false;
      }
    },
    search () {
      this.getCheckinByPlaceIdInTimestamp()
    },
    getNowDate () {
      let today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0')
      const yyyy = today.getFullYear()
      today = dd + '/' + mm + '/' + yyyy
      return today
    },
    convertTimestampToDate (timestamp) {
      return moment(timestamp).format('DD/MM/yyyy HH:mm:ss')
    },
    convertTimestampSendDate (date) {
      if (date == undefined || date == null){
        return null;
      }
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....) (..):(..)/, '$3-$2-$1 $4:$5');
      var date = new Date(changedDate);
      return moment(date.getTime()).format('yyyy-MM-DD HH:mm:ss')
    },

    convertDateToTimestamp (date) {
      if (date == null) return ''
      const newDate = new Date(date)
      return newDate.getTime()
    }
  }
}
</script>
<style lang="scss">
.number-records{
  padding-left: 1.25rem;
  padding-bottom: 0.25rem
}
.modal-image{
  img{
    width: 100%;
    height: 100%;
  }
}
</style>
