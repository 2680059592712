<template>
  <!-- Page content -->
  <div id="main-default" class="container-fluid mt-3">
    <div class="card col-12">
      <div class="row mt-3">
        <div class="col-xs-6 col-md-3 col-lg-2">
          <b-form-group
          :label="$t('label.reportType')">
            <select v-model="reportType" class="form-control" @change="reportTypeChange">
              <option
                v-for="listReportType in listReportTypes"
                v-bind:value="listReportType.value"
              >
                {{ listReportType.text }}
              </option>
            </select>
          </b-form-group>
        </div>
        <div class="col-xs-6 col-md-3 col-lg-2" v-if="isSupperAdmin || isComAdmin">
          <b-form-group :label="$t('label.branch')">
            <select v-model="placeId" class="form-control" @change="changeBranch">
              <option
                v-for="branch in branchs"
                v-bind:value="branch.value"
              >
                {{ branch.text }}
              </option>
            </select>
          </b-form-group>
        </div>
        <div class="col-xs-6 col-lg-2 col-md-3" v-if="reportByDepartPos">
          <b-form-group
            class="required-mess"
            :label="$t('label.department')">
            <treeselect v-model="departmentId" :multiple="false" :options="departmentOption" :default-expand-level="Infinity"
                        :noChildrenText="$t('label.noNodeChildren')" :placeholder="$t('label.chooseDepartment')" />
          </b-form-group>
        </div>
        <div class="col-xs-6 col-lg-2 col-md-3" v-if="reportByDepartPos">
          <b-form-group
            :label="$t('label.jobPosition')">
            <treeselect v-model="positionId" :multiple="false" :options="positionOption" :default-expand-level="Infinity"
                        :noChildrenText="$t('label.noNodeChildren')" :placeholder="$t('label.choosePosition')" />
          </b-form-group>
        </div>
        <div class="col-xs-6 col-lg-2 col-md-3" v-if="reportByMonth == false">
          <b-form-group
            :label="$t('label.selectDateRange')">
            <flat-pickr v-model="dateSelect" :config="configDateTimePicker" :placeholder='$t("label.selectDateRange")'
                        name="reportDate"
                        class="form-control"></flat-pickr>
          </b-form-group>
        </div>
        <div v-if="reportByMonth" class="col-xs-6 col-lg-2 col-md-3">
          <b-form-group
            :label="$t('label.chooseMonth')">
            <select v-model="month" class="form-control">
              <option v-for="m in monthOptions"
                      v-bind:value="m.value">
                {{ m.text }}
              </option>
            </select>
          </b-form-group>
        </div>
        <div v-if="reportByMonth" class="col-xs-6 col-lg-2 col-md-3">
          <b-form-group
            :label="$t('label.chooseYear')">
            <select v-model="year" class="form-control">
              <option v-for="y in yearOptions"
                      v-bind:value="y.value">
                {{ y.text }}
              </option>
            </select>
          </b-form-group>
        </div>
        <div class="col-xs-6 col-lg-2 col-md-3" v-if="reportByUser">
          <b-form-group
            :label="$t('label.employeeCode')">
              <div class="autocomplete" v-click-outside="onClickOutside">
                <b-form-input
                  v-model="aliasId"
                  @input="onChangeCode"
                  type="text">
                  </b-form-input>
                <ul
                  v-show="isOpenCodeRes"
                  class="autocomplete-results"
                >
                  <li
                    v-for="(result, i) in codeResults"
                    :key="i"
                    @click="setCodeResult(result)"
                    class="autocomplete-result"
                  >
                    {{ result.value }} - {{ result.text }}
                  </li>
                </ul>
              </div>
          </b-form-group>
        </div>
        <div class="col-xs-6 col-lg-2 col-md-3" v-if="reportByUser">
          <b-form-group
            :label="$t('label.fullName')">
          <div class="autocomplete" v-click-outside="handleClickOutside">
                <b-form-input
                  v-model="fullName"
                  @input="onChangeName"
                  type="text">
                  </b-form-input>
                <ul
                  v-show="isOpenNameRes"
                  class="autocomplete-results"
                >
                  <li
                    v-for="(result, i) in nameResults"
                    :key="i"
                    @click="setNameResult(result)"
                    class="autocomplete-result"
                  >
                    {{ result.value }} - {{ result.text }}
                  </li>
                </ul>
              </div>
          </b-form-group>
        </div>
        <div class="col-xs-6 col-lg-2 col-md-3" v-if="reportByLateEarly">
          <b-form-group
            :label="$t('label.late') + '/' + $t('label.comeBackBefore')">
            <b-form-checkbox v-model="lateEarly"></b-form-checkbox>
          </b-form-group>
        </div>
        <div class="col-12 text-right mb-2">
            <a
              href="javascript:void(0)"
              class="btn btn-danger bg-gradient-danger"
              @click="previewreport()"
              >{{ $t("label.preview") }}
            </a>
            <a
              href="javascript:void(0)"
              class="btn btn-danger bg-gradient-danger"
              @click="exportReport()"
              >{{ $t("label.exportExcel") }}
            </a>
            <a
              href="javascript:void(0)"
              class="btn btn-danger bg-gradient-danger"
              @click="exportPdf()"
              >{{ $t("label.exportPdf") }}
            </a>
        </div>
      </div>

    </div>
    <div class="card col-12 pt-4">
      <div class="row">
        <div class="col-12 col-md-6">
          <div>
            <div
              class="card bg-gradient-warning border-success"
              style="min-height: 400px"
            >
              <div class="text-center p-3">
                <h5 class="h3 mb-0 text-white">
                  {{ $t("label.statisticsToday") }}
                </h5>
              </div>
              <div class="card-body" style="">
                <div class="row">
                  <div class="col-md-4">
                    <div class="card text-center">
                      <div class="card-body p-2">
                        <p class="text-danger font-weight-bold p-0 m-0">{{infoCheckin.checkedin}}/{{infoCheckin.accountNumber}}</p>
                        <span>{{ $t("label.working") }}</span>
                      </div>
                    </div>
                    <div class="card border text-center">
                      <div class="card-body p-2">
                        <p class="text-danger font-weight-bold p-0 m-0">{{infoCheckin.lateNumber}}</p>
                        <span>{{ $t("label.late") }}</span>
                      </div>
                    </div>
                    <div class="card text-center">
                      <div class="card-body p-2">
                        <p class="text-danger font-weight-bold p-0 m-0">{{infoCheckin.notCheckin}}</p>
                        <span>{{ $t("label.absent") }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8 list-person-in-out">
                    <div class="list-person-in-out--item" v-for="(item, index) in countInoutIINnfos">
                        <div class="list-person-in-out--item__img">
                          <img :src="item.avatar" alt="">
                        </div>
                        <div class="list-person-in-out--item__content">
                          <h3>{{item.fullName}}</h3>
                          <p>{{$t('label.turnInOut')}}: {{item.countinout}}</p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="card border-0">
            <div class="container">
              <apexchart
                height="389"
                :options="options"
                :series="series"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <b-modal
            v-model="modalPreview"
            id="modal-preview"
            modal-class="modal-fullscreen"
            title="Xem trước báo cáo"
            header-bg-variant="gradient-primary"
            header-text-variant="light"
            body-bg-variant="secondary"
            centered
          >
          <div id="print">
            <preview-report :preview-data="previewData">
            </preview-report>
          </div>
           <template v-slot:modal-footer="{ cancel }">
            <b-button @click="generatePDF()">{{$t('label.exportPdf')}}</b-button>
            <b-button @click="exportReport()">{{$t('label.exportExcel')}}</b-button>
            <b-button @click="print()">{{$t('label.print')}}</b-button>
            <b-button @click="cancel()">{{$t('label.cancel')}}</b-button>
      </template>
      </b-modal>
    </div>
    <div class="col-12">
      <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="fileName"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a1"
      :pdf-margin="10"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      @progress="onPdfProgress($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <preview-report :preview-data="previewData">
        </preview-report>
      </section>
    </vue-html2pdf>
    </div>
    <pulse-loader :loading="loading"></pulse-loader>
  </div>
</template>
<script>
import apiClient from '@/axios'
import apiServer from '@/axiosServer'
import flatPickr from 'vue-flatpickr-component'
import VueApexCharts from "vue-apexcharts"
import FlatpickrLanguages from "flatpickr/dist/l10n"
import Treeselect from '@riophae/vue-treeselect'
import VueHtml2pdf from "vue-html2pdf"
import PreviewReport from '@/components/PreviewReport.vue'
import PulseLoader from '@/components/PulseLoader.vue'
import moment from 'moment'
import Vue from "vue"
import { Printd } from 'printd'

Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

export default {
  components: {
    flatPickr,
    Treeselect,
    VueHtml2pdf,
    PreviewReport,
    PulseLoader
  },
  data() {
    return {
      isSupperAdmin: false,
      isComAdmin: false,
      loading: false,
      accountNumber:'',
      lateNumber:'',
      branchNumber:'',
      workingNumber:'',
      absentNumber:'',
      reportType:1,
      dateSelect:'',
      placeId: null,
      branchs: [],
      listReportTypes: [
        { text: this.$t('label.workDetail'), value: 1 },
        { text: this.$t('label.checkinDetail'), value: 2 },
        { text: this.$t('label.checkinByDay'), value: 3 },
        { text: this.$t('label.checkinMoreDay'), value: 4 },
        { text: this.$t('label.workDayDetail'), value: 5 },
        { text: this.$t('label.mealsInMonth'), value: 6 },
        { text: this.$t('label.detailCheckin'), value: 7 },
        { text: this.$t('label.generalReport'), value: 8 }
      ],
      configDateTimePicker: {
        dateFormat: 'd/m/Y',
        mode: "range",
        maxDate: "today",
        locale: FlatpickrLanguages["en"],
        shorthandCurrentMonth: true
      },
      options: {
        chart: {
          id: "vuechart-example",
          type: 'bar',

          height: 300,
          width: '100%'
      },
      xaxis: {
        categories: [],
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }]
      },
      series: [],
      infoCheckin: [],
      countInoutIINnfos:[],
      departmentId: null,
      positionId: null,
      aliasId: null,
      fullName: null,
      reportByMonth: false,
      reportByDepartPos: false,
      reportByUser: false,
      reportByLateEarly: false,
      month: 0,
      year: 0,
      monthOptions: [],
      yearOptions: [],
      departmentOption: null,
      positionOption: null,
      lateEarly: null,
      isOpenCodeRes: false,
      isOpenNameRes: false,
      modalPreview: false,
      nameResults: [],
      codeResults: [],
      previewData: [],
      fileName: ''
    };
  },
  created() {
    let today = new Date();
    this.month = today.getMonth() + 1;
    this.year = today.getFullYear();
    for (let i = 1; i <= 12; i++) {
      if (i < 10) {
        this.monthOptions.push({text: '0' + i, value: '0' + i});
      } else {
        this.monthOptions.push({text: i, value: i});
      }
    }
    for (let j = this.year; j >= this.year - 10; j--) {
      this.yearOptions.push({text: j, value: j});
    }
    if (this.$store.getters.superAdmin == true) {
      this.isSupperAdmin = true;
    }
    if (this.$store.getters.comAdmin == true) {
      this.isComAdmin = true;
    }
  },
  mounted() {
    if (this.isSupperAdmin || this.isComAdmin) {
      this.getListBranch();
    } else {
      this.getDepartment();
      this.getPosition();
    }
    this.getSummary();
    this.getStatistic();
    if (this.$store.getters.locale == 'vi') {
      this.configDateTimePicker.locale = FlatpickrLanguages["vn"];
    }
    this.getCountInout();
  },
  methods:{
    changeBranch () {
      this.getDepartment();
      this.departmentId = null;
      this.getPosition();
      this.positionId = null;
    },
    async getListBranch(){
      let res;
      try{
        res = await apiServer.post('/api/v1/company/get-all-branch',{});
        if (res && res.data) {
          this.branchs.push({value:null, text:this.$t('label.allBranches')});
          for (let br of res.data) {
            this.branchs.push({value: br.placeID, text:br.branch_name});
          }
        }
      }
      catch (e) {
        console.log(e);
      }
    },
    async getDepartment() {
      let res
      try {
        res = await apiServer.post('api/v1/department/list', {
          placeId: this.placeId
        })
      } catch (error) {}
      if (res && res.data) {
        this.departmentOption = res.data;
      }
    },
    async getPosition () {
      let res
      try {
        res = await apiServer.post('api/v1/position/list', {
          placeId: this.placeId
        })
      } catch (error) {}
      if (res && res.data) {
        this.positionOption = [];
        if (res.data.length > 0) {
          this.positionOption = res.data;
        }
      }
    },
    async getDataReport(type){
      this.loading = true;
      let res;
      let timeStart = null;
      let timeEnd = null;

      if (this.dateSelect !== null && this.dateSelect !== '') {
        let dateArr = this.dateSelect.split(' ');
        timeEnd = this.convertTimestampSendDate(dateArr[0]);
        if(dateArr.length == 1){
          timeStart= timeEnd;
        }
        else {
          timeStart = this.convertTimestampSendDate(dateArr[2]);
        }

      }

      try {
        let api='';
        if (this.reportType == 1){
          api = '/api/v1/report/work-detail';
          this.fileName = 'report-work';
        } else if(this.reportType == 2) {
          api = '/api/v1/report/checkin-detail';
          this.fileName = 'report-checkin';
        } else if (this.reportType == 3){
          api = '/api/v1/report/checkin-per-day';
          this.fileName = 'report-per-day';
        } else if (this.reportType == 4){
          api = '/api/v1/report/checkin-month';
          this.fileName = 'report-per-month';
        } else if (this.reportType == 5){
          api = '/api/v1/report/work-day-detail';
          this.fileName = 'report-work-detail';
        } else if (this.reportType == 6) {
          api = '/api/v1/report/meals-in-month';
          this.fileName = 'meals-in-month';
        } else if (this.reportType == 7) {
          api = '/api/v1/report/detail-checkin';
          this.fileName = 'detail-checkin';
        } else {
          api = '/api/v1/report/general-checkin';
          this.fileName = 'general-checkin';
        }

        res = await apiServer.post(api, {
          placeid: this.placeId,
          fromdate: timeEnd,
          todate: timeStart,
          date: timeStart,
          year: this.year,
          month: this.month,
          departmentid: this.departmentId,
          positionid: this.positionId,
          aliasid: this.aliasId,
          fullname: this.fullName,
          lateearly: this.lateEarly,
          mode: "preview"
        });
      }
        catch (error) {
          this.loading = false;
          if (error.response.status == 400) {
            alert(this.$t(error.response.data.message));
          }
      }
      if (res && res.data) {
        this.loading = false;
        this.previewData = res.data[0];
        if (this.previewData.length == 1) {
          alert(this.$t('notify.message.nodata'));
        } else {
          if (type == "preview"){
            this.modalPreview = true;
          }
        }
      }
    },
    async previewreport(){
      await this.getDataReport("preview");
    },
    async exportReport(){
      this.loading = true;
      let res;
      let timeStart = null;
      let timeEnd = null;

      if (this.dateSelect !== null && this.dateSelect !== '') {
        let dateArr = this.dateSelect.split(' ');
        timeEnd = this.convertTimestampSendDate(dateArr[0]);
        if(dateArr.length == 1){
          timeStart= timeEnd;
        }
        else {
          timeStart = this.convertTimestampSendDate(dateArr[2]);
        }

      }

      try {
        let api='';
        if (this.reportType == 1){
          api = '/api/v1/report/work-detail';
          this.fileName = 'report-work';
        } else if(this.reportType == 2) {
          api = '/api/v1/report/checkin-detail';
          this.fileName = 'report-checkin';
        } else if (this.reportType == 3){
          api = '/api/v1/report/checkin-per-day';
          this.fileName = 'report-per-day';
        } else if (this.reportType == 4){
          api = '/api/v1/report/checkin-month';
          this.fileName = 'report-per-month';
        } else if (this.reportType == 5){
          api = '/api/v1/report/work-day-detail';
          this.fileName = 'report-work-detail';
        } else if (this.reportType == 6) {
          api = '/api/v1/report/meals-in-month';
          this.fileName = 'meals-in-month';
        } else if (this.reportType == 7) {
          api = '/api/v1/report/detail-checkin';
          this.fileName = 'detail-checkin';
        } else {
          api = '/api/v1/report/general-checkin';
          this.fileName = 'general-checkin';
        }

        res = await apiServer.post(api, {
          placeid: this.placeId,
          fromdate: timeEnd,
          todate: timeStart,
          date: timeStart,
          year: this.year,
          month: this.month,
          departmentid: this.departmentId,
          positionid: this.positionId,
          aliasid: this.aliasId,
          fullname: this.fullName,
          lateearly: this.lateEarly
        }, {
          responseType: 'blob'
        }).then((response) => {
          this.loading = false;
          const url = URL.createObjectURL(new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', this.fileName)
          document.body.appendChild(link)
          link.click();
      });
      } catch (error) {
        console.log(error);
        if (error.response.status === 400) {
          this.loading = false;
          let responseText = await error.response.data.text();
          alert(this.$t(JSON.parse(responseText).message));
        }
      }
    },
    async exportPdf() {
      await this.getDataReport(null);
      this.generatePDF();
    },
    generatePDF() {
      this.loading = true;
      this.$refs.html2Pdf.generatePdf();
    },
    onPdfProgress(event) {
      if (event == 100){
        this.loading = false;
        this.modalPreview = false;
      }
    },
    async getSummary() {
      let res
      try {
        res = await apiServer.post('api/v1/dashboard/summary', {
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.infoCheckin =
          {
              accountNumber: res.data.accountNumber,
              checkedin: res.data.checkedin,
              lateNumber: res.data.lateNumber,
              notCheckin: res.data.notCheckin,
          }
      }
    },

  async onChangeCode(){
    this.isOpenCodeRes = true;
    let res;
    try {
      res = await apiServer.post('api/v1/user/suggest-employee', {
        placeID: this.$store.getters.placeID,
        keyword: this.aliasId,
        searchType: "code"
      })
    } catch (error) {
    }
    if (res && res.data) {
      this.codeResults = [];
      if (res.data.length > 0) {
        for (let user of res.data) {
          this.codeResults.push({value: user.aliasID, text: user.fullName});
        }
      }
    }
  },
  setCodeResult(result) {
    this.aliasId = result.value;
    this.isOpenCodeRes = false;
  },

  async onChangeName(){
    this.isOpenNameRes = true;
      let res;
      try {
        res = await apiServer.post('api/v1/user/suggest-employee', {
          placeID: this.$store.getters.placeID,
          keyword: this.fullName,
          searchType: "name"
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.nameResults = [];
        if (res.data.length > 0) {
          for (let user of res.data) {
            this.nameResults.push({value: user.aliasID, text: user.fullName});
          }
        }
      }
    },
    setNameResult(result) {
    this.fullName = result.text;
    this.isOpenNameRes = false;
  },
  onClickOutside() {
      this.isOpenCodeRes = false;
  },
  handleClickOutside() {
    this.isOpenNameRes = false;
  },
  async getCountInout() {
    let res
    try {
      res = await apiServer.get('api/v1/report/count-inout', {
      })
    } catch (error) {
    }
    console.log(res)
    if (res && res.data) {
      this.countInoutIINnfos = res.data;
    }
  },

  async getStatistic() {
    let res
    try {
      res = await apiServer.post('api/v1/checkin/statistic', {
      })
    } catch (error) {
    }
    if (res && res.data) {
      // console.debug(res.data);
      //this.series.push({names:'series-1'});
      let checkedIn = [];
      let checkinLate = [];
      let checkoutEarly = [];
      let leaved = [];
      let unCheckin = [];
      for (let v of res.data) {
        this.options.xaxis.categories.push(v.dateId);
        checkedIn.push(v.checkedin);
        checkinLate.push(v.checkin_late);
        checkoutEarly.push(v.checkout_early);
        leaved.push(v.leaved);
        unCheckin.push(v.un_checkin);
      }
      this.series.push({name: this.$t('label.checkedin'), data:checkedIn});
      this.series.push({name: this.$t('label.late'), data:checkinLate});
      this.series.push({name: this.$t('label.comeBackBefore'), data:checkoutEarly});
      this.series.push({name: this.$t('label.takeABreak'), data:leaved});
      this.series.push({name: this.$t('label.notCheckin'), data:unCheckin});
    }
  },

    reportTypeChange(){
      if(this.reportType === 3){
        this.dateSelect = null;
        this.configDateTimePicker.mode = "single";
      } else {
        this.configDateTimePicker.mode = "range";
        this.leaveTypeHalf = null;
      }
      this.reportByDepartPos = false;
      this.reportByMonth = false;
      this.reportByUser = false;
      this.reportByLateEarly = false;
      if ([6,7,8].includes(this.reportType)) {
        this.reportByDepartPos = true;
      }
      if ([6,8].includes(this.reportType)) {
        this.reportByMonth = true;
      }
      if (this.reportType == 7) {
        this.reportByUser = true;
        this.reportByLateEarly = true;
      }
    },
    print(){
      const d = new Printd()
      d.print( document.getElementById('print'), 
      [`#print table tr td { border: 1px solid #e9ecef;padding: 2px; } #print table tr:first-child td {text-align: center; font-weight: bold}`] )
    },
    convertDate(inputDate, format) {
      if (inputDate == undefined || inputDate == null) return '';
      let date = new Date(inputDate)
      if (date !== 'Invalid Date') {
        let day = ("0" + date.getDate()).slice(-2)
        // current month
        let month = ("0" + (date.getMonth() + 1)).slice(-2)
        // current year
        let year = date.getFullYear();
        if(format == 'dd-MM-yyyy'){
          return day + "-" + month + "-" + year
        } else if(format == 'dd/MM/yyy'){
          return day + "/" + month + "/" + year
        } else if(format == 'yyyy/MM/dd'){
          return year + "/" + "/" + month + "/" + day
        } else {
          return year + "-" + month + "-" + day
        }
      }
    },
    convertTimestampToDate (timestamp) {
      return moment(timestamp).format('DD/MM/yyyy')
    },
    convertTimestampSendDate (date) {
      const dateString = date;
      const changedDate = dateString.replace(/(..)\/(..)\/(....)/, '$3-$2-$1');
      var date = new Date(changedDate);
      return moment(date).format('yyyy-MM-DD')
    }
  }
}
</script>
<style scoped lang="scss">
  .icon-dashdoard{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 40px;
  }
  .list-person-in-out--item{
    background: #fff;
    border-radius: 6px;
    padding:5px 10px;
    display: flex;
    margin-bottom: 15px;
    .list-person-in-out--item__content{
      display: flex;
      width: 85%;
      justify-content: space-between;
      align-content: center;
      padding: 10px 5px 0;
        h3{
          line-height: 1.5;
          margin-bottom: 0;
        }
        p{
          font-size: 13px;
          margin-bottom: 0;
        }
      }
    .list-person-in-out--item__img{
      width: 15%;
      padding-top: 5px;
      img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
  }
  .autocomplete {
    position: relative;
  }

  .autocomplete-results {
    position: absolute;
    z-index: 999;
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    min-height: 1em;
    max-height: 18em;    
    overflow: auto;
    background: #fff;
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
  }

  .autocomplete-result:hover {
    background-color: #eeeceb;
  }
</style>
<style>
  .modal-fullscreen .modal-dialog {
    max-width: 100%;
    margin: 0;
    left: 0;
    right: 0;
    display: flex;
    position: fixed;
    z-index: 100000;
}
.modal-fullscreen .modal-dialog .modal-body {
  width: 100%;
  height: calc(100vh - 200px);
  overflow: auto;
}
</style>
