<template>
  <div id="app-body">
    <div class="bg-video-wrap">
        <video src="../assets/img/welcome/bg.mp4" loop muted autoplay>
        </video>
        <div class="overlay">
        </div>
    </div>
    <div class="main-body">
        <div class="close-welcome" @click="redirectLink('/admin/dashboard')"><font-awesome-icon icon="power-off"/></div>
        <div class="main--header">
            <div class="row">
                <div class="col-2"><h1 class="logo"><img src="../assets/img/welcome/logo.png" alt=""></h1></div>
                <div class="col-9">
                    <marquee>
                        <div class="text-run">
                            <ul>
                                <li>VƯỢT BÃO ĐẠI DỊCH COVID-19, VIETTEL CONSTRUCTION GHI NHẬN DOANH THU 6 THÁNG ĐẦU NĂM ĐẠT MỨC 3.552 TỶ ĐỒNG. *****</li>&nbsp;
                                <li>TGĐ VIETTEL CONSTRUCTION ĐƯỢC THĂNG QUÂN HÀM THƯỢNG TÁ TRƯỚC THỜI HẠN.</li>
                            </ul>
                        </div>
                    </marquee>
                </div>
            </div>
        </div>
        <div class="container-fluid" style="padding:0 2rem">
            <div class="row">
                <div class="col-2">
                    <div class="date-time">
                        <div class="date-time--time">{{currentTime}}</div>
                        <div class="date-time--date"> <p>{{currentDate}}</p><div>{{nameDate}}</div></div>
                    </div>
                </div>
                <div class="col-5 last-inout">
                    <div class="last-inout--item">
                        <div  v-for="user in usersLast">
                            <div>
                                <div class="last-inout__image"><img :src="user.detected_image_url"></div>
                                <div v-show="user.personID" class="check-right"><img src="../assets/img/welcome/icon-tick.png"></div>
                                <div class="last-inout--content">
                                    <h4>{{user.personName}}</h4>
                                    <p>{{user.placeName}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-5">
                    <div class="row list-noti">
                        <div class="col-6">
                            <div class="card border-0">
                                <div>
                                    <span class="h2 font-weight-bold mb-0">{{this.dataDashBoard.checkedin}}</span>
                                    <p class="card-title text-muted mb-0">
                                    {{ $t("label.checkedin") }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="card border-0">
                                <div>
                                    <span class="h2 font-weight-bold mb-0">{{this.dataDashBoard.lateNumber}}</span>
                                    <p class="card-title text-muted mb-0">
                                    {{ $t("label.late") }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 class="text-center title-noti">{{$t('label.titleListCheckin')}}</h3>
                    <div class="list-name row">
                        <div class="list-name--item col-6" v-for="user in infoList">
                            <div>
                                <h4>{{user.personName}}</h4>
                                <p>{{updateTime(user.date)}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="margin-height"></div>
        <div class="list-checkout">
            <div>
                <div class="list-checkout--item" v-for="user in users">
                    <div class="list-checkout--item__image">
                        <img :src="user.detected_image_url"/>
                    </div>
                    <h4>{{user.personName}}</h4>
                    <p>{{updateTime(user.date)}}</p>
                </div>
            </div>
        </div>
        <div class="col-12" style="display:none">
            <div>
                {{$t('label.turnAudioOn')}}
                <toggle-button
                    :value=audioOn
                    color="#FB6340"
                    :labels=labelsAudio
                    @change="clickAudio"
                />
            </div>
            <div>
            <video style="display:none" controls="" id="audio">
                <source id ="audio-source" src="../assets/video/notification.mp3" type="audio/mpeg">
            </video>
            </div>
        </div>
    </div>
  </div>
</template>


<script>
import apiClient from '@/axios'
import apiServer from '@/axiosServer'
import moment from 'moment'

export default {
  data() {
    return {
      dataDashBoard: [],
      users: [],
      usersLast: [],
      popupTurnOn: false,
      audioOn: true,
      srcMp3: '',
      labelsAudio:{checked: this.$t('label.on'), unchecked: this.$t('label.off')},
      currentTime:'',
      currentDate:'',
      nameDate:'',
      infoList:[],
      fontSize:'',
    };
  },
  created() {
    this.audioOn = this.$store.getters.audio;
    setInterval(this.getNow, 1000);
  },
  mounted() {
      this.getSummary();
      document.getElementById('audio').click();
      this.checkWidth();
      this.checkHeight();
  },
  sockets: {
    connect: function () {
      console.debug('socket connected')
    },
    receiveMessage: function (user) {
    this.usersLast = [];
      for (let i = 0; i < this.$store.getters.placeID.length; i++) {
        if (parseInt(this.$store.getters.placeID[i]) === parseInt(user.placeID)) {
          if ((this.audioOn) && (user.audio === true)) {
            const audioSrc = 'data:audio/mp3;base64,' + user.linkaudio
            document.getElementById('audio-source').src = audioSrc
            document.getElementById('audio').load()
            document.getElementById('audio').play()
          }
          this.users.unshift(user)
          this.usersLast.unshift(user)
          if( this.infoList.length > 9){
              this.infoList.pop();
              this.infoList.unshift(user);
          }else{
              this.infoList.unshift(user)
          }
        }
      }
    }
  },
  methods:{
      async getSummary() {
      let res
      try {
        res = await apiServer.post('api/v1/dashboard/summary', {
        })
      } catch (error) {
      }
      if (res && res.data) {
        this.dataDashBoard =
          {
              accountNumber: res.data.accountNumber,
              checkedin: res.data.checkedin,
              lateNumber: res.data.lateNumber,
              notCheckin: res.data.notCheckin,
          }
      }
    },
    clickAudio() {
      this.audioOn = !this.audioOn;
      this.$store.commit("updateAudio", this.audioOn);
    },

    redirectEmployeeManager(){
      this.$router.replace("/admin/employee-manager")
    },

    getNow() {
        const today = new Date();
        var dt = moment(today, "YYYY-MM-DD HH:mm:ss");
        const date = moment(today).format("DD/MM/yyyy");
        const time = moment(today).format("HH:mm");
        this.currentTime = time;
        this.currentDate = date;
        this.nameDate = dt.format('dddd');
    },

    updateTime(date){
        return moment(date).format("HH:mm");
    },
     checkWidth() {
        const htmlDom = document.getElementsByTagName('html')[0];
        let w = window.innerWidth;
        var font = w / 100;
        htmlDom.style.fontSize = font +'px';
    },
    redirectLink(link){
        this.$router.replace(link)
    },
    checkHeight () {
        var maxHeight = window.screen.height;
        var maxWidth = window.screen.width;
        var curHeight = window.innerHeight;
        var curWidth = window.innerWidth;
        let margin = document.querySelector(".margin-height");
        if (maxWidth == curWidth && maxHeight == curHeight)
        {
            margin.classList.add('fix-height');
        } else if (window.scrollY < 1) {
            margin.classList.remove('fix-height');
        }
    }
  }
};
</script>
<style scoped lang="scss">
body,html {
    overflow-x: hidden;
}
.bg-video-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: url(../assets/img/welcome/bg-video.png) no-repeat center center/cover;
}
video {
  min-width: 100%;
  min-height: 100%;
  z-index: 1;
}
.main-body{
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 99;
    position: fixed;
}
.main--header{
    // background: url(../assets/img/welcome/header-bg1.png) no-repeat left bottom/cover;
    padding: 0 2rem 0;
    background: linear-gradient(180deg, #00000052 75%, #00000000 0%);
    position: relative;
    h1{
        line-height: 0;
        padding-top: 1rem;
        margin-bottom: 0;
    }
    &::before{
        content:'';
        width: 14%;
        position: absolute;
        height: 60%;
        top: 100%;
        left: 0;
        background: linear-gradient(113deg, #00000052 90%, #0E506F00 50%) no-repeat;
        transform: translateY(-42%);
        z-index: -1;
    }
    .text-run{
        padding: .5rem 0;
        ul{
            display: flex;
            color: #fff;
            list-style: none;
            font-weight: bold;
            padding-top: .4rem;
            margin: .2rem 0 0;
            font-size: 1rem;
            li{
                margin: 0;
            }
        }
    }
}




.date-time{
    color: #fff;
    padding-top: 7rem;
}
.date-time--time{
    font-size: 5rem;
    line-height: .8;
    margin-bottom: 2rem;
}
.date-time--date{
    font-weight: bold;
    padding-left: .3rem;
    p{
        font-size: 2rem;
        line-height: .8;
        margin-bottom: .2rem;
    }
    div{
        font-size: 1.7rem;
    }
}
.list-name{
    color: #fff;
    .list-name--item{
        &>div{
            padding: .8rem;
            display: flex;
            justify-content: space-between;
            h4{
                color: #fff;
                width: 80%;
                margin-bottom: 0;
            }
            p{
                padding: 0 .8rem;
                width: 20%;
                margin-bottom: 0;
            }
        }
        &:nth-child(3)>div,&:nth-child(4)>div,&:nth-child(7)>div,&:nth-child(8)>div,&:nth-child(11)>div,&:nth-child(12)>div{
            background: #ffffff1a;
            border-radius:.2rem;
        }
    }
}
.last-inout{
    position: relative;

    h1{
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%,-50%);
        z-index: 2;
        img{
            width: 11rem;
        }
    }

    &>div.last-inout--item{
        height: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        &>div{
            display: flex;
            align-items: center;
            &>div{
                box-shadow: 0 0 1rem #011b40;
                border-radius: .8rem;
                padding: 0;
                .last-inout__image{
                    padding: 1.3rem;
                    background: #8FAADC;
                    border-radius: .8rem .8rem 0 0;
                    text-align: center;
                    img{
                        width: 11rem;
                        height: 11rem;
                        background: url(../assets/img/welcome/bg-img.png) center center no-repeat;
                        background-size: contain;
                        border-radius: 50%;
                        padding: .8rem;
                    }
                }
                .last-inout--content{
                    background: #2F5597;
                    text-align: center;
                    color: #fff;
                    border-radius: 0 0 .8rem .8rem;
                    h4{
                        color: #fff;
                        padding: .8rem 1rem 0;
                        font-size: 1.2rem;
                    }
                    p{
                       padding-bottom: 1rem;
                       line-height: 1;
                       margin-bottom: 0;
                    }
                }
            }
        }
    }
}
.list-checkout{
    padding: 2rem 2rem 1rem;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 8vh;
    background: #00000050;
    &>div{
        width: auto;
        white-space:nowrap;
    }
}
.list-checkout--item{
    min-width: 10rem;
    text-align: center;
    margin-right: 1rem;
    display: inline-block;
    padding: 0 1rem;
    img{
        width: 9rem;
        height: 9rem;
        border-radius: 50%;
        box-shadow:0 0 .8rem #8FAADC;
        margin: .5rem 0;
    }
    p{
        color: #fff;
        font-size: 1.1rem;
        line-height: 1.2;
        height: .8rem;
    }
    h4{
        color: #fff;
        font-size: 1.1rem;
        margin-top: .3rem;
    }
}
.list-noti{
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    .card{
        padding: .4rem .8rem;
        margin-bottom: 0;
        div{
            display: flex;
            justify-content: space-between;
        }
    }
    &>div:nth-child(1){
        span{
            color: green;
        }
    }
    &>div:nth-child(2){
        span{
            color: red;
        }
    }
}
.check-right{
    position: relative;
    background: #9d1129;
    background: linear-gradient(180deg, #8FAADC 50%, #2F5597 0%);
    line-height: 0;
    text-align: center;
    img{
        width: 2.3rem;
        border-radius: 50%;
        box-shadow: 0 0 .5rem #2e251bad;
    }
}
.close-welcome{
    position: absolute;
    top: .7rem;
    right: 2rem;
    padding: .5rem .57rem;
    border: solid .2rem #fff;
    line-height: 0;
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    z-index: 2;
    font-size: 1rem;
}
.title-noti{
    color:#fff;
    font-size: 1.2rem;
    font-weight: normal;
    margin: 1.5rem 0 -.6rem;
}
.margin-height.fix-height{
    height: 8vh;
}
.logo{
    img{width: 10rem;}
}

/*ipad-pro*/
@media screen and (min-width: 968px) and (max-width: 1110px){

}
/* end-ipad-pro*/

/*ipad*/
@media screen and (min-width: 768px) and (max-width: 967px){


}
/*end-ipad*/
/*mobile*/
@media screen and (max-width: 767px){



}
@media screen and (min-width: 375px) and (max-width: 413px){

}
</style>
